import ApiGateway from 'lib/api-gateway';
import AppConfig from 'helpers/config';
import EmployeeEntity from 'employees/entities/employee';

class EmployeeAPIGateway {

  toString() {
    return 'EmployeeAPIGateway';
  }

  async employeeList(params) {
    const listConfig =  AppConfig.server.apiHost + '/employee/';
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load(params);
    const entity = response.data.results.map(i => new EmployeeEntity(i));
    return { ...response.data, results: entity };

  }

  async getRoleList(params) {
    const roleListConfig =  AppConfig.server.apiHost + '/company/employee/role/';
    const apiGateway = new ApiGateway(roleListConfig);
    const response = await apiGateway.load(params);
    const roleList = response.data;
    return roleList;
  }

  async getDepartmentList(params) {
    const departmentListConfig =  AppConfig.server.apiHost + '/company/departments/'; 
    const apiGateway = new ApiGateway(departmentListConfig);
    const response = await apiGateway.load(params);
    const departmentList = response.data;
    return departmentList;
  }

  async getCountryList(params) {
    const countryListConfig =  AppConfig.server.apiHost + '/address/country/';
    const apiGateway = new ApiGateway(countryListConfig);
    const response = await apiGateway.load(params);
    const countryList = response.data;
    return countryList;
  }

  async getStateList(params) {
    const stateListConfig =  AppConfig.server.apiHost + '/address/state/'; 
    const apiGateway = new ApiGateway(stateListConfig);
    const response = await apiGateway.load(params);
    const stateList = response.data;
    return stateList;
  }

  async getAllSubCompanies(params) {
    const subcompanyListConfig =  AppConfig.server.apiHost + '/company/all/';
    const apiGateway = new ApiGateway(subcompanyListConfig);
    const response = await apiGateway.load(params);
    const subcompanyList = response.data;
    return subcompanyList;
  }

  async getTeamList(params) {
    const teamListConfig =  AppConfig.server.apiHost + '/company/teams/';
    const apiGateway = new ApiGateway(teamListConfig);
    const response = await apiGateway.load(params);
    const teamList = response.data;
    return teamList;
  }

  async getMultiTeamList(params){
    const teamListConfig =  AppConfig.server.apiHost + '/company/teams/';
    const apiGateway = new ApiGateway(teamListConfig);
    const response = await apiGateway.load(params);
    const multiTeamList = response.data;
    return multiTeamList;
  }
  

  async addEmployeeTags(params) {
    const addTag =  AppConfig.server.apiHost + '/job/tags/';
    const apiGateway = new ApiGateway(addTag);
    const response = await apiGateway.save(params);
    const data = response.data;
    return data;
  }

  async createEmployee(employee) {
    const employeeReqObj = {
      ...employee,
      user: {
        first_name: employee.first_name,
        last_name: employee.last_name,
        password: employee.password,
        
      },
      company_id: employee.company,
      department: employee.department,
      team: employee.team && employee.team,
      employee_role: employee.employee_role,
      email : employee.email,
      title: employee.title,
    }

    const createEmployeeConfig =  AppConfig.server.apiHost + '/employee/';
    const apiGateway = new ApiGateway(createEmployeeConfig);
    const response = await apiGateway.save(employeeReqObj);
    const employeeEntity = new EmployeeEntity(response.data);
    return employeeEntity;
  }

  async updateEmployee(employee, invite) {
    const employeeReqObj = {
      ...employee,
      user: {
        first_name: employee.first_name,
        last_name: employee.last_name,
        password: employee.password,
      },
      phone_ext: employee.phone_ext ? employee.phone_ext : '+1',
      company_id: employee.company,
      department: employee.department ? employee.department : 0,
      team: employee.team ? employee.team : 0,
      employee_role: employee.employee_role,
      username : employee.username,
      invite : invite,
      state: employee.state ? employee.state : 0,
      jobs_present : employee.jobs_present,
      country: employee.country ? employee.country : 0,
    }
    const updateEmployeeConfig =  AppConfig.server.apiHost + '/employee/' + employee.id + '/';
    const apiGateway = new ApiGateway(updateEmployeeConfig);
    const response = await apiGateway.update(employeeReqObj);
    const employeeEntity = new EmployeeEntity(response.data);
    return employeeEntity;
  }

  async getEmployee(employeeId) {
    const employeeConfig =  AppConfig.server.apiHost + '/employee/' + employeeId + '/'; 
    const apiGateway = new ApiGateway(employeeConfig);
    const response = await apiGateway.load();
    const employeeEntity = new EmployeeEntity(response.data);
    return employeeEntity;
  }

  async deleteMarkedTags(id, data) {
    const tagsConfig =  AppConfig.server.apiHost + '/job/tags/' + id + '/'; 
    const apiGateway = new ApiGateway(tagsConfig);
    const response = await apiGateway.delete({...data});
  }

  async updateTagName(id, data) {
    const tagsConfig =  AppConfig.server.apiHost + '/job/tags/' + id + '/'; 
    const apiGateway = new ApiGateway(tagsConfig);
    const response = await apiGateway.update({...data});
  }


  async getEmployeesTags(params) {
    const tagsConfig =  AppConfig.server.apiHost + '/job/tags/';
    const apiGateway = new ApiGateway(tagsConfig);
    const response = await apiGateway.load(params);
    const tagsList = response.data
    return tagsList;
  }

  async getEmployeesPolicies(params) {
    const tagsConfig =  AppConfig.server.apiHost + '/pto/policy/';
    const apiGateway = new ApiGateway(tagsConfig);
    const response = await apiGateway.load(params);
    const policyList = response?.data?.results
    return policyList ? policyList : [];
  }

 async getLoginLink(employeeId){
    const linkConfig =  AppConfig.server.apiHost + '/employee/' + employeeId + '/link/'; 
    const apiGateway = new ApiGateway(linkConfig);
    const response =  await apiGateway.load();
    const link = response.data;
    return link;
  }

  async getInfoList(params) {
    const infoListConfig =  AppConfig.server.apiHost + '/t2b/documents/kw-articles-list/';
    const apiGateway = new ApiGateway(infoListConfig);
    const response = await apiGateway.load(params);
    const infoList = response.data;
    return infoList;
  }

  async getKioskAuditLogs(id, params){
    const kioskListConfig =  AppConfig.server.apiHost + '/employee/' + id + '/face-login/fail/';
    const apiGateway = new ApiGateway(kioskListConfig);
    const response = await apiGateway.load(params);
    const kioskList = response.data;
    return kioskList;
  }

  async postKioskAudits(params){
    const postKioskListConfig =  AppConfig.server.apiHost + '/t2b/faces/face/add-remove/';
    const apiGateway = new ApiGateway(postKioskListConfig);
    const response = await apiGateway.save(params);
    const data = response.data;
    return data;
  }

  async updateStatus(params){
    const postStatusConfig =  AppConfig.server.apiHost + '/employee/bulk/active/';
    const apiGateway = new ApiGateway(postStatusConfig);
    const response = await apiGateway.save(params);
    const data = response.data;
    return data;
  }

  async export(params){
    const exportConfig =  AppConfig.server.apiHost + '/employee/employee-export/';
    const apiGateway = new ApiGateway(exportConfig);
    const response = await apiGateway.load(params);
    const data = response.data;
    return data;
  }

  async resetPassword(data){

    const resetConfig =  AppConfig.server.apiHost + '/employee/reset-password/';
    const apiGateway = new ApiGateway(resetConfig);
    const response = await apiGateway.save(data);
    const success = response.data;
    return success;
  }

  async getSystemSettings() {
    const systemSettingConfig =  AppConfig.server.apiHost + '/company/settings/system/';
    const apiGateway = new ApiGateway(systemSettingConfig);
    const response = await apiGateway.load({ paginate: false });
    const systemSettingList = response.data;
    return systemSettingList;
  }

  async getAuditLogs(params){
    const auditListConfig =  AppConfig.server.apiHost + '/t2b/audits/audits/';
    const apiGateway = new ApiGateway(auditListConfig);
    const response = await apiGateway.load(params);
    const auditList = response.data;
    return auditList;
  }

  async getAllJobsList(params) {
      const allJobsListConfig =  AppConfig.server.apiHost + '/job/v2/';
      const apiGateway = new ApiGateway(allJobsListConfig);
      const response = await apiGateway.load(params);
      const allJobsList = response.data;
      return allJobsList;
    }
    async getAssignedJobsList(params) {
      const assignedJobsListConfig = AppConfig.server.apiHost + '/job/list/admin/';
      const apiGateway = new ApiGateway(assignedJobsListConfig);
      const response = await apiGateway.load(params);
      const  assignedJobsList = response.data;
      return assignedJobsList;
    }

    async getUnassignedJobsList(params){
      const unAssignedJobsListConfig = AppConfig.server.apiHost + '/job/list/admin/';
      const apiGateway = new ApiGateway(unAssignedJobsListConfig);
      const response = await apiGateway.load(params);
      const  unAssignedJobsList = response.data;
      return unAssignedJobsList;
    }

    async assignJobs(params){
      const job_idObj = {
      job_ids: params.job_ids,
      employee_ids: params.employee_ids,
      };
      const postAssignJobListConfig =  AppConfig.server.apiHost + '/job/employee/' + params.employee_id + '/';
      const apiGateway = new ApiGateway(postAssignJobListConfig);
      const response = await apiGateway.update(job_idObj);
      const data = response.data;
      return data;
    }

    async deleteEmployeeJob(job){
      const deleteEmployeeJobConfig =  AppConfig.server.apiHost + '/job/' +job.id + '/employee/';
      const apiGateway = new ApiGateway(deleteEmployeeJobConfig);
      const response = await apiGateway.update(job);
      const data = response.data;
      return data;
    }

    async updateUserPreference(params) {
      const updateUserPreferenceConfig = AppConfig.server.apiHost + '/employee/user_preference/view/';
      const apiGateway = new ApiGateway(updateUserPreferenceConfig);
      const response = await apiGateway.update(params);
      const data = response.data;
      return data;
    }

    async getUserPreference(params){
      const getUserPreferenceConfig = AppConfig.server.apiHost + '/employee/user_preference/view/';
      const apiGateway = new ApiGateway(getUserPreferenceConfig);
      const response = await apiGateway.load(params);
      const  employeePreferenceData = response.data;
      return employeePreferenceData;
    }

    async enableResetBiometric(employee){
      const enableResetBiometricConfig =  AppConfig.server.apiHost + `/employee/${employee.id}/reset-face/`;      
      const apiGateway = new ApiGateway(enableResetBiometricConfig);
      const response = await apiGateway.delete();
    }

    async enableResetPincode(employee){
      const enableResetPincodeConfig =  AppConfig.server.apiHost + `/employee/pincode/${employee.id}/?reset_pincode=true`;      
      const apiGateway = new ApiGateway(enableResetPincodeConfig);
      const response = await apiGateway.update();
    }

    async updateUserJobAssignment(params) {
      const updateUserPreferenceConfig = AppConfig.server.apiHost + '/job/remove_job/';
      const apiGateway = new ApiGateway(updateUserPreferenceConfig);
      const response = await apiGateway.update(params);
      const data = response.data;
      return data;
    }

  }
export default EmployeeAPIGateway;