import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as NotificationSettingDucks from 'ducks/vendors/notificationSetting';
import * as InfoDucks from 'ducks/info/info';

import SSTCFLayout from 'layouts/SSTCF';
import NotificationSettingListSection from 'sections/vendors/notificationSettingList/List';

import { NAV } from 'constants/vendors';

/**
 * NotificationSettingListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link NotificationSettingListSection}
 *
 * Fetch Needs:
 *    - getNotificationSettings
 */
class NotificationSettingListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.state = { isLoading: false, isInfoOpen: true };
  }

  componentWillMount() {
    
    const cookieKey = cookie.load("vendor_list_notification_setting");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  loadData(){
    const { location: { query } } = this.props;
    this.fetchData(query);
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'vendor.notification_setting'}),
      this.props.NotificationSettingDucks.getNotificationSettings(params)
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_list_notification_setting", !isInfoOpen);
  }

  render() {
    const { isLoading, isInfoOpen } = this.state;
    const tabs = [
      { href: '/vendor/basic-setting', label: 'Basic' },
      { href: '/vendor/system-setting', label: 'System' },
      { href: '/vendor/notification-setting', label: 'Notification' },
      { href: '/vendor/adjustment-types', label: 'Adjustment Types' },
      { href: "/vendor/system-forms", label: "System Forms" },
      
    ];
    const { infoList } = this.props;
    return (
      <SSTCFLayout
        navInfo={NAV.NotificationSettingListPage}
        tabItems={tabs.filter(item => item)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <NotificationSettingListSection isLoading={isLoading} />
      </SSTCFLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  NotificationSettingDucks: bindActionCreators(NotificationSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

NotificationSettingListPage.propTypes = {
  location: PropTypes.object.isRequired,
  NotificationSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(NotificationSettingListPage);
