import { createSelector } from 'reselect';
import moment from 'moment';

import createReducer from 'helpers/createReducer';
import axios from 'helpers/interceptor';
import { secondsToHHMM } from 'helpers/utils';
import URLS from './urls';

const GET_DAILY_TIMECARDS = 'timecards/dailyTimecard/GET_DAILY_TIMECARDS';
const GET_DAILY_TIMECARDS_V3 = 'timecards/dailyTimecard/GET_DAILY_TIMECARDS_V3';
const GET_DAILY_TIMECARDS_V3_FULFILLED = 'timecards/dailyTimecard/GET_DAILY_TIMECARDS_V3_FULFILLED'
const GET_DAILY_TIMECARDS_FULFILLED = 'timecards/dailyTimecard/GET_DAILY_TIMECARDS_FULFILLED';
const GET_DAILY_TIMECARD_ADJUSTMENTS = 'timecards/dailyTimecard/GET_DAILY_TIMECARD_ADJUSTMENTS';
const GET_DAILY_TIMECARD_ADJUSTMENTS_FULFILLED = 'timecards/dailyTimecard/GET_DAILY_TIMECARD_ADJUSTMENTS_FULFILLED';
const GET_CUSTOM_DAILY_TIMECARDS = 'timecards/dailyTimecard/GET_CUSTOM_DAILY_TIMECARDS';
const GET_CUSTOM_DAILY_TIMECARDS_FULFILLED = 'timecards/dailyTimecard/GET_CUSTOM_DAILY_TIMECARDS_FULFILLED';
const GET_CUSTOM_DAILY_TIMECARDS_V2 = 'timecards/dailyTimecard/GET_CUSTOM_DAILY_TIMECARDS_V2';
const GET_CUSTOM_DAILY_TIMECARDS_V2_FULFILLED = 'timecards/dailyTimecard/GET_CUSTOM_DAILY_TIMECARDS_V2_FULFILLED';
const GET_DAILY_DEVIATED_TIMECARDS = 'timecards/dailyTimecard/GET_DAILY_DEVIATED_TIMECARDS';
const GET_DAILY_DEVIATED_TIMECARDS_FULFILLED = 'timecards/dailyTimecard/GET_DAILY_DEVIATED_TIMECARDS_FULFILLED';
const RESET_HISTORIC_TIMECARD = 'timecards/dailyTimecard/RESET_HISTORIC_TIMECARD';
const GET_DAILY_TIMECARD_DETAIL = 'timecards/dailyTimecard/GET_DAILY_TIMECARD_DETAIL';
const GET_DAILY_TIMECARD_DETAIL_FULFILLED = 'timecards/dailyTimecard/GET_DAILY_TIMECARD_DETAIL_FULFILLED';
const GET_DAILY_TIMECARD_DETAIL_PENDING = 'timecards/dailyTimecard/GET_DAILY_TIMECARD_DETAIL_PENDING';
const GET_DAILY_DEVIATED_TIMECARDS_PENDING = 'timecards/dailyTimecard/GET_DAILY_DEVIATED_TIMECARDS_PENDING';
const SET_WEEK_AND_DATE = 'timecards/dailyTimecard/SET_WEEK_AND_DATE';
const APPROVE_DAILY_TIMECARD = 'timecards/timecard/APPROVE_DAILY_TIMECARD';
const APPROVE_DAILY_TIMECARD_FULFILLED = 'timecards/timecard/APPROVE_DAILY_TIMECARD_FULFILLED';
const DISAPPROVE_DAILY_TIMECARD = 'timecards/timecard/DISAPPROVE_DAILY_TIMECARD';
const DISAPPROVE_DAILY_TIMECARD_FULFILLED = 'timecards/timecard/DISAPPROVE_DAILY_TIMECARD_FULFILLED';
const TOGGLE_COLLAPSE = 'timecards/timecard/TOGGLE_COLLAPSE_FULFILLED'; 
const ADD_SHIFT =  'timecards/timecard/ADD_SHIFT';  
const ADD_MEAL =  'timecards/timecard/ADD_MEAL';
const ADD_SHIFT_TIME =  'timecards/timecard/ADD_SHIFT_TIME';
const ADD_MEAL_TIME =  'timecards/timecard/ADD_MEAL_TIME';
const RESET_MEAL_TIME =  'timecards/timecard/RESET_MEAL_TIME';
const RESET_SHIFT_TIME =  'timecards/timecard/RESET_SHIFT_TIME';
const UPDATE_MILEAGE = 'timecards/timecard/UPDATE_MILEAGE';
const UPDATE_MILEAGE_FULFILLED =  'timecards/timecard/UPDATE_MILEAGE_FULFILLED';
const UPDATE_USER_VIEW = 'timecards/timecard/UPDATE_USER_VIEW';
const UPDATE_USER_VIEW_FULFILLED = 'timecards/timecard/UPDATE_USER_VIEW_FULFILLED';
const GET_USER_VIEW = 'timecards/timecard/GET_USER_VIEW';
const GET_USER_VIEW_FULFILLED = 'timecards/timecard/GET_USER_VIEW_FULFILLED';
const FEEDBACK_TEMPLATE = 'timecards/timecard/FEEDBACK_TEMPLATE';
const FEEDBACK_TEMPLATE_FULFILLED = 'timecards/timecard/FEEDBACK_TEMPLATE_FULFILLED'; 
const CLEAR_NOSQL = 'timecards/timecard/CLEAR_NOSQL';

export function clearNosql(params) {
  return {
    type: CLEAR_NOSQL,
    payload: axios.put(`${URLS.CUSTOM_TIMECARD}/clear-nosql/${params.empId}/`),
  };
}

export function getDailyTimecards(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DAILY_TIMECARDS,
    payload: axios.get(`${URLS.TIMECARD}${id}/${URLS.DAILY_TIMECARD}`, { params }),
  };
}

export function getDailyTimecardsV3(parameters) {
  const { id,...params } = parameters;
  return {
    type: GET_DAILY_TIMECARDS_V3,
    payload: axios.get(`${URLS.TIMECARD}${URLS.DAILY_TIMECARD_V3}`, { params }),
  };
}

export function getDailyTimecardAdjustments(params) {
  return {
    type: GET_DAILY_TIMECARD_ADJUSTMENTS,
    payload: axios.get(URLS.ADJUSTMENT_TYPES_TIMECARD, { params }),
  };
}

export function getCustomDailyTimecards(parameters) {
  const { parent, first_name, last_name, ...params } = parameters;

  if(params.department_id){
    delete params.department_id;
  }
 
  if(params.payroll_id === 'custom'){
    delete params.payroll_id;
  }
  else if(params.payroll_id && params.payroll_id !== 'custom'){
    delete params.start_date;
    delete params.end_date;
  }
  return {
    type: GET_CUSTOM_DAILY_TIMECARDS,
    payload: axios.get(`${URLS.CUSTOM_TIMECARD}employee/`, { params }),
  };
}

export function getCustomDailyTimecardsV2(parameters) {
  const { parent, first_name, last_name, ...params } = parameters;
  if(params.payroll_id === 'custom'){
    delete params.payroll_id;
  }
  else if(params.payroll_id && params.payroll_id !== 'custom'){
    delete params.start_date;
    delete params.end_date;
  }
  return {
    type: GET_CUSTOM_DAILY_TIMECARDS_V2,
    payload: axios.get(`${URLS.CUSTOM_TIMECARD}employee/`, { params }),
  };
}

export function getDailyTimecardsDetail(params) {
  return {
    type: GET_DAILY_TIMECARD_DETAIL,
    payload: axios.get(`${URLS.TIMECARD}${URLS.DAILY_TIMECARD}${params.id}/`),
  };
}

export function getHistoricTimecards(params) {
  return {
    type: GET_DAILY_TIMECARDS,
    payload: axios.get(`${URLS.TIMECARD}${URLS.HISTORIC_TIMECARDS}`, { params }),
  };
}

export function getDeviateDailyTimecards(parameters) {
  const { parent, ...params } = parameters;
  if(params.payroll_id === 'custom'){
    delete params.payroll_id;
  }
  else if(params.payroll_id && params.payroll_id !== 'custom'){
    delete params.start_date;
    delete params.end_date;
  }
  return {
    type: GET_DAILY_DEVIATED_TIMECARDS,
    payload: axios.get(URLS.ACTIVITY_DEAVIATION, { params }),
  };
}

export function resetHistoricTimecards(data) {
  return {
    type: RESET_HISTORIC_TIMECARD,
    payload: data,
  };
}

export function setWeekAndDate(data) {
  return {
    type: SET_WEEK_AND_DATE,
    payload: data,
  };
}

export function addShift(data) {
  return {
    type: ADD_SHIFT,
    payload: data,
  };
}

export function addShiftTime(data) {
  return {
    type: ADD_SHIFT_TIME,
    payload: data,
  };
}

export function addMealTime(data) {
  return {
    type: ADD_MEAL_TIME,
    payload: data,
  };
}

export function resetMealTime(data) {
  return {
    type: RESET_MEAL_TIME,
    payload: data,
  };
}

export function resetShiftTime(data) {
  return {
    type: RESET_SHIFT_TIME,
    payload: data,
  };
}

export function addMeal(data) {
  return {
    type: ADD_MEAL,
    payload: data,
  };
}

export function approveDailyTimecard(data) {
  const params={
    is_approved: true
  }
  return {
    type: APPROVE_DAILY_TIMECARD,
    payload: axios.patch(`${URLS.CUSTOM_TIMECARD}${data.id}/`,{...params }),
  };
}

export function disapproveDailyTimecard(data) {
  const params={
    is_approved : false
  }
  return {
    type: DISAPPROVE_DAILY_TIMECARD,
    payload: axios.patch(`${URLS.CUSTOM_TIMECARD}${data.id}/`,{...params }),
  };
}

export function toggleCollapse(data) {
  return {
    type: TOGGLE_COLLAPSE,
    payload: data,
  };
}

export function updateMileage(data) {
  const params={
    mileage: data.data.mileage
  }
  return {
    type: UPDATE_MILEAGE,
    payload: axios.patch(`${URLS.CUSTOM_TIMECARD}${data.id}/`,{...params }),
  };
}

export function updateUserPreference(params) {
  return {
    type: UPDATE_USER_VIEW,
    payload: axios.put(`${URLS.CUSTOM_TIMECARD}${URLS.USER_PREFERENCE}`,{...params }),
  };
}

export function getUserPreference(params) {
  return {
    type: GET_USER_VIEW,
    payload: axios.get(`${URLS.CUSTOM_TIMECARD}${URLS.USER_PREFERENCE}`,{params: params }),
  }
}

function getQueryParams(params) {
  let queryParams;

  if(params.non_others && params.latest){
    return queryParams = `?context_ids={"session_id":${params.context_ids.session_id}}&others=false`;
  }
  else if(params.others){
    return queryParams = `?context_ids={"session_id":${params.context_ids.session_id}}&others=true`;
  }
  else{
    return queryParams = `?context_ids={"session_id":${params.context_ids.session_id}}`;
  }
}

export function feedbackTemplate(params) {
  return {
    type: FEEDBACK_TEMPLATE,
    payload: axios.get(`${URLS.FEEDBACK}${getQueryParams(params)}`),
  }
}

const defaultState = {
  list: [],
  detail: {},
  deviatedList: { results: [] },
  week: null,
  startDate: null,
  endDate: null,
  adjustmentList: {},
  userViewPreference: {},
  feedbackList: {},
  feedbackResponse: {},
};

function SET_WEEK_AND_DATE_REDUCER(state, action) {
  return Object.assign({}, state, { week: action.payload.week,
    startDate: action.payload.startDate,
    endDate: action.payload.endDate
  });
}


function GET_CUSTOM_DAILY_TIMECARDS_V2_FULFILLED_REDUCER(state, action) {
  let weekStartDate;
  const weekArray = [];
  const day = moment(state.startDate).day();
  if(day - 1 === parseInt(state.week,10)){
    // if week day is the selected day, weekStartDate is same.
    weekStartDate = moment(state.startDate);
  }
  else {
    // previous week day corresponding to the given startDate and given week day.
    // for negative values, week day array is reversed.
    // example- friday corresponds to -2.
    // subtract -1 because of the difference of 1 in every week day when compared the constants week day and moment.js week day.
    weekStartDate = moment(state.startDate).day(-(7-parseInt(state.week,10) -1));
  }

  const data =[];
  action.payload.results.map((item, index) => {
    data.push(item);
    if(Object.keys(item.adjustments).length){
      const newItem = { ...item,
        ...item.adjustments,
        adjustment_type: true,
        is_adjustment: false,
        id: `${item.id}_ad`,
        ids: item.id,
        punch_in_time: null,
        punch_in_time_display: null,
        is_meal_paid: false,
        meal_period_time: null,
        meal_period_time_display: null,
        total_time_seconds: null,
        total_time: null,
        timecard: item.timecard,
      }
      data.push(newItem);
    }
    return true;
  });


  const newData = [...data ];

  if (newData.length > 0) {
    //  startDate and endDate contains payroll start as well as end date
    const startDate = weekStartDate.format('YYYY-MM-DD');
    const endDate = moment(state.endDate).format('YYYY-MM-DD');

    //  create a first object in weekArray using startDate
    weekArray.push({
      weekStartDate: startDate,
      weekEndDate: moment(startDate).add(6, 'days').format('YYYY-MM-DD'),
      punch_in_time: null,
      is_meal_paid: false,
      meal_period_time: null,
      meal_paid_time: null,
      meal_unpaid_time: null,
      over_time: null,
      double_over_time: null,
      total_time_seconds: null,
      regular_time: null,
      child: [],
    });
    let i = 0;
    //  create weeks object lie between payroll start date, end date and push into weekArray
    while (moment(endDate) > moment(weekArray[i].weekEndDate)) {
      weekArray.push({
        weekStartDate: moment(weekArray[i].weekStartDate).add(7, 'days').format('YYYY-MM-DD'),
        weekEndDate: moment(weekArray[i].weekEndDate).add(7, 'days').format('YYYY-MM-DD'),
        punch_in_time: null,
        meal_period_time: null,
        meal_paid_time: null,
        meal_unpaid_time: null,
        over_time: null,
        double_over_time: null,
        total_time_seconds: null,
        regular_time: null,
        is_meal_paid: false,
        employee: null,
        child: [],
      })
      i+=1;
    }


    // sum of all entries lie between particular week and
    // roundoffSecods of each entries by using roundOffSeconds function
    for (let j = 0; j < weekArray.length; j += 1) {
      for (let k = 0; k < newData.length; k += 1) {
        weekArray[j].employee = newData[k].employee;
        weekArray[j].id = newData[k].id;
        if (moment(newData[k].date) >= moment(weekArray[j].weekStartDate) &&
          moment(newData[k].date) <= moment(weekArray[j].weekEndDate)) {
          /*console.log('newData[k].meal_unpaid_time', newData[k].meal_unpaid_time);*/
          weekArray[j].punch_in_time += newData[k].punch_in_time;
          weekArray[j].is_meal_paid += newData[k].is_meal_paid;
          weekArray[j].meal_period_time += newData[k].meal_period_time;
          if(!newData[k].adjustment_type){
            weekArray[j].regular_time += newData[k].regular_time;
            weekArray[j].over_time += newData[k].over_time;
            weekArray[j].double_over_time += newData[k].double_over_time;
            weekArray[j].total_time_seconds += newData[k].total_time_seconds;
            weekArray[j].meal_unpaid_time += newData[k].meal_unpaid_time;
            weekArray[j].meal_paid_time += newData[k].meal_paid_time;
          }
          else{
            weekArray[j].total_time_seconds += newData[k].total_adjustment_time;
          }
          weekArray[j].child.push(newData[k]);
        }
      }
      // convert final weekArray display time into hh:mm by using secondsToHHMM function
      weekArray[j].punch_in_time_display = secondsToHHMM(weekArray[j].punch_in_time);
      weekArray[j].meal_paid_time_display = secondsToHHMM(weekArray[j].meal_paid_time);
      weekArray[j].meal_unpaid_time_display = secondsToHHMM(weekArray[j].meal_unpaid_time);
      weekArray[j].meal_period_time_display = secondsToHHMM(weekArray[j].meal_period_time);
      weekArray[j].regular_time_display = secondsToHHMM(weekArray[j].regular_time);
      weekArray[j].total_time = secondsToHHMM(weekArray[j].total_time_seconds);
      weekArray[j].over_time_display = secondsToHHMM(weekArray[j].over_time);
      weekArray[j].double_over_time_display = secondsToHHMM(weekArray[j].double_over_time);
      if(weekArray[j].child.length > 0){
        weekArray[j].date_display = "Weekly Total";
        const { child, ...rest } = weekArray[j];
        weekArray[j].child.push({ ...rest });
        const length = weekArray[j].child.length;
        delete weekArray[j].child[length-1].id;
      }
    }
  }

  return Object.assign({}, state, {
    list: weekArray,
  });
}

function GET_DAILY_TIMECARDS_FULFILLED_REDUCER(state, action) {
  const weekArray = [];
  const data =[];
  action.payload.map((item, index) => {
    data.push(item);
    if(Object.keys(item.adjustments).length){
      const newItem = { ...item,
        ...item.adjustments,
        adjustment_type: true,
        is_adjustment: false,
        id: `${item.id}_ad`,
        ids: item.id,
        punch_in_time: null,
        punch_in_time_display: null,
        is_meal_paid: false,
        meal_period_time: null,
        meal_period_time_display: null,
        total_time: null,
        total_time_seconds: null,
        timecard: item.timecard,
      }
      data.push(newItem);
    }
    return true;
  });

  const newData = data.filter((item) => !item.is_adjustment);

  if (newData.length > 0) {
    //  startDate and endDate contains payroll start as well as end date
    const startDate = moment(newData[0].timecard.week_start_date).format('YYYY-MM-DD');
    const endDate = moment(newData[0].timecard.end_date).subtract(1, 'days').format('YYYY-MM-DD');
    //  create a first object in weekArray using startDate
    
    weekArray.push({
      weekStartDate: startDate,
      weekEndDate: moment(startDate).add(6, 'days').format('YYYY-MM-DD'),
      punch_in_time: null,
      is_meal_paid: false,
      meal_period_time: null,
      meal_paid_time: null,
      meal_unpaid_time: null,
      over_time: null,
      double_over_time: null,
      total_time_seconds: null,
      regular_time: null,
      child: [],
    });

    let i = 0;
    //  create weeks object lie between payroll start date, end date and push into weekArray
    while (moment(endDate) > moment(weekArray[i].weekEndDate)) {
      weekArray.push({
        weekStartDate: moment(weekArray[i].weekStartDate).add(7, 'days').format('YYYY-MM-DD'),
        weekEndDate: moment(weekArray[i].weekEndDate).add(7, 'days').format('YYYY-MM-DD'),
        punch_in_time: null,
        meal_period_time: null,
        meal_paid_time: null,
        meal_unpaid_time: null,
        over_time: null,
        double_over_time: null,
        total_time_seconds: null,
        regular_time: null,
        is_meal_paid: false,
        child: [],
      })
      i+=1;
    }


    // sum of all entries lie between particular week and
    // roundoffSecods of each entries by using roundOffSeconds function
    for (let j = 0; j < weekArray.length; j += 1) {
      for (let k = 0; k < newData.length; k += 1) {
        if (moment(newData[k].date) >= moment(weekArray[j].weekStartDate) &&
          moment(newData[k].date) <= moment(weekArray[j].weekEndDate)) {
          weekArray[j].punch_in_time += newData[k].punch_in_time;
          weekArray[j].is_meal_paid = newData[k].is_meal_paid;
          weekArray[j].meal_period_time += newData[k].meal_period_time;
          weekArray[j].meal_paid_time += newData[k].meal_paid_time;
          weekArray[j].meal_unpaid_time += newData[k].meal_unpaid_time;
          weekArray[j].regular_time += newData[k].regular_time;
          weekArray[j].total_time_seconds += newData[k].total_time_seconds;
          weekArray[j].over_time += newData[k].over_time;
          weekArray[j].double_over_time += newData[k].double_over_time;
          weekArray[j].child.push(newData[k]);
        }
      }
      // convert final weekArray display time into hh:mm by using secondsToHHMM function
      weekArray[j].punch_in_time_display = secondsToHHMM(weekArray[j].punch_in_time);
      weekArray[j].meal_period_time_display = secondsToHHMM(weekArray[j].meal_period_time);
      weekArray[j].meal_paid_time_display = secondsToHHMM(weekArray[j].meal_paid_time_display);
       weekArray[j].meal_unpaid_time_display = secondsToHHMM(weekArray[j].meal_unpaid_time_display);
      weekArray[j].regular_time_display = secondsToHHMM(weekArray[j].regular_time);
      weekArray[j].total_time = secondsToHHMM(weekArray[j].total_time_seconds);
      weekArray[j].over_time_display = secondsToHHMM(weekArray[j].over_time);
      weekArray[j].double_over_time_display = secondsToHHMM(weekArray[j].double_over_time);
      if(weekArray[j].child.length > 0){
        weekArray[j].date_display = "Weekly Total";
        const { child, ...rest } = weekArray[j];
        weekArray[j].child.push({ ...rest });
      }
    }
  }
  
  return Object.assign({}, state, {
    list: weekArray,
  });
}

function GET_DAILY_TIMECARDS_V3_FULFILLED_REDUCER(state, action) {
  const weekArray = [];
  const arr = action.payload.map((i) => ({ ...i, isCollapseOpen: true }));
  arr.map((item, index) => {
    if(Object.keys(item.adjustments).length){
      const newItem = { ...item,
        ...item.adjustments,
        adjustment_type: true,
        is_adjustment: false,
        id: `${item.id}_ad`,
        ids: item.id,
        punch_in_time: null,
        punch_in_time_display: null,
        is_meal_paid: false,
        meal_period_time: null,
        meal_period_time_display: null,
        total_time: null,
        total_time_seconds: null,
        timecard: item.timecard,
      }
      item.activity_split.push(newItem);
    }
    return true;
  });

  const newData = arr.filter((item) => !item.is_adjustment);
  if (newData.length > 0) {
    //  startDate and endDate contains payroll start as well as end date
    const startDate = moment(newData[0].timecard.week_start_date).format('YYYY-MM-DD');
    const endDate = moment(newData[0].timecard.end_date).subtract(1, 'days').format('YYYY-MM-DD');
    //  create a first object in weekArray using startDate
    weekArray.push({
      weekStartDate: startDate,
      weekEndDate: moment(startDate).add(6, 'days').format('YYYY-MM-DD'),
      punch_in_time: null,
      is_meal_paid: false,
      meal_period_time: null,
      over_time: null,
      double_over_time: null,
      total_time_seconds: null,
      regular_time: null,
      child: [],
    });
    
    let i = 0;

    //  create weeks object lie between payroll start date, end date and push into weekArray

    while (moment(endDate) > moment(weekArray[i].weekEndDate)) {
      weekArray.push({
        weekStartDate: moment(weekArray[i].weekStartDate).add(7, 'days').format('YYYY-MM-DD'),
        weekEndDate: moment(weekArray[i].weekEndDate).add(7, 'days').format('YYYY-MM-DD'),
        punch_in_time: null,
        meal_period_time: null,
        over_time: null,
        double_over_time: null,
        total_time_seconds: null,
        regular_time: null,
        is_meal_paid: false,
        child: [],
      })
      i+=1;
    }


    // sum of all entries lie between particular week and
    // roundoffSecods of each entries by using roundOffSeconds function
    for (let j = 0; j < weekArray.length; j += 1) {
      for (let k = 0; k < newData.length; k += 1) {
        if (moment(newData[k].date) >= moment(weekArray[j].weekStartDate) &&
          moment(newData[k].date) <= moment(weekArray[j].weekEndDate)) {
          weekArray[j].punch_in_time += newData[k].punch_in_time;
          weekArray[j].is_meal_paid = newData[k].is_meal_paid;
          weekArray[j].meal_period_time += newData[k].meal_period_time;
          weekArray[j].regular_time += newData[k].regular_time;
          weekArray[j].total_time_seconds += newData[k].total_time_seconds;
          weekArray[j].over_time += newData[k].over_time;
          weekArray[j].double_over_time += newData[k].double_over_time;
          weekArray[j].child.push(newData[k]);
        }
      }
      // convert final weekArray display time into hh:mm by using secondsToHHMM function
      weekArray[j].punch_in_time_display = secondsToHHMM(weekArray[j].punch_in_time);
      weekArray[j].meal_period_time_display = secondsToHHMM(weekArray[j].meal_period_time);
      weekArray[j].regular_time_display = secondsToHHMM(weekArray[j].regular_time);
      weekArray[j].total_time = secondsToHHMM(weekArray[j].total_time_seconds);
      weekArray[j].over_time_display = secondsToHHMM(weekArray[j].over_time);
      weekArray[j].double_over_time_display = secondsToHHMM(weekArray[j].double_over_time);
      if(weekArray[j].child.length > 0){
        weekArray[j].date_display = "Weekly Total";
        const { child, ...rest } = weekArray[j];
        weekArray[j].child.push({ ...rest });
      }
    }
  }

  return Object.assign({}, state, {
    list: weekArray,
  });
}

// New reducer for custom timecard on inline view

function GET_CUSTOM_DAILY_TIMECARDS_FULFILLED_REDUCER(state, action) {
  let weekStartDate;
  const weekArray = [];
  const day = moment(state.startDate).day();
  if(day - 1 === parseInt(state.week,10)){
    /*if week day is the selected day, weekStartDate is same.*/
    weekStartDate = moment(state.startDate);
  }
  else {
    // previous week day corresponding to the given startDate and given week day.
    // for negative values, week day array is reversed.
    // example- friday corresponds to -2.
    // subtract -1 because of the difference of 1 in every week day when compared the constants week day and moment.js week day.
    weekStartDate = moment(state.startDate).day(-(7-parseInt(state.week,10) -1));
  }


  const data =[];
  action.payload.results.map((item, index) => {
    const shiftArr = item.activity_split.filter((i) => i.activity_code === 'PUNCH-IN');
    data.push({ ...item, isCollapseOpen: true, daily_timecard: shiftArr.length !== 0 })
    item.activity_split.map((shift)=>{
      if(!shift.meals.length){
        const shiftStart = moment(shift.start_datetime).format("YYYY-MM-DD");
        const shiftEnd = moment(shift.end_datetime).format("YYYY-MM-DD");
        const newMeal = {
          date:shift.date,
          start_date: shiftStart,
          end_date: shiftEnd, 
          daily_timecard_id: shift.daily_timecard_id, 
          company_id: shift.company_id,
          new_meal: true,
          shift_exist: true,
          date_display: shift.date_display,
          activity_code: "MEAL-PERIOD",
          activity_name: "Meal Period",
          end_time: "",
          start_time: "",
          id:shift.id,
        }
        shift.meals.push(newMeal);
      }
      return true;  
    })
    if(!shiftArr.length){
      const newShift = {
        date:item.date,
        start_date:item.date,
        end_date:item.date, 
        daily_timecard_id: item.id, 
        company_id: item.company_id,
        new_shift: true,
        shift_exist:false,
        date_display: item.date_display,
        activity_code: "PUNCH-IN",
        activity_name: "Shift",
        end_time: "",
        start_time: "",
        total_time:"",
        total_time_display:"",
        id:item.id,
        meals: [{
          date:item.date, 
          start_date:item.date,
          end_date:item.date,
          shift_exist:false,
          daily_timecard_id: item.id, 
          id:item.id,
          company_id: item.company_id,
          new_meal: true,
          date_display: item.date_display,
          activity_code: "MEAL-PERIOD",
          activity_name: "Meal Period",
          end_time: "",
          start_time: "", 
        }]
      }
      item.activity_split.push(newShift);
    }
    if(item.adjustments && item.adjustments.length){
      for(let p = 0; p < item.adjustments.length; p++){
        const { activity_split, ...rest } = item;
        let newItem = { ...rest,
          ...item.adjustments[p],
          adjustment_type: true,
          is_adjustment: false,
          id: `${item.id}_ad`,
          ids: item.id,
          punch_in_time: null,
          punch_in_time_display: null,
          is_meal_paid: false,
          meal_period_time: null,
          meal_paid_time: null,
          meal_unpaid_time: null,
          meal_period_time_display: null,
          total_time_seconds: null,
          total_time: null,
          timecard: item.timecard,
        }
        item.activity_split.push(newItem);
      }
    }
    if(item.pto_requests && item.pto_requests.length){
      for(let p = 0; p < item.pto_requests.length; p++){
        const { activity_split,adjustments, ...rest } = item;
        let newItem = { ...rest,
          ...item.pto_requests[p],
          pto_requests_type: true,
          id: `${item.id}_pr`,
          ids: item.id,
          punch_in_time: null,
          punch_in_time_display: null,
          is_meal_paid: false,
          meal_period_time: null,
          meal_paid_time: null,
          meal_unpaid_time: null,
          meal_period_time_display: null,
          total_time_seconds: null,
          total_time: null,
          timecard: item.timecard,
        }
        item.activity_split.push(newItem);
      }
    }
    return true;
  });
  
  const newData = [...data];
  // const newData = data.filter((item) => !item.is_adjustment);
  if (newData.length > 0) {
    //  startDate and endDate contains payroll start as well as end date
    const startDate = weekStartDate.format('YYYY-MM-DD');
    const endDate = moment(state.endDate).format('YYYY-MM-DD');

    //  create a first object in weekArray using startDate
    weekArray.push({
      weekStartDate: startDate,
      weekEndDate: moment(startDate).add(6, 'days').format('YYYY-MM-DD'),
      punch_in_time: null,
      is_meal_paid: false,
      meal_period_time: null,
      meal_paid_time: null,
      meal_unpaid_time: null,
      over_time: null,
      double_over_time: null,
      total_time_seconds: null,
      mileage: null,              //mileage key added
      regular_time: null,
      child: [],
    });
    let i = 0;
    //  create weeks object lie between payroll start date, end date and push into weekArray
    while (moment(endDate) > moment(weekArray[i].weekEndDate)) {
      weekArray.push({
        weekStartDate: moment(weekArray[i].weekStartDate).add(7, 'days').format('YYYY-MM-DD'),
        weekEndDate: moment(weekArray[i].weekEndDate).add(7, 'days').format('YYYY-MM-DD'),
        punch_in_time: null,
        meal_period_time: null,
        meal_paid_time: null,
        meal_unpaid_time: null,
        over_time: null,
        double_over_time: null,
        total_time_seconds: null,
        regular_time: null,
        mileage: null,
        is_meal_paid: false,
        employee: null,
        child: [],
      })
      i+=1;
    }

    // sum of all entries lie between particular week and
    // roundoffSecods of each entries by using roundOffSeconds function
    for (let j = 0; j < weekArray.length; j += 1) {
      for (let k = 0; k < newData.length; k += 1) {
        if(newData[k].employee){
          weekArray[j].employee = newData[k].employee;
          weekArray[j].id = newData[k].id;
          if (moment(newData[k].date) >= moment(weekArray[j].weekStartDate) &&
            moment(newData[k].date) <= moment(weekArray[j].weekEndDate)) {
            weekArray[j].punch_in_time += newData[k].punch_in_time;

            weekArray[j].is_meal_paid = newData[k].is_meal_paid;
            if(newData[k].is_meal_paid){
              weekArray[j].meal_period_time += newData[k].meal_period_time;
            }
            else{
            weekArray[j].meal_period_time -= newData[k].meal_period_time;
            }
            weekArray[j].meal_paid_time += newData[k].meal_paid_time;
            weekArray[j].meal_unpaid_time += newData[k].meal_unpaid_time;
            weekArray[j].regular_time += newData[k].regular_time;
            weekArray[j].total_time_seconds += newData[k].total_time_seconds;
            weekArray[j].over_time += newData[k].over_time;
            weekArray[j].double_over_time += newData[k].double_over_time;
            weekArray[j].mileage += newData[k].mileage;               //mileage key added

            weekArray[j].child.push(newData[k]);
          }
        }
        else {
          // weekArray[j].employee = newData[k].employee;
          weekArray[j].id = newData[k].id;
          if (moment(newData[k].date) >= moment(weekArray[j].weekStartDate) &&
            moment(newData[k].date) <= moment(weekArray[j].weekEndDate)) {
            weekArray[j].punch_in_time += 0;
            weekArray[j].is_meal_paid = newData[k].is_meal_paid;
            weekArray[j].meal_period_time += 0;
            weekArray[j].meal_paid_time += 0;
            weekArray[j].meal_unpaid_time += 0;
            weekArray[j].regular_time += 0;
            weekArray[j].total_time_seconds += 0;
            weekArray[j].over_time += 0;
            weekArray[j].double_over_time += 0;
            weekArray[j].mileage += 0;            //mileage key added
            weekArray[j].child.push(newData[k]); 
          }   
        }  
      }
      // convert final weekArray display time into hh:mm by using secondsToHHMM function
      weekArray[j].punch_in_time_display = secondsToHHMM(weekArray[j].punch_in_time);
      weekArray[j].meal_period_time_display = secondsToHHMM(weekArray[j].meal_period_time);
      weekArray[j].meal_paid_time_display = secondsToHHMM(weekArray[j].meal_paid_time);
      weekArray[j].meal_unpaid_time_display = secondsToHHMM(weekArray[j].meal_unpaid_time);
      weekArray[j].regular_time_display = secondsToHHMM(weekArray[j].regular_time);
      weekArray[j].total_time = secondsToHHMM(weekArray[j].total_time_seconds);
      weekArray[j].over_time_display = secondsToHHMM(weekArray[j].over_time);
      weekArray[j].double_over_time_display = secondsToHHMM(weekArray[j].double_over_time);
      if(weekArray[j].child.length > 0){
        weekArray[j].date_display = "Weekly Total";
        weekArray[j].daily_timecard = true;
        const { child, ...rest } = weekArray[j];
        weekArray[j].child.push({ ...rest });
        const length = weekArray[j].child.length;
        delete weekArray[j].child[length-1].id;
      }
    }
  }
  return Object.assign({}, state, {
    list: weekArray,
  });
}

/*function groupBy(arr, key) {*/
  /* eslint-disable */
 /* return arr.reduce(function(acc, x) {
    (acc[x[key]] = acc[x[key]] || []).push(x);
    return acc;
  }, {});*/
  /* eslint-enable */


function GET_DAILY_DEVIATED_TIMECARDS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    deviatedList: action.payload,
  });
}

function RESET_HISTORIC_TIMECARD_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: []
  });
}

function GET_DAILY_TIMECARD_DETAIL_PENDING_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: {},
  });
}

function GET_DAILY_DEVIATED_TIMECARDS_PENDING_REDUCER(state, action) {
  return Object.assign({}, state, {
    deviatedList: [],
  });
}

function GET_DAILY_TIMECARD_DETAIL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function APPROVE_DAILY_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function DISAPPROVE_DAILY_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function GET_DAILY_TIMECARD_ADJUSTMENTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    adjustmentList: action.payload,
  });
}

function UPDATE_MILEAGE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function GET_USER_VIEW_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userViewPreference: action.payload,
  });
}

function UPDATE_USER_VIEW_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userViewPreference: action.payload,
  });
}

function FEEDBACK_TEMPLATE_FULFILLED_REDUCER(state, action) {
  const arr1 = [];
  let arr2 = [];
  action.payload.results && action.payload.results.forEach((i , index)=>
    {
      arr1.push(i.template.template_data.questions);
    }) 
 action.payload.results && action.payload.results.forEach(( item , id)=>
    {
      arr2.push(item.response.questions);
    })
 let result = arr1.map((ques, qindex) =>{
  return ques.map((q)=>({...q, response : arr2[qindex].find((ans)=>ans.sequence_of_configuration == q.sequence_of_configuration).answer}))
 });
 const newAns = result.map((res,resId)=>{
  let newRes = res.map((newi)=>{
    return {
      ...newi,
      create_date : action.payload.results[resId] && action.payload.results[resId].create_date_display,
      created_by : action.payload.results[resId] && action.payload.results[resId].user && action.payload.results[resId].user.first_name,
      label: action.payload.results[resId] && action.payload.results[resId].template && action.payload.results[resId].template.template_data && action.payload.results[resId].template.template_data.label,
      surveyType: action.payload.results[resId] && action.payload.results[resId].template && action.payload.results[resId].template.survey_type,
      answer :  newi.answer && newi.answer.map((newA)=> {
       return {...newA , checked : newi.response.find((j)=>j.value == newA.value) ? true : false} 
      })
    }})
  return newRes;
 });
  return Object.assign({}, state, {
    feedbackList: newAns,
  });
}

function ADD_SHIFT_REDUCER(state, action) {
  const arr = [...state.list ];
  const newList= arr.map((i) => {
    const newArr = i.child.map((j) => {
      if(j.id === action.payload.id){
        j.activity_split.push({
          date: j.date,
          start_date: j.date,
          end_date: j.date, 
          daily_timecard_id: !j.daily_timecard ? null : j.activity_split[0].daily_timecard_id, 
          company_id: !j.daily_timecard ? null : j.activity_split[0].company_id,
          new_shift: true,
          date_display: !j.daily_timecard ? j.date_display : j.activity_split[0].date_display,
          activity_code: "PUNCH-IN",
          activity_name: "Shift",
          shift_exist: false,
          end_time: "",
          meals: [{
            date: !j.daily_timecard ? j.date :j.activity_split[0].date, 
            daily_timecard_id: !j.daily_timecard ? null : j.activity_split[0].daily_timecard_id, 
            company_id: !j.daily_timecard ? null : j.activity_split[0].company_id,
            new_meal: true,
            shift_exist:false,
            date_display: !j.daily_timecard ? j.date_display : j.activity_split[0].date_display,
            activity_code: "MEAL-PERIOD",
            activity_name: "Meal Period",
            end_time: "",
            start_time: "",
          }],
          total_time: 0,
          start_time: "",
          id: Math.floor(Math.random() * 20000),
        });
      }
    return j
    });
    return { ...i, child: newArr };
  });
  return Object.assign({}, state, { list: newList });
}

function ADD_SHIFT_TIME_REDUCER(state, action) {
  const arr = [...state.list ];
  const newList = arr.map((i) => {
    const newArr = i.child.map((j) => {
      const newShiftTime = j.activity_split && j.activity_split.map((k) => {
        if(k.id === action.payload.id){
          return action.payload;
        }
        return k;
      })
      return { ...j, activity_split: newShiftTime };
    }) 
    return {...i, child: newArr };
  });
  return Object.assign({}, state, { list: newList });
}

function RESET_SHIFT_TIME_REDUCER(state, action) {
  const arr = [...state.list ];
  const newList = arr.map((i) => {
    const newArr = i.child.map((j) => {
      const newShiftTime = j.activity_split && j.activity_split.map((k) => {
        if(k.id === action.payload.id){
          const resettedShift = {...action.payload, 
            start_time : "",
            end_time : "",
            meals: [{
              date:action.payload.date, 
              start_date:action.payload.date,
              end_date:action.payload.date,
              shift_exist:false,
              daily_timecard_id: action.payload.id, 
              id:action.payload.id,
              company_id: action.payload.company_id,
              new_meal: true,
              date_display: action.payload.date_display,
              activity_code: "MEAL-PERIOD",
              activity_name: "Meal Period",
              end_time: "",
              start_time: "", 
            }]
          }
          return resettedShift;
        }
        return k;
      })
      return { ...j, activity_split: newShiftTime };
    }) 
    return {...i, child: newArr };
  });
  return Object.assign({}, state, { list: newList });
}

function ADD_MEAL_TIME_REDUCER(state, action) {
  const arr = [...state.list ];
  const newList = arr.map((i) => {
    const newArr = i.child.map((j) => {
      const newShiftTime = j.activity_split && j.activity_split.map((k) => {
        const newMealTime = k.meals && k.meals.map((l) => {
          if(l.id === action.payload.id){
            return action.payload;
          }  
          return l;
        })
        return { ...k, meals: newMealTime}
      })  
      return { ...j, activity_split: newShiftTime };
    }) 
    return {...i, child: newArr };
  });
  return Object.assign({}, state, { list: newList });
}


function RESET_MEAL_TIME_REDUCER(state, action) {
  const arr = [...state.list ];
  const newList = arr.map((i) => {
    const newArr = i.child.map((j) => {
      const newShiftTime = j.activity_split && j.activity_split.map((k) => {
        const newMealTime = k.meals && k.meals.map((l) => {
          if(l.id === action.payload.id){
            const resettedMeal = {...action.payload, start_time : "", end_time : ""}
            return resettedMeal;
          }  
          return l;
        })
        return { ...k, meals: newMealTime}
      })  
      return { ...j, activity_split: newShiftTime };
    }) 
    return {...i, child: newArr };
  });
  return Object.assign({}, state, { list: newList });
}



function ADD_MEAL_REDUCER(state, action) {
  const minDate= moment(action.payload.start_datetime).format("YYYY-MM-DD");
  const shiftStart = moment(action.payload.start_datetime).format("YYYY-MM-DD");
  const shiftEnd = moment(action.payload.end_datetime).format("YYYY-MM-DD");
  const arr = [...state.list ];
  arr.map((i) => {
    const newArr = i.child.map((j) => {
      const newMeal = j.activity_split && j.activity_split.map((k) => {
        if(k.id === action.payload.id){
          k.meals.push({
            date: (action.payload.date === minDate) ? action.payload.date : minDate, 
            daily_timecard_id: action.payload.daily_timecard_id, 
            company_id: action.payload.company_id,
            new_meal: true,
            shift_exist: true,
            id: Math.floor(Math.random() * 20000),
            date_display: action.payload.date_display,
            activity_code: "MEAL-PERIOD",
            activity_name: "Meal Period",
            end_time: "",
            start_time: "",
            start_date: shiftStart,
            end_date: shiftEnd,
          });
        }  
        return k;
      })
      return newMeal;
    }) 
    return {...i, child: newArr };
  });
  return Object.assign({}, state, { list: arr });
}

function TOGGLE_COLLAPSE_REDUCER(state, action) {
  const arr = [...state.list ];
  const newList = arr.map((i) => {
    const newArr = i.child.map((j) => {
      const newItem = { ...j };
      if(j.id === action.payload.id){
        newItem.isCollapseOpen = !j.isCollapseOpen;
      }
      return newItem;
    });
    return { ...i, child: newArr };
  });
  return Object.assign({}, state, { list: newList });
}

const handlers = {
  [GET_CUSTOM_DAILY_TIMECARDS_FULFILLED]: GET_CUSTOM_DAILY_TIMECARDS_FULFILLED_REDUCER,
  [GET_CUSTOM_DAILY_TIMECARDS_V2_FULFILLED]: GET_CUSTOM_DAILY_TIMECARDS_V2_FULFILLED_REDUCER,
  [GET_DAILY_TIMECARDS_FULFILLED]: GET_DAILY_TIMECARDS_FULFILLED_REDUCER,
  [GET_DAILY_TIMECARDS_V3_FULFILLED]: GET_DAILY_TIMECARDS_V3_FULFILLED_REDUCER,
  [GET_DAILY_TIMECARD_ADJUSTMENTS_FULFILLED]: GET_DAILY_TIMECARD_ADJUSTMENTS_FULFILLED_REDUCER,
  [GET_DAILY_DEVIATED_TIMECARDS_FULFILLED]: GET_DAILY_DEVIATED_TIMECARDS_FULFILLED_REDUCER,
  [GET_DAILY_TIMECARD_DETAIL_PENDING]: GET_DAILY_TIMECARD_DETAIL_PENDING_REDUCER,
  [GET_DAILY_DEVIATED_TIMECARDS_PENDING]: GET_DAILY_DEVIATED_TIMECARDS_PENDING_REDUCER,
  [RESET_HISTORIC_TIMECARD]: RESET_HISTORIC_TIMECARD_REDUCER,
  [GET_DAILY_TIMECARD_DETAIL_FULFILLED]: GET_DAILY_TIMECARD_DETAIL_FULFILLED_REDUCER,
  [GET_DAILY_TIMECARD_DETAIL_FULFILLED]: GET_DAILY_TIMECARD_DETAIL_FULFILLED_REDUCER,
  [SET_WEEK_AND_DATE]: SET_WEEK_AND_DATE_REDUCER,
  [APPROVE_DAILY_TIMECARD_FULFILLED]: APPROVE_DAILY_TIMECARD_FULFILLED_REDUCER,
  [DISAPPROVE_DAILY_TIMECARD_FULFILLED]: DISAPPROVE_DAILY_TIMECARD_FULFILLED_REDUCER,
  [TOGGLE_COLLAPSE]: TOGGLE_COLLAPSE_REDUCER,
  [ADD_SHIFT]: ADD_SHIFT_REDUCER,
  [ADD_SHIFT_TIME]: ADD_SHIFT_TIME_REDUCER,
  [ADD_MEAL_TIME]: ADD_MEAL_TIME_REDUCER,
  [ADD_MEAL]: ADD_MEAL_REDUCER,
  [RESET_MEAL_TIME]: RESET_MEAL_TIME_REDUCER,
  [RESET_SHIFT_TIME]: RESET_SHIFT_TIME_REDUCER,
  [UPDATE_MILEAGE_FULFILLED]: UPDATE_MILEAGE_FULFILLED_REDUCER,
  [GET_USER_VIEW_FULFILLED]: GET_USER_VIEW_FULFILLED_REDUCER,
  [UPDATE_USER_VIEW_FULFILLED]: UPDATE_USER_VIEW_FULFILLED_REDUCER,
  [FEEDBACK_TEMPLATE_FULFILLED] : FEEDBACK_TEMPLATE_FULFILLED_REDUCER 
};


const dailyTimecardSelector = state => state.timecards.dailyTimecard;


export const dailyTimecardList = createSelector(
  dailyTimecardSelector,
  instance => instance.list,
);

export const adjustmentList = createSelector(
  dailyTimecardSelector,
  instance => instance.adjustmentList,
);

export const dailyDeviatedTimecardList = createSelector(
  dailyTimecardSelector,
  instance => instance.deviatedList,
);

export const dailyTimecardDetail = createSelector(
  dailyTimecardSelector,
  instance => instance.detail
);

export const payPeriodTotal = createSelector(
  dailyTimecardList,
  (instance) => { 
    const periodTotal = {
      punch_in_time:null,
      meal_period_time:0,
      meal_paid_time:0,
      meal_unpaid_time:0,
      is_meal_paid:false,
      regular_time:null,
      total_time_seconds:null,
      over_time:null,
      double_over_time:null,
      mileage:null,                   // mileage key added
    };
    for (let i = 0; i < instance.length; i += 1) {
      // check each week is_meal_paid if its true then
      // add meal period time else substract meal period time from
      // toal meal period time
      periodTotal.meal_period_time += instance[i].meal_period_time;
      periodTotal.meal_paid_time += instance[i].meal_paid_time;
      periodTotal.meal_unpaid_time += instance[i].meal_unpaid_time;
      periodTotal.punch_in_time += instance[i].punch_in_time;
      periodTotal.regular_time += instance[i].regular_time;
      periodTotal.total_time_seconds += instance[i].total_time_seconds;
      periodTotal.over_time += instance[i].over_time;
      periodTotal.double_over_time += instance[i].double_over_time;
      periodTotal.mileage += instance[i].mileage;               //mileage key added
    }
    // total meal period time is positive than set is_meal_paid falg to true
    if(periodTotal.meal_period_time >= 0) {
      periodTotal.is_meal_paid = true;
    }
    periodTotal.punch_in_time_display = secondsToHHMM(periodTotal.punch_in_time);
    periodTotal.meal_paid_time_display = secondsToHHMM(periodTotal.meal_paid_time);
    periodTotal.meal_unpaid_time_display = secondsToHHMM(periodTotal.meal_unpaid_time);
    periodTotal.regular_time_display = secondsToHHMM(periodTotal.regular_time);
    periodTotal.total_time = secondsToHHMM(periodTotal.total_time_seconds);
    periodTotal.over_time_display = secondsToHHMM(periodTotal.over_time);
    periodTotal.double_over_time_display = secondsToHHMM(periodTotal.double_over_time);
    periodTotal.date_display = "Pay Period Total";
    return periodTotal;
  }
);


export const payPeriodTotalV2 = createSelector(
  dailyTimecardList,
  (instance) => { 
    const periodTotal = {
      punch_in_time:null,
      meal_period_time:0,
      meal_paid_time:0,
      meal_unpaid_time:0,
      is_meal_paid:false,
      regular_time:null,
      total_time_seconds:null,
      over_time:null,
      double_over_time:null,
    };
    for (let i = 0; i < instance.length; i += 1) {

      // check each week is_meal_paid if its true then
      // add meal period time else substract meal period time from
      // toal meal period time
      if(instance[i].is_meal_paid){
        periodTotal.meal_period_time += instance[i].meal_period_time;
      }
      else 
      {
        periodTotal.meal_period_time += instance[i].meal_period_time; 
      }
      periodTotal.punch_in_time += instance[i].punch_in_time;
      periodTotal.meal_paid_time += instance[i].meal_paid_time;
      periodTotal.meal_unpaid_time += instance[i].meal_unpaid_time;
      periodTotal.regular_time += instance[i].regular_time;
      periodTotal.total_time_seconds += instance[i].total_time_seconds;
      periodTotal.over_time += instance[i].over_time;
      periodTotal.double_over_time += instance[i].double_over_time;
    }
    // total meal period time is positive than set is_meal_paid falg to true
    if(periodTotal.meal_period_time >= 0) {
      periodTotal.is_meal_paid = true;
    }
    periodTotal.punch_in_time_display = secondsToHHMM(periodTotal.punch_in_time);
    periodTotal.meal_paid_time_display = secondsToHHMM(periodTotal.meal_paid_time);
    periodTotal.meal_unpaid_time_display = secondsToHHMM(periodTotal.meal_unpaid_time);
    periodTotal.regular_time_display = secondsToHHMM(periodTotal.regular_time);
    periodTotal.total_time = secondsToHHMM(periodTotal.total_time_seconds);
    periodTotal.over_time_display = secondsToHHMM(periodTotal.over_time);
    periodTotal.double_over_time_display = secondsToHHMM(periodTotal.double_over_time);
    periodTotal.date_display = "Pay Period Total";
    return periodTotal;
  }
);

export const userViewPreference = createSelector(
  dailyTimecardSelector,
  instance => instance.userViewPreference,
);

export const feedbackResponseList = createSelector(
  dailyTimecardSelector,
  instance => instance.feedbackList,
);

export const feedbackResponse = createSelector(
  dailyTimecardSelector,
  instance => instance.feedbackResponse,
);

export default createReducer(defaultState, handlers);
