import React from 'react';

import PropTypes from 'prop-types';

class MarkerClusterComponent extends React.PureComponent {
  componentDidMount() {
    const { google } = this.props;
    this.infoWindow = new google.maps.InfoWindow();
    this.forceUpdate();
    if (google) {
      this.renderMarkerCluster();
    }
  }


  componentDidUpdate(prevProps) {
    // if (
    //   (this.props.map !== prevProps.map) ||
    //   (JSON.stringify(this.props.position) !== JSON.stringify(prevProps.position))
    // ) {
    //   // The relevant props have changed
    //   if (Object.keys(this.props.position).length) {
    //     console.log('render markerr-------------------');
    //     this.renderMarkerCluster();
    //   }
    //   else if (this.marker) {
    //     // console.log('destroy marker');
    //     this.marker.setMap(null);
    //     // this.marker = null;
    //   }
    // }
   
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.renderMarkerCluster();
    }
  }

  componentWillUnmount() {
    if (this.infowindow) this.infowindow.close();
    if (this.markerCluster) this.markerCluster.clearMarkers();
  }

  renderMarkerCluster() {
    const { map, google, onClick, data, selectedMarkerId, toggleInfoWindow } = this.props;
    if (this.markerCluster) this.markerCluster.clearMarkers();
    const markerArray = data.map((item, index) => {
      const { datetime, employee_name, address, activity_name, extra_data } = item;
      const { internetSpeed, lowPowerMode,wifiScanCount,
        deviceName, appVersion, batteryLevel, isLocationPermissionEnabled, osVersion, deviceOS, carrierName, accelerometerPermission} = extra_data || {};
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(item.position),
        map: this.props.map,
        label: {
          text: this.props.checked ? (index + 1).toString() : (index + 1).toString(),
          color: "#ffffff",
          fontWeight: '600',
          fontSize: '13px',
        },
        zIndex: selectedMarkerId === index ? 999 : index,
        // html: `<div>Employee:  ${item.employee_name} </div>
        // <div>Date:  ${item.datetime} </div>
        // <div>Activity: ${item.activity_name === 'Start of Geofence Breach' ? 'Left' : item.activity_name === 'End of Geofence Breach' ? 'Entered' : item.activity_name} </div>
        // </div> <div>Address:  ${item.address} </div>`,
        html: `
        <table rules="all" color="#D3D3D3" bordercolor="#eaebed">
  <tbody>
    ${(employee_name !== undefined && employee_name !== null) ?
      ` <tr>
        <th style="text-align: justify-left; padding: 5px">Employee</th>
        <td style="text-align: justify-left; padding: 5px"> ${employee_name}</td>
          </tr>` : " "}
          ${(datetime !== undefined && datetime !== null) ?
      `<tr>
          <th style="text-align: justify-left; padding: 5px">Date</th>
          <td style="text-align: justify-left; padding: 5px"> ${datetime} </td>
            </tr> `: " "}
           ${(activity_name !== undefined && activity_name !== null) ?
      `<tr>
            <th style="text-align: justify-left; padding: 5px">Activity</th>
            <td style="text-align: justify-left; padding: 5px">
           ${activity_name === 'Start of Geofence Breach' ? 'Left' : activity_name === 'End of Geofence Breach' ? 'Entered' : activity_name}
             </td>
               </tr>` : " "}
           ${(address !== undefined && address !== null) ?
      `<tr> 
           <th style="text-align: justify-left; padding: 5px">Address</th> 
            <td style="text-align: justify-left; padding: 5px">   ${address} </td> 
            </tr>`: ""}
          ${(deviceName !== undefined && deviceName !== null) ?
      `<tr> 
           <th style="text-align: justify-left; padding: 5px">Device Info</th> 
            <td style="text-align: justify-left; padding: 5px">  ${deviceName} </td> 
              </tr>`: ""}
          ${(osVersion !== undefined && osVersion !== null) ?
      `<tr> 
            <th style="text-align: justify-left; padding: 5px">Version of OS</th> 
             <td style="text-align: justify-left; padding: 5px"> ${deviceOS} ${osVersion}  </td> 
                 </tr>`: ""}
          ${(appVersion !== undefined && appVersion !== null) ?
      ` <tr> 
            <th style="text-align: justify-left; padding: 5px">App version installed</th> 
            <td style="text-align: justify-left; padding: 5px">  ${appVersion} </td> 
                </tr>` : " "}
          ${(internetSpeed !== undefined && internetSpeed !== null) ?
      ` <tr> 
                 <th style="text-align: justify-left; padding: 5px">Signal strength/ wifi strength</th> 
                   <td style="text-align: justify-left; padding: 5px"> ${internetSpeed} kbps </td> 
                      </tr>` : " "}
          ${(isLocationPermissionEnabled !== undefined && isLocationPermissionEnabled !== null) ?
      ` <tr> 
                <th style="text-align: justify-left; padding: 5px">Location permissions/ accelerometer permissions</th> 
                <td style="text-align: justify-left; padding: 5px"> ${isLocationPermissionEnabled}  </td> 
                  </tr>` : " "}
          ${(batteryLevel !== undefined && batteryLevel !== null) ?
      ` <tr> 
                  <th style="text-align: justify-left; padding: 5px">Battery level</th> 
                  <td style="text-align: justify-left; padding: 5px"> ${batteryLevel} % </td> 
                    </tr>` : " "}
          ${(carrierName !== undefined && carrierName !== null) ?
      ` <tr> 
                  <th style="text-align: justify-left; padding: 5px">Carrier name</th> 
                  <td style="text-align: justify-left; padding: 5px"> ${carrierName}  </td> 
                    </tr>` : " "}  
          ${(accelerometerPermission !== undefined && accelerometerPermission !== null) ?
      ` <tr> 
                   <th style="text-align: justify-left; padding: 5px">Accelerometer permission</th> 
                   <td style="text-align: justify-left; padding: 5px"> ${accelerometerPermission}  </td> 
                     </tr>` : " "}
                     
         ${(lowPowerMode !== undefined && lowPowerMode !== null) ?
     ` <tr> 
                 <th style="text-align: justify-left; padding: 5px">Low Power Mode</th> 
                 <td style="text-align: justify-left; padding: 5px"> ${lowPowerMode}  </td> 
                   </tr>` : " "}

         ${(wifiScanCount !== undefined && wifiScanCount !== null) ?
     ` <tr> 
                 <th style="text-align: justify-left; padding: 5px">Wifi Scan Count</th> 
                 <td style="text-align: justify-left; padding: 5px"> ${wifiScanCount}  </td> 
                   </tr>` : " "}
  </tbody>
</table>
        `,
        showInfo: item.showInfo,
        icon: item.icon,
      });

      marker.addListener('click', (event) => {
        if (onClick) onClick(item);
      });

      return marker;
    });
    
    markerArray.map((item) => {
      if (item.showInfo) {
        this.infoWindow.setContent(item.html);
        this.infoWindow.setOptions({ maxWidth:  280 });
        this.infoWindow.open(map, item);
        this.infoWindow.addListener('closeclick', () => {
          toggleInfoWindow(item);
        });
      }
      return null;
    })

    /* eslint-disable */
    var mcOptions = {
      gridSize: 40,
      maxZoom: 12,
      zoomOnClick: true,
      minimumClusterSize: 2,
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    };

    if(this.props.map){

      this.markerCluster = new MarkerClusterer(
        map,
        markerArray,
        mcOptions,
      );
    }

  //   window.onload = function () {

  //   var mapOptions = {
  //     gridSize: 40,
  //     maxZoom: 12,
  //     zoomOnClick: true,
  //     minimumClusterSize: 2,
  //     imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  //   };

  //   this.markerCluster = new MarkerClusterer(
  //       map,
  //       markerArray,
  //       mcOptions,
  //     );
  //   }
    /* eslint-enable */
  }

  render() {
    return null;
  }
}

MarkerClusterComponent.propTypes = {
  map: PropTypes.object,
  onClick: PropTypes.func,
  toggleInfoWindow: PropTypes.func,
  zIndex: PropTypes.number,
  selectedMarkerId: PropTypes.number,
};

export default MarkerClusterComponent;
