const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/vendor/list', label: 'Customers' },
];
const baseNavPlugin = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/vendor/plugin', label: 'Plugins' },
];
const dashboardNav = [
  { href: '/dashboard', label: 'Dashboard' },
];
const billingDetailNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/bill/list', label: 'Billing' },
];


export const NAV = {
  VendorEditFormPage: {
    title: 'Company Profile',
    parent: '/dashboard',
  },
  BasicSettingFormPage: {
    title: 'Basic Settings',
    parent: '/dashboard',
  },
  AdjustmentTypesListPage: {
    title: 'Adjustment Types',
    parent: '/dashboard',
  },
  VendorRoleListPage: {
    title: 'Role Settings',
    parent: '/dashboard',
  },
  DocumentTypesListPage: {
    title: 'Document Types',
    parent: '/dashboard',
  },
  EDocsListPage: {
    title: 'E-docs',
    parent: '/dashboard',
  },
  VendorRoleAddFormPage: {
    title: 'Add Role',
    breadcrumb: [
      { href: '/dashboard', label: 'Dashboard' },
      { href: '/vendor/role-setting', label: 'Role Settings' },
      { label: 'Add Role' },
    ],
    description: 'Add role to your company',
    parent: '/vendor/role-setting/',
  },
  VendorRoleEditFormPage: {
    title: 'Edit Role',
    breadcrumb: [
      { href: '/dashboard', label: 'Dashboard' },
      { href: '/vendor/role-setting', label: 'Role Settings' },
      { label: 'Edit Role' },
    ],
    description: 'View and manage role',
    parent: '/vendor/role-setting/',
  },
  NotificationSettingListPage: {
    title: 'Notification Settings',
    parent: '/dashboard',
  },
  SystemSettingListPage: {
    title: 'System Settings',
    parent: '/dashboard',
  },
  CompanyListPage: {
    title: 'Manage Branches',
    parent: '/dashboard',
  },
  DepartmentListPage: {
    title: 'Departments',
    parent: '/dashboard',
  },
  VendorSubCompanyAddFormPage: {
    title: 'Add Branch',
    parent: '/vendor/sub-company',
  },
  VendorSubCompanyEditFormPage: {
    title: 'Edit Company',
    parent: '/vendor/sub-company',
  },
  VendorSubCompanyUserFormPage: {
    title: 'Company User',
    parent: '/vendor/sub-company',
  },
  VendorListPage: {
    title: 'Customers',
    breadcrumb: [
      ...baseNav,
      { label: 'All' },
    ],
    description: 'View & manage all your Customer profiles',
  },
  IncompleteSignupListPage: {
    title: 'Customers',
    breadcrumb: [
      ...baseNav,
      { label: 'Incomplete SignUp' },
    ],
    description: 'View & manage incomplete Customer profiles',
  },
  PluginPage: {
    title: 'Plugins',
    parent: '/dashboard',
  },
  PluginSettingPage: {
    title: 'QuickBooks Settings',
    parent: '/vendor/plugin',
    description: '',
  },
  CommonPluginSettingsPage : {
    title: 'Plugin Settings',
    parent: '/vendor/plugin',
    description: '',
  },
  PluginSettingAdpPage: {
    title: 'ADP Settings',
    parent: '/vendor/plugin',
    description: '',
  },
  QBDesktopPage: {
    title: 'QuickBooks Desktop',
    breadcrumb: [
      ...baseNavPlugin,
      { label: 'QuickBooks Desktop' },
    ],
    parent: '/vendor/plugin',
    description: 'Please follow the instructions',
  },
  ADPDesktopPage: {
    title: 'ADP Connector',
    breadcrumb: [
      ...baseNavPlugin,
      { label: 'ADP Connector' },
    ],
    parent: '/vendor/plugin/settings/Quickbooks/sync',
    description: 'Please follow the instructions',
  },
  AccountFormPage: {
    title: 'Account Details',
    parent: '/vendor/list/all',
    breadcrumb: [
      ...baseNav,
      { label: 'Account Details' },
    ],
  },
  CompanyBillorPlanPage: {
    title: 'Company Plan',
    parent: '/dashboard',
    breadcrumb: [
      ...dashboardNav,
      { label: 'Company Plan' },
    ],
  },
  PaymentMethodPage: {
    title: 'Payment Methods',
    parent: '/dashboard',
    breadcrumb: [
      ...dashboardNav,
      { label: 'Payment Methods' },
    ],
  },
  VendorInvoicePage: {
    title: 'Company Invoice',
    parent: '/dashboard',
    breadcrumb: [
      ...dashboardNav,
      { label: 'Company Invoice' },
    ],
  },
  InvoiceDetailFormPage: {
    title: 'Invoice Detail',
    parent: '/vendor/invoice',
  },
  BillListPage: {
    title: 'Billings',
    breadcrumb: [
      ...dashboardNav,
      { label: 'Billings' },
    ],
  },
   BillFormPage: {
    title: 'Billing Details',
    parent: '/bill/list',
    breadcrumb: [
      ...billingDetailNav,
      { label: 'Billing Details' },
    ],
  },

};

export const DATE_FORMATS = [
  { value: '%a, %d %b %Y', label: 'ddd, DD MMM YYYY' },
  { value: '%m/%d/%Y', label: 'MM/DD/YYYY' },
  { value: '%d/%m/%Y', label: 'DD/MM/YYYY' },
  { value: '%Y/%m/%d', label: 'YYYY/MM/DD' },
  { value: '%Y/%d/%m', label: 'YYYY/DD/MM' },
  { value: '%B %d, %Y', label: 'MMMM DD, YYYY' },
  { value: '%b %d, %Y', label: 'MMM DD, YYYY' },
];

export const BOOLEAN_OPTIONS = [
  { value: 'True', label: 'Yes' },
  { value: 'False', label: 'No' },
];

export const US_TIMEZONES = [
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Pacific-New",
  "US/Samoa",
];

export const NOTIFICATION_STATUS_OPTIONS = [
  { value: true, label: 'ON' },
  { value: false, label: 'OFF' },
];

export const SYSTEM_HOVER_MESSAGE_OPTIONS = {
  ENABLE_DAILY_COMPLIANCE: 'Creates an acknowledgment pop up in the Worksana App at punch out.',
  DAILY_COMPLIANCE_MESSAGE: 'A customizable message that an employee will need to acknowledge at punch out.',
  MANUAL_ENTRY_BY_USER: 'Correction Request allow employees to send a message directly to the admin panel at punch out. Allow Edit of Activities grant the employees the ability to edit their own timecards at punch out. No Manual Entry Allowed does not give the employees either option.',
  FORCE_PUNCH_OUT_TIME: 'After this amount of time punched in, the employee will be auto-clocked out for the day.',
  EMPLOYEE_CAN_ADD_NOTE: 'Employee can add note.',
  MEAL_VIOLATION: 'Enable this feature to track meal violations.',
  FORCE_PUNCH_OUT_MESSAGE: 'The notification message that will be sent to employees when they have exceeded FORCE PUNCH OUT TIME.'
};

export const NOTIFICATION_HOVER_MESSAGE_OPTIONS = {
  START_MEAL: 'This notification will go out at the time you choose, and the notification can be customized.',
  CONTINUE: 'This notification will go out (x) time after the Work Shift- Notification is triggered. The message can be customized.',
  SHIFT_COMPLETED: 'This notification will go out at the time you choose. It is tied to Work Shift Time, which means meal periods will not be counted towards this value. The message can be customized.',
  GEO_FENCE_RESTRICTION: 'This notification will alert an employee after (x) minutes that they have left the geofence area'
};

export const EMPLOYEES_FILTER_OPTIONS = [
  { value: true, label: 'Associated' },
  { value: false, label: 'Unassociated' },
];

export const TIMECARDS_FILTER_OPTIONS = [
  { value: true, label: 'Successfully Updated in QuickBooks' },
  { value: false, label: 'Unmapped' },
  { value: 'in_progress', label: 'In Progress' },

];

export const ADP_TIMECARDS_FILTER_OPTIONS = [
  { value: true, label: 'Successfully Updated in ADP' },
  { value: false, label: 'Unmapped' },
  { value: 'in_progress', label: 'In Progress' },
];

export const YEARS = [
  { id: '2015', name: '2015' },
  { id: '2016', name: '2016' },
  { id: '2017', name: '2017' },
  { id: '2018', name: '2018' },
  { id: '2019', name: '2019' },
  { id: '2020', name: '2020' },
  { id: '2021', name: '2021' },
  { id: '2022', name: '2022' },
  { id: '2023', name: '2023' },
  { id: '2024', name: '2024' },
  { id: '2025', name: '2025' },
];
export const MONTHS = [
  { id: '', name: 'All' },
  { id: '1', name: 'January' },
  { id: '2', name: 'February' },
  { id: '3', name: 'March' },
  { id: '4', name: 'April' },
  { id: '5', name: 'May' },
  { id: '6', name: 'June' },
  { id: '7', name: 'July' },
  { id: '8', name: 'August' },
  { id: '9', name: 'September' },
  { id: '10', name: 'October' },
  { id: '11', name: 'November' },
  { id: '12', name: 'December' },
];

export const COMMERCIAL_STATUS_LIST = [
  { value: '', label: 'All' },
  { value: 'Trial Period', label: 'Trial Period' },
  { value: 'Payment Enabled', label: 'Payment Enabled' },
  { value: 'Suspended', label: 'Suspended' },
  { value: 'Closed', label: 'Closed' },
  { value: 'Cancellation in progress', label: 'Cancellation in progress' },
  { value: 'No billing', label: 'No billing' },
]

export const PAYMENT_METHOD_LIST = [
  { value: 'online', label: 'Online' },
  { value: 'offline', label: 'Offline' },
]

export const BILL_STATUS_LIST = [
  { id :'',name: 'All'},
  { id: 'ON GOING', name: 'Ongoing' },
  { id: 'INVOICE RAISED', name: 'Invoice Raised' },
  { id: 'PAYMENT RECEIVED', name: 'Payment Received' },
  { id: 'PAYMENT FAILED', name: 'Payment Failed' },

]


export const DISCOUNT_UNIT_OPTIONS = [
  { value: 'Amount', label: 'Amount ($)' },
  { value: 'Percentage', label: 'Percentage (%)' },
];

export const NOTIFICATION_TYPE_LIST = [
  { id :'MEAL_START',name: 'MEAL_START'},
  { id: 'MEAL_END', name: 'MEAL_END' },
  { id: 'MEAL_FORCE_END', name: 'MEAL_FORCE_END' },
]

export const EMPLOYEES_FILTER_TYPE = [
  { value: '1', label: 'Newest Added' },
  { value: '2', label: 'Sort in Ascending Order' },
];

export const EMPLOYEES_STATUS_OPTIONS = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Open' },
  { value: '3', label: 'Completed' },
  { value: '2', label: 'Deleted' },
];

export const STATUS_OPTIONS = [
  { value: "", label: "All" },
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

export default {
  NAV, DATE_FORMATS, BOOLEAN_OPTIONS, US_TIMEZONES,
  EMPLOYEES_FILTER_OPTIONS, TIMECARDS_FILTER_OPTIONS,
  NOTIFICATION_STATUS_OPTIONS, SYSTEM_HOVER_MESSAGE_OPTIONS,
  NOTIFICATION_HOVER_MESSAGE_OPTIONS, DISCOUNT_UNIT_OPTIONS,
  ADP_TIMECARDS_FILTER_OPTIONS, EMPLOYEES_FILTER_TYPE, EMPLOYEES_STATUS_OPTIONS };
