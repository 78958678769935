import React from 'react';

import PropTypes from 'prop-types';

import { GoogleApiWrapper } from 'google-maps-react';
import connect from 'react-redux/lib/connect/connect';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Switch from "react-switch";
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import SectionLoaderAtom from 'atoms/SectionLoader';
//   MapComponent from './Map';
import MapComponent from 'components/common/googleMap/Map';
import MarkerClusterComponent from 'components/common/googleMap/MarkerCluster';
import PolygonComponent from 'components/common/googleMap/Polygon';
import MarkerComponent from 'components/common/googleMap/Marker';
import RectangleComponent from 'components/common/googleMap/Rectangle';
import InfoWindowComponent from 'components/common/googleMap/InfoWindow';
import CircleComponent from 'components/common/googleMap/Circle';

import styles from './styles.module.scss';
import BeaconIcon from './beacon.png';
import QuestionableBeacon from "./questionableBeacon.png";
import MarkerIcon from './marker.png';
import closeimage from './Close-Cross.png';

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;

class SessionMapModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: { lat: -25.363882, lng: 131.044922 },
      zoom: 9,
      showInfoWindow: false,
      activeShape: {},
      shapeEvent: {},
      data: {},
      addressArray: [],
      selectedMarkerId: null,
      currentIndex: 0,
      isChecked: false,
      markers: [],
      geomap: [],
      isQuestionableBeackon: false,
    };
    this.currentIndex = -1;
    this.showBreachesToggle = false;
    this.nextMarker = this.nextMarker.bind(this);
    this.prevMarker = this.prevMarker.bind(this);
    this.onZoomChanged = this.onZoomChanged.bind(this);
    this.onShapeClick = this.onShapeClick.bind(this);
    this.onGeoFenceClick = this.onGeoFenceClick.bind(this);
    this.highlightMarker = this.highlightMarker.bind(this);
    this.changeIndex = this.changeIndex.bind(this);
    this.questionableBeackon = this.questionableBeackon.bind(this);
    this.onPressEnter = this.onPressEnter.bind(this);
    this.moveCaretAtEnd = this.moveCaretAtEnd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    if((this.props.sessionInfo.locations && this.props.sessionInfo.locations.length ) !==
      (prevProps.sessionInfo.locations && prevProps.sessionInfo.locations.length )){
      if(this.props.sessionInfo && this.props.sessionInfo.locations && this.props.sessionInfo.locations.length && this.props.sessionInfo.punch_in_type && this.props.sessionInfo.punch_in_type.app_user){
        this.setState(({currentIndex}) => ({ currentIndex: this.props.sessionInfo.locations.length }));
      }
      else if(this.props.sessionInfo && this.props.sessionInfo.locations && this.props.sessionInfo.locations.length && !this.props.sessionInfo.punch_in_type){
        this.setState(({currentIndex}) => ({ currentIndex: this.props.sessionInfo.locations.length }));
      } 
    }
    if((prevState.isChecked != this.state.isChecked) || (JSON.stringify(prevProps.sessionInfo.locations) !== JSON.stringify(this.props.sessionInfo.locations))) {
        this.loadData();
      }

  }

  componentWillMount(){
    const { accessSidemenu } = this.props;
    this.showBreachesToggle =  accessSidemenu && accessSidemenu.length && accessSidemenu.find((item) => item.name === 'job'); 
  }


  componentDidMount(){
    this.loadData();
  }

  componentWillUnmount(){
    this.props.resetModalData();
  }

  onShapeClick(data) {
    this.props.onMarkerClick(data, 'true');
  }

  onGeoFenceClick(data, shape, event) {
    this.props.toggleInfoWindow();
    this.setState({
      activeShape: shape,
      showInfoWindow: true,
      shapeEvent: event,
      data,
    });
  }

  onZoomChanged(center,zoom) {
    this.setState({ center });
  }

  onPressEnter(event){
    if(event.which === 13){
      this.node.blur();
    }
  }

  questionableBeackon(event){
    this.setState({isQuestionableBeackon:event.target.checked})
  }

  nextMarker() {
    this.currentIndex += 1;
    const currentMarker = this.state.markers[
      this.currentIndex];
    //this.state.markers.push(currentMarker);
    this.setState({
      center: {
        lat: currentMarker && currentMarker.location && currentMarker.location.coordinates && currentMarker.location.coordinates[1],
        lng: currentMarker && currentMarker.location && currentMarker.location.coordinates && currentMarker.location.coordinates[0]
      },
    });
    if (currentMarker && currentMarker.id) {
      this.setState({ selectedMarkerId: currentMarker.id });
    }
    this.setState({ currentIndex: this.currentIndex });
    this.props.onMarkerClick(currentMarker, 'true');
  }

  prevMarker() {
    this.currentIndex -= 1;
    const currentMarker = this.state.markers[
      this.currentIndex];
    //this.state.markers.pop();
    this.setState({
      center: {
        lat: currentMarker && currentMarker.location && currentMarker.location.coordinates && currentMarker.location.coordinates[1],
        lng: currentMarker && currentMarker.location && currentMarker.location.coordinates && currentMarker.location.coordinates[0]
      },
      //markers: filteredMarkers,
    });
    if(currentMarker && currentMarker.id) {
      this.setState({ selectedMarkerId: currentMarker.id });
    }
    this.setState({ currentIndex: this.currentIndex});
    this.props.onMarkerClick(currentMarker, 'true');
  }

  highlightMarker(marker, index){
    if(marker.id >= this.state.markers[this.state.markers.length -1].id){
      const newarr = this.state.markers.filter((item) => item.id <= marker.id);
      this.state.markers = newarr;
      this.currentIndex= newarr.length - 1;
      this.setState({ currentIndex: newarr.length - 1});
    }
    else if(this.state.currentIndex === ''){
      this.currentIndex = marker.id;
      this.setState({ currentIndex: marker.id });
    }
    this.setState({ selectedMarkerId:  marker.id});
    if(!marker.showInfo){
      this.props.toggleInfoWindow(marker);
    }
  }

  changeIndex(event){
    const { sessionInfo } = this.props;
    const val = event.target.value;
    const res = val.match('^[0-9]+$');
    if(val === ''){
      return this.setState({ currentIndex: ''});
    }
    else if(val === '0' || res === null){
      return toast.error('Choose a valid marker');
    }
    this.setState({ selectedMarkerId: val, currentIndex: (+val) - 1});
    let filteredMarkers = this.state.markers;
    const newarr = filteredMarkers.filter((item, index) => index == (+val) - 1);
    if(!newarr || !newarr.length) return toast.error('Choose a valid marker');
    filteredMarkers = newarr;
    this.currentIndex = (+val) - 1;
    
    const isInfoOpen = filteredMarkers.filter((item, index) => index === (+val) - 1).length ?
      filteredMarkers.filter((item, index) => index === (+val) - 1)[0].showInfo : false;

    const marker = newarr && newarr.length && newarr[0];
    if(marker && !marker.address){
      this.props.getLocationAddress(marker);
    }
    if(!isInfoOpen){
      this.props.toggleInfoWindow(marker);
    }
    return true;
  }

  moveCaretAtEnd(e) {
    const tempValue = e.target.value;
    e.target.value = '';
    e.target.value = tempValue;
  }

  handleChange( checked , type){
     this.currentIndex = -1;

    if(checked) this.setState({isChecked: type})
    else{
      this.setState({isChecked: checked})

    }
   
  }

  loadData(){
    const { sessionInfo, google} = this.props;
    const {isChecked} = this.state;
    const locationArr = sessionInfo && sessionInfo.locations ? 
      sessionInfo.locations.filter((i) => Object.keys(i.location).length) : [];

    let filteredMarkers = [];

    if (Object.keys(sessionInfo && sessionInfo).length > 0 && (locationArr.length)) {
      
      sessionInfo.locations.forEach((item, index) => {
        if(isChecked == 'mileageMode' && (item.activity_name === 'MILEAGE START' || item.activity_name === 'MILEAGE END' || item.activity_name === 'Mileage Start' || item.activity_name === 'Mileage End')) {
          filteredMarkers.push(item);
        }
        else if(isChecked == 'jobMode' && (item.activity_name === 'Job Start' || item.activity_name === 'Job End')) {
          filteredMarkers.push(item);
        }else if(isChecked == 'breachMode' && (item.activity_name === 'Start of Geofence Breach' || item.activity_name === 'End of Geofence Breach' || item.activity_name === 'Left' || item.activity_name === 'Entered')) {
          filteredMarkers.push(item);
        }else if(!isChecked && !(item.activity_name === 'MILEAGE START' || item.activity_name === 'MILEAGE END' || item.activity_name === 'Job Start' || item.activity_name === 'Job End' || item.activity_name === 'Mileage Start' || item.activity_name === 'Mileage End' || item.activity_name === 'Start of Geofence Breach' || item.activity_name === 'End of Geofence Breach' || item.activity_name === 'Left' || item.activity_name === 'Entered')) {
          filteredMarkers.push(item);
        }
      })
      filteredMarkers = filteredMarkers.map((item) => {
        let iconObj;
        let markerObj = {};

        if(item.activity_name === 'Beacon' || item.activity_name === 'MILEAGE END' || item.activity_name === 'Job End' || item.activity_name === 'Start of Geofence Breach' || item.activity_name === 'Left'){
          iconObj = {
            url: BeaconIcon,
            scaledSize: google && new google.maps.Size(30, 30),
            labelOrigin: google && new google.maps.Point(15,12),
            origin: google && new google.maps.Point(0, 0),
          };
        }else {
          iconObj = {
            url: MarkerIcon,
            scaledSize: google && new google.maps.Size(30, 30),
            labelOrigin: google && new google.maps.Point(15,12),
            origin: google && new google.maps.Point(0, 0),
          };
        }
        markerObj = {...item, icon: iconObj};
        return markerObj;
      });
    }

    if (this.currentIndex === -1) {
      this.currentIndex = filteredMarkers ? filteredMarkers.length - 1 : -1;
      this.setState({currentIndex : this.currentIndex});
    }
    const lastMarker = filteredMarkers && filteredMarkers[this.currentIndex];
    if (this.currentIndex === filteredMarkers[filteredMarkers.length - 1] && lastMarker && !lastMarker.address) {
      this.props.onMarkerClick(lastMarker, 'true');
    }
    this.setState({markers: filteredMarkers})

    let val = sessionInfo && sessionInfo.locations && sessionInfo.locations.length ?
    sessionInfo.locations.filter((item) =>
      item.activity_code == "GEOFENCE_BREACH"
    ) : [];

    let geomap = [];

    val && val.length && val.map(item => {
      if(item.geo_fences){
         geomap.push(item.geo_fences)
      }   
    })
    
    this.setState({geomap: geomap.flat()});
  }



  render() {
    const { isOpen, sessionInfo, toggle, google, selectedEmployee,
      loaded, isLocationLoading,
      toggleInfoWindow,  geoFenceDetail, permission , userExtraData} = this.props;
    const { activeShape, showInfoWindow, shapeEvent, data, selectedMarkerId, isChecked , geomap, isQuestionableBeackon} = this.state;


    const markersDetail = this.state.markers.map(item => ({...item, shape_type: item.location.type,
      lat_long: { coordinates: item.location.coordinates }}));

    let sesssionInfoMappingGeofenceDetail = [];

    const locationArr = sessionInfo && sessionInfo.locations ? 
      sessionInfo.locations.filter((i) => Object.keys(i.location).length) : [];
    
    if(sessionInfo && sessionInfo.punch_in_type && !locationArr.length){
      sesssionInfoMappingGeofenceDetail = [];
    }
    
    else{  
      sesssionInfoMappingGeofenceDetail = markersDetail;
      markersDetail.map((item) => {
        geoFenceDetail && geoFenceDetail.results && geoFenceDetail.results.map((geofenceItem) => {
          sesssionInfoMappingGeofenceDetail.push(geofenceItem);
          return true;
        });
        return true;
      });
    } 
    
    let activityDisplayList = isChecked ? this.state.markers.length && this.state.markers : this.state.markers && this.state.markers.map((item, index) => {
      if(item.activity_code!== 'BEACON' && item.activity_code!== 'CONTINUE' && item.activity_code !== 'MILEAGE START' && item.activity_code !== 'MILEAGE END' && item.activity_code !== 'Mileage Start' && item.activity_code !== 'Mileage End')
      {
        const editedItem = {...item, newId: index + 1};
        return editedItem;
        
      }
      else
        return null;
    });

    activityDisplayList = activityDisplayList && activityDisplayList.length && activityDisplayList.filter((item)=> item !== null);

    let kioskDisplayList={};
    if(sessionInfo && sessionInfo.punch_in_type && !locationArr.length){
      kioskDisplayList = sessionInfo.locations && sessionInfo.locations.filter((item) => item.activity_code!== 'BEACON' && item.activity_code!== 'CONTINUE');
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg" className="session-modal form-modal" backdrop="static">
        <ModalHeader>
          { Object.keys(sessionInfo).length > 0
            ? <div>
              View Session Track for {sessionInfo.employee && sessionInfo.employee.user && sessionInfo.employee.user.first_name ? sessionInfo.employee.user.first_name : selectedEmployee && selectedEmployee.first_name}&nbsp;
              {sessionInfo.employee && sessionInfo.employee.user && sessionInfo.employee.user.last_name ? sessionInfo.employee && sessionInfo.employee.user && sessionInfo.employee.user.last_name : selectedEmployee && selectedEmployee.last_name }
              {sessionInfo && sessionInfo.punch_in_type && sessionInfo.punch_in_type.kiosk ? "( Kiosk Employee )" : ""}
              {sessionInfo && sessionInfo.punch_in_type && sessionInfo.punch_in_type.manual_entry ? "( Manual Entry )" : ""}
              <img src={closeimage} className={styles['close-icon']} height="14px" width="14px" alt="Close" onClick={() => toggle()} />
            </div>
            : null
          }
        </ModalHeader>
        <ModalBody className="ml-3 mr-0">
          <Row> 
            <Col md={sessionInfo && sessionInfo.locations && sessionInfo.locations.length ? "8":"12"}>
              {loaded && !isLocationLoading
                ?<section style={{ height: '55vh', position: 'relative' }}>
                  { !permission &&
                    <div className={styles['overlay-tracking']}>
                      <div className="text-center">
                        You do not have permission to view employee tracking.
                        <br/>Please contact your administrator.
                      </div>
                    </div>
                  }
                  <MapComponent
                    google={google}
                    locations={locationArr.length ? sesssionInfoMappingGeofenceDetail: []}
                    getAddressForMarker = {this.props.getAddressForMarker}
                    setLocation={this.currentIndex >= 0
                      ? {
                        position: sessionInfo && sessionInfo.locations && sessionInfo.locations.length && sessionInfo.locations[this.currentIndex].position,
                        zoom: 13,
                      }
                      : null
                    }
                  >
                    {geoFenceDetail.results && geoFenceDetail.results.map((item, index) => {
                      if (item.shape_type === 'Point') {
                        return <MarkerComponent
                          key={item.id}
                          data={item}
                          getAddressForMarker = {this.props.getAddressForMarker}
                          position={{
                            lat: item.lat_long.coordinates && item.lat_long.coordinates[1],
                            lng: item.lat_long.coordinates && item.lat_long.coordinates[0],
                          }}
                          onClick={this.onGeoFenceClick}
                          // zIndex="99999"
                        />;
                      }
                      else if (item.shape_type === 'Polygon') {
                        return <PolygonComponent
                          key={item.id}
                          data={item}
                          getAddressForMarker = {this.props.getAddressForMarker}
                          paths={
                            item.lat_long &&
                              item.lat_long.coordinates[0].map(latLng => ({
                                lat: latLng[1],
                                lng: latLng[0],
                              }))
                          }
                          fillColor={item.color}
                          onClick={this.onGeoFenceClick}
                        />;
                      }
                      else if (item.shape_type === 'Rectangle') {
                        return <RectangleComponent
                          key={item.id}
                          getAddressForMarker = {this.props.getAddressForMarker}
                          data={item}
                          bounds={item.lat_long.coordinates}
                          fillColor={item.color}
                          onClick={this.onGeoFenceClick}
                        />;
                      }
                      else if (item.shape_type === 'Circle') {
                        return <CircleComponent
                          key={item.id}
                          data={item}
                          getAddressForMarker = {this.props.getAddressForMarker}
                          radius={item.radius}
                          center={{
                            lat: item.lat_long && item.lat_long.coordinates[1],
                            lng: item.lat_long && item.lat_long.coordinates[0],
                          }}
                          fillColor={item.color}
                          onClick={this.onGeoFenceClick}
                        />;
                      }
                      return null;
                    })}
                    {geomap  && geomap.length && geomap.map((item, index) => {
                      if (item.shape_type === 'Point') {
                        return <MarkerComponent
                          key={item.id}
                          data={item}
                          getAddressForMarker = {this.props.getAddressForMarker}
                          position={{
                            lat: item.lat_long.coordinates && item.lat_long.coordinates[1],
                            lng: item.lat_long.coordinates && item.lat_long.coordinates[0],
                          }}
                          onClick={this.onGeoFenceClick}
                          // zIndex="99999"
                        />;
                      }
                      else if (item.shape_type === 'Polygon') {
                        return <PolygonComponent
                          key={item.id}
                          data={item}
                          getAddressForMarker = {this.props.getAddressForMarker}
                          paths={
                            item.lat_long &&
                              item.lat_long.coordinates[0].map(latLng => ({
                                lat: latLng[1],
                                lng: latLng[0],
                              }))
                          }
                          fillColor={item.color}
                          onClick={this.onGeoFenceClick}
                        />;
                      }
                      else if (item.shape_type === 'Rectangle') {
                        return <RectangleComponent
                          key={item.id}
                          getAddressForMarker = {this.props.getAddressForMarker}
                          data={item}
                          bounds={item.lat_long.coordinates}
                          fillColor={item.color}
                          onClick={this.onGeoFenceClick}
                        />;
                      }
                      else if (item.shape_type === 'Circle') {
                        return <CircleComponent
                          key={item.id}
                          data={item}
                          getAddressForMarker = {this.props.getAddressForMarker}
                          radius={item.radius}
                          center={{
                            lat: item.lat_long && item.lat_long.coordinates[1],
                            lng: item.lat_long && item.lat_long.coordinates[0],
                          }}
                          fillColor={item.color}
                          onClick={this.onGeoFenceClick}
                        />;
                      }
                      return null;
                    })}
                    {locationArr.length && !isLocationLoading && loaded &&
                      <MarkerClusterComponent
                        data={this.state.markers}
                        onClick={this.onShapeClick}
                        toggleInfoWindow={toggleInfoWindow}
                        selectedMarkerId={parseInt(selectedMarkerId, 10)}
                        checked = {isChecked ? true : false}
                      />
                    }   
                    <InfoWindowComponent
                      shape={activeShape}
                      visible={showInfoWindow}
                      shapeEvent={shapeEvent}
                      data={data}
                      style={{"margin-bottom": "200px" }}
                    >
                      <div>
                        {data.name && <div>GeoFence: {data.name}</div>}
                        {data.employee_name && <div>Employee: {data.employee_name}</div>}
                        {data.datetime && <div>Date: {data.datetime}</div>}
                        {data.activity_name && <div>Activity: {data.activity_name === 'Start of Geofence Breach' ? 'Left' : data.activity_name === 'End of Geofence Breach' ? 'Entered' : data.activity_name}</div>}
                        {data && data.address && <div>Address: {data ? data.address : null}</div>}
                        {data.create_date_display && <div>Date Created: {data.create_date_display}</div>}
                      </div>
                    </InfoWindowComponent>
                  </MapComponent>
                  {!(locationArr.length ) &&
                    !isLocationLoading && permission
                    ?<div className={styles['no-location']}>
                      No location is recorded for this date !!
                    </div> : null } 
                  {(sessionInfo && sessionInfo.punch_in_type && !sessionInfo.punch_in_type.app_user && sessionInfo.punch_in_type.kiosk) &&
                    !isLocationLoading && permission && !locationArr.length
                    ?<div className={styles['no-location']}>
                      No location is recorded for this date as this is a kiosk entry !!
                    </div> : null }
                  {/*(sessionInfo && sessionInfo.punch_in_type && !sessionInfo.punch_in_type.app_user && sessionInfo.punch_in_type.manual_entry) &&
                    !isLocationLoading && permission
                    ?<div className={styles['no-location']}>
                      No location is recorded for this date as this is a manual entry !!
                    </div> : null */}
                  { locationArr.length && loaded && !isLocationLoading ?
                    <div>
                    <Col className="float-left px-0 mt-3" md={8}>
                    {userExtraData.company_permissions.mileage && ((this.props.session && this.props.session === 'historic') || !this.props.session) ? 
                      <Row className="float-left mt-2 w-100">
                        <Col md={6} className="float-left"><strong>Show Mileage Mode</strong></Col>
                        <Col md={2} className={`${styles['switch-vertical']}`}>
                          <Switch 
                            style={{marginTop: '20px'}}
                            onChange={checked => this.handleChange(checked, 'mileageMode')}
                            checked={isChecked == 'mileageMode'}
                            uncheckedIcon={false}
                            checkedIcon={true}
                            height={18}
                            width={36}
                            />
                        </Col>
                        {isChecked == 'mileageMode' &&
                          <span className="d-inline">
                            Total Miles: {sessionInfo && sessionInfo.mileage ? sessionInfo.mileage.toFixed(1) : sessionInfo.total_mileage ? sessionInfo.total_mileage.toFixed(1) : '0.0'}
                          </span>
                        }
                      </Row>: null }
                    {this.showBreachesToggle ?
                      <Row className="float-left mt-2 w-100">
                        <Col md={6}><strong>Geofence Breaches</strong></Col>
                        <Col md={2} className={`${styles['switch-vertical']}`}>
                          <Switch 
                            style={{marginTop: '20px'}}
                            onChange={checked => this.handleChange(checked, 'breachMode')}
                            checked={isChecked == 'breachMode'}
                            uncheckedIcon={false}
                            checkedIcon={true}
                            height={18}
                            width={36}
                            />
                        </Col>
                      </Row> 
                      :
                      null
                    }

                    { (this.props.session && this.props.session === 'historic') || !this.props.session ?
                      <Row className="float-left mt-2 w-100">
                        <Col md={6}><strong>Job Mode</strong></Col>
                        <Col md={2} className={`${styles['switch-vertical']}`}>
                          <Switch 
                            style={{marginTop: '20px'}}
                            onChange={checked => this.handleChange(checked, 'jobMode')}
                            checked={isChecked == 'jobMode'}
                            uncheckedIcon={false}
                            checkedIcon={true}
                            height={18}
                            width={36}
                            />
                        </Col>
                      </Row> : null
                    }
                     <Row className="float-left mt-2 w-100">
                        <Col md={6}><strong>Questionable Beacon</strong></Col>
                        <Col md={2} className={`${styles['switch-vertical']}`}>
                        <input type="checkbox" disabled={isChecked} onChange={(event) => this.questionableBeackon(event)} />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="float-right mt-1 mt-3" md={4}>
                       { this.currentIndex !== -1 ?
                        <div className="float-right">
                          <Button
                            className={styles['pagination-btn']}
                            disabled={this.currentIndex === 0}
                            onClick={this.prevMarker}
                            color="default"
                          >
                            &#8249;
                          </Button>
                          {/* eslint-disable */
                          <input
                            className={styles['pagination-btn__current']}
                            onChange={(val) => this.changeIndex(val)}
                            value={this.state.currentIndex === '' ? ''  : this.state.currentIndex + 1}
                            onKeyPress={(event) => this.onPressEnter(event)}
                            ref={node => {this.node = node}}
                            autoFocus="autofocus"
                            onFocus={this.moveCaretAtEnd}
                            data-lpignore={true}
                            autoComplete="off"
                          />
                          /* eslint-enable */}
                          <Button
                            className={styles['pagination-btn']}
                            disabled={this.currentIndex === this.state.markers.length - 1}
                            onClick={this.nextMarker}
                            color="default"
                          >
                            &#8250;
                          </Button>
                        </div> : null }
                    </Col>
                    </div> : null }
                </section>
                : <section style={{ height: '55vh' }}><SectionLoaderAtom active /></section>
              }
            </Col>
            {locationArr.length && sessionInfo.punch_in_type && this.currentIndex !== -1 ?
              <Col md={4} className="pl-1 pr-1">
                { !isLocationLoading ?
                  <div className={`${styles['modal-data-wrap']} pr-3`}>
                    {activityDisplayList && activityDisplayList.length ? activityDisplayList.map((item, index) => {
                      if(isChecked) {
                      return(<Row>
                        <Col className={item.activity_name === 'MILEAGE START' || item.activity_name === 'Mileage Start' ? `${styles['modal-data__mileage-tracking']} pr-0 mt-1` : `${styles['modal-data']} pr-0 mt-1`} md={2} onClick={() => this.highlightMarker(item)}>
                          <span>
                            <img src={item.activity_name ==='MILEAGE START' || item.activity_name === 'Mileage Start' || item.activity_name === 'Job Start' || item.activity_name === 'End of Geofence Breach' || item.activity_name === 'Entered' ? MarkerIcon : BeaconIcon} alt= ''  height="30" width="30" />
                            <span className={styles['marker-icon-label']}>{isChecked ? index + 1 : item.newId + 1}</span>
                          </span>
                          {item.activity_name === 'MILEAGE START' || item.activity_name === 'Mileage Start' && activityDisplayList[index + 1].mileage && activityDisplayList[index + 1].mileage.toFixed(1) !== 0.0  ?
                                <div className={styles['miles-custom']} title={activityDisplayList[index + 1].mileage.toFixed(1)}>
                                  {activityDisplayList[index + 1].mileage.toFixed(1)} miles
                                </div> : null
                              }
                        </Col>
                        <Col className={`${styles['modal-data']} pl-1 pr-1 pb-2`} md={10} onClick={() => this.highlightMarker(item)}>
                          <div className={styles['table-data']}>
                            {item.activity_name ==='Start of Geofence Breach' ? 'Left :' : item.activity_name === 'End of Geofence Breach' ? 'Entered :' : `${item.activity_name} `}{ item.geofence_name ? `${item.geofence_name} :` :  ': '}{item.job_name ? `${item.job_name} - ` :  null}
                            {!item.geofence_name ?
                              <div>
                                <div title={item.address} className={`${styles['modal-address']}`}>
                                  {(item.address && item.address.length && item.address.length < 70) ? item.address : item.address && item.address.substring(0, 67).concat("...")}
                                </div>
                              </div>
                              :
                              null
                            }
                            <div style={item.activity_name === 'MILEAGE END' ? {borderBottom: '2px solid #efefef'} : null}>{item.datetime}</div>
                          </div>
                        </Col>
                      </Row>
                    )}else if(!(item.activity_name === 'MILEAGE START' || item.activity_name === 'MILEAGE END' || item.activity_name === 'Job Start' || item.activity_name === 'Job End' || item.activity_name === ' Mileage Start' || item.activity_name === 'Mileage End' || item.activity_name === 'Start of Geofence Breach' || item.activity_name ==='End of Geofence Breach' || item.activity_name === 'Left' || item.activity_name === 'Entered' )) {
                      const markerIconSrc = isQuestionableBeackon===true && (item?.extra_data?.lowPowerMode === true || item?.extra_data?.wifiScanCount <= 3) ? QuestionableBeacon : MarkerIcon;
                        return(<Row>
                        <Col className={`${styles['modal-data']} pr-0 mt-1`} md={2} onClick={() => this.highlightMarker(item)}>
                          <span>
                            <img src={markerIconSrc} alt= ''  height="30" width="30" />
                            <span className={styles['marker-icon-label']}>{isChecked  ? index + 1 : item.newId}</span>
                          </span>
                        </Col>
                        <Col className={`${styles['modal-data']} pl-1 pr-1 pb-2`} md={10} onClick={() => this.highlightMarker(item)}>
                          <div className={styles['table-data']}>
                            {item.activity_name ==='Start of Geofence Breach' ? 'Left :' : item.activity_name === 'End of Geofence Breach' ? 'Entered :' :`${item.activity_name} : `}
                            <div title={item.address} className={`${styles['modal-address']} `}>
                              {(item.address && item.address.length && item.address.length < 70) ? item.address : item.address && item.address.substring(0, 67).concat("...")}
                            </div>
                            <span>{item.datetime}</span>
                            
                          </div>
                        </Col>
                      </Row>
                    )}
                      else
                        return false;
                    }): ( isChecked ?  <span>There are no records to show</span> : null )}
                  </div> : null}
              </Col> : ( isChecked ?  <span>
              There are no records to show
              </span> : null )
              }
            {!(locationArr.length) && sessionInfo.punch_in_type ?
              <Col md={4} className="pl-1 pr-1">
                { isLocationLoading ?
                  null
                  : <div className={`${styles['modal-data-wrap']} pr-3`}>
                    {kioskDisplayList && kioskDisplayList.length ? kioskDisplayList.map((item) => (
                      <Row>
                        <Col className={styles['modal-data']} md={2}>
                          {item.activity_name==="Shift Start" && 
                            <span className={styles['timeline-badge__punchIn']}>PI</span>
                          }
                          {item.activity_name==="Shift End" && 
                            <span className={styles['timeline-badge__punchOut']}>PO</span>
                          }
                          {item.activity_name==="Meal Period Start" &&
                            <span className={styles['timeline-badge__mealPeriod']}>MP</span>
                          }
                          {item.activity_name==="Meal Period End" &&
                            <span className={styles['timeline-badge__mealPeriod']}>MP</span>
                          }
                        </Col>
                        <Col className={`${styles['modal-data']} pl-1 pr-1 pb-2`} md={10}>
                          <div className={styles['table-data-kiosk']}>
                            {`${item.activity_name} :`}
                            <span>{item.datetime}</span>
                          </div>
                        </Col>
                      </Row>
                    )) : null}
                  </div>}
              </Col> : null}  
            {locationArr.length && !sessionInfo.punch_in_type ?
              <Col md={4} className="pl-1 pr-1">
                { isLocationLoading ?
                  null
                  : <div className={`${styles['modal-data-wrap']} pr-3`}>
                    {activityDisplayList && activityDisplayList.length ? activityDisplayList.map((item) => (
                      <Row>
                        <Col className={styles['modal-data']} md={2} onClick={() => this.highlightMarker(item, item.id)}>
                          <span>
                            <img src={MarkerIcon} alt= ''  height="30" width="30" />
                            <span className={styles['marker-icon-label']}>{item.newId ? item.newId : item.id}</span>
                          </span>
                        </Col>
                        <Col className={`${styles['modal-data']} pl-1 pr-1 pb-2`} md={10} onClick={() => this.highlightMarker(item, item.id)}>
                          <div className={styles['table-data']}>
                            {`${item.activity_name} :`}
                            <div title={item.address} className={`${styles['modal-address']}`}>
                              {(item.address && item.address.length && item.address.length < 70) ? item.address : item.address && item.address.substring(0, 67).concat("...")}
                            </div>
                            <span>{item.datetime}</span>
                            
                          </div>
                        </Col>
                      </Row>
                    )) : null}
                  </div>}
              </Col> : null}             
          </Row>
        </ModalBody>
      </Modal>
    );
  }
};

SessionMapModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  sessionInfo: PropTypes.object.isRequired,
  onMarkerClick: PropTypes.func,
  onMarkerClose: PropTypes.func,
  toggleInfoWindow: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  google: PropTypes.object,
  selectedMarkerAddress: PropTypes.string,
  geoFenceDetail: PropTypes.object.isRequired,
  isModalLoading: PropTypes.bool.isRequired,
  isLocationLoading: PropTypes.bool.isRequired,
  resetModalData: PropTypes.func.isRequired,
  getLocationAddress: PropTypes.func,
  selectedEmployee: PropTypes.string,
  permission: PropTypes.bool,
};

const withConnect = connect(
)(SessionMapModalComponent);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION,
})(withConnect);


