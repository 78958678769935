import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';

import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as RouteDucks from 'ducks/routes';
import * as TeamDucks from 'ducks/vendors/team';
import * as UserDucks from 'ducks/accounts/user';

import FilterDropdownComponent from 'components/common/FilterDropdown';
import FilterFormComponent from 'components/common/FilterForm';
import ActivityTrackFilterFormComponent from 'components/activities/activityTrackList/Filter';

import { FILTER_OPTIONS, PUNCH_IN_TYPES, PUNCH_IN_TYPES_CURRENT , PUNCH_IN_OPTIONS } from 'constants/activities';

import ListViewIcon from './list-view.png';
import MapViewIcon from './map-view.png';

/**
 * ActivityTrackListPage -> ActivityTrackFilterSection
 *
 * Components:
 *    - {@link FilterDropdownComponent}
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    None
 */
class ActivityTrackFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMapView = this.toggleMapView.bind(this);
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
  }

  componentWillMount(){
    Promise.all([
      this.props.DepartmentDucks.getAllDepartments({ paginate: false}),
      this.props.TeamDucks.getAllTeams({ paginate: false }),
    ]);
  }

  onDepartmentSelect(id) {
    const { location: { pathname, query }} = this.props
    const { page, ...rest } = query;
    const { router: { history } } = this.context;

    history.push({
      pathname,
      search: queryString.stringify({ ...rest, department_id: id, team_id: null }),
    });
    this.props.TeamDucks.getAllTeams({ department_id: id, paginate: false });
  }

  toggleMapView() {
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;

    if (query.view) {
      const { view, ...rest } = query;
      history.push({ pathname, search: queryString.stringify({ ...rest }) });
    } else {
      history.push({
        pathname, search: queryString.stringify({ ...query, view: 'map' }),
      });
    }
  }

  render() {
    const { departmentAll, location, session, teamAll, isOrganizationVisible, userExtraData, dateFormat,toggleTable ,absentEmployee , view} = this.props;
    const { query } = location;
    let departmentOptions = [];
    let teamOptions = [];

    if (departmentAll && departmentAll.length) {
      departmentOptions = departmentAll && departmentAll.map(item => ({
        value: item.id, label: item.name,
      }));
      // if (!userExtraData.department){
        departmentOptions.splice(0, 0, { value: "", label: "All Departments" })
      // }
      teamOptions = teamAll && teamAll.map(item => ({
        value: item.id, label: item.name,
      }));
      // if (!userExtraData.team) {
        teamOptions.splice(0, 0, { value: "", label: "All Teams" })
      // }
    }
    const formattedDates = {};
    if (query.start_date) formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date) formattedDates.end_date = moment(query.end_date).toISOString();
    console.log({query})
    return (
      <div>
        <Row className="filter ml-3 mr-4">
          <Col md={6} className="px-0">
            { session === 'current' &&
              <section className="d-flex">
                {departmentAll.length > 0 && isOrganizationVisible &&
                  <span id ="department" className="department-index">
                    <FilterDropdownComponent
                      paramKey="department_id"
                      location={location}
                      options={departmentOptions}
                      onChange={(val) => this.onDepartmentSelect(val)}
                    />
                    <UncontrolledTooltip placement="top" target="department">
                      Select Department
                    </UncontrolledTooltip>
                  </span>
                }
                {departmentAll.length > 0 && isOrganizationVisible &&
                  <span id ="team">
                    <FilterDropdownComponent
                      paramKey="team_id"
                      location={location}
                      options={teamOptions}
                    />
                    <UncontrolledTooltip placement="top" target="team">
                      Select Team
                    </UncontrolledTooltip>
                  </span>
                } 
                {(query.punch_in_options !== '2') && <div id ="empType" className="float-right">
                  <div className="d-inline-block">
                    <FilterDropdownComponent
                      paramKey="punch_in_type"
                      location={location}
                      options={PUNCH_IN_TYPES_CURRENT}
                    />
                    <UncontrolledTooltip placement="top" target="empType">
                      Activity Type
                    </UncontrolledTooltip>
                  </div>  
                </div>}
                {
                  <div id ="punchInType" className="float-right">
                    <div className="d-inline-block">
                      <FilterDropdownComponent
                        paramKey="punch_in_options"
                        location={location}
                        options={PUNCH_IN_OPTIONS}
                        onChange={(val) => toggleTable(val)}
                      />
                      <UncontrolledTooltip placement="top" target="punchInType">
                        Punch In Options
                      </UncontrolledTooltip>
                    </div>  
                  </div>
               }
              </section>

            }
            {session === 'historic' && (
              <section className="d-flex">
                {departmentAll.length > 0 && isOrganizationVisible &&
                  <span id ="department" className="department-index">
                    <FilterDropdownComponent
                      paramKey="department_id"
                      location={location}
                      options={departmentOptions}
                      onChange={(val) => this.onDepartmentSelect(val)}
                    />
                    <UncontrolledTooltip placement="top" target="department">
                      Select Department
                    </UncontrolledTooltip>
                  </span>
                }
                {departmentAll.length > 0 && isOrganizationVisible &&
                  <span id ="team">
                    <FilterDropdownComponent
                      paramKey="team_id"
                      location={location}
                      options={teamOptions}
                    />
                    <UncontrolledTooltip placement="top" target="team">
                      Select Team
                    </UncontrolledTooltip>
                  </span>
                }
                <span id = "tracking">
                  <FilterDropdownComponent
                    paramKey="is_active"
                    location={location}
                    options={FILTER_OPTIONS}
                  />
                </span>
                <UncontrolledTooltip placement="top" target="tracking">
                  Employement Status
                </UncontrolledTooltip>
                { session === 'historic' &&     
                  <div id ="empType" className="float-right">
                    <div className="d-inline-block">
                      <FilterDropdownComponent
                        paramKey="punch_in_type"
                        location={location}
                        options={PUNCH_IN_TYPES}
                      />
                      <UncontrolledTooltip placement="top" target="empType">
                        Activity Type
                      </UncontrolledTooltip>
                    </div>  
                  </div>
                }
              </section>
            )}

          </Col>
          <Col md={6} className="px-0">
            <section className="float-right d-inline-block">
              <div className="d-inline-block">
                <FilterFormComponent
                  initialValues={query}
                  location={location}
                  placeholder="Name, Title or Tag"
                /> 
              </div>   
            </section>
            {session === 'current' && (query.punch_in_options !== '2') && (
              <section className="float-right">
                {query.view
                  ? <div id="list_view">
                      <Button
                      color="accent"
                      onClick={this.toggleMapView}
                      >
                      <img
                        src={ListViewIcon}
                        alt="Icon"
                        height="20"
                        width="20"
                      />
                    </Button>
                     <UncontrolledTooltip placement="top" target="list_view">
                        List View
                      </UncontrolledTooltip>
                  </div>
                  : <div>
                      <Button
                      id="map_view"
                      color="accent"
                      onClick={this.toggleMapView}
                      >
                      <img
                        src={MapViewIcon}
                        alt="Icon"
                        height="20"
                        width="20"
                      />
                      <UncontrolledTooltip placement="top" target="map_view">
                        Map View
                      </UncontrolledTooltip>
                    </Button>
                  </div>
                }
              </section>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            { session === 'historic' &&
              <div>
                <ActivityTrackFilterFormComponent
                  dateFormat={dateFormat}
                  location={location}
                  initialValues={formattedDates}
                />
              </div>  
            }
          </Col>
        </Row>  
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  teamAll: TeamDucks.teamAll(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapActionsToProps = dispatch => ({
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
});

ActivityTrackFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  departmentAll: PropTypes.array.isRequired,
  session: PropTypes.string,
  TeamDucks: PropTypes.object.isRequired,
  teamAll: PropTypes.array.isRequired,
  isOrganizationVisible: PropTypes.bool,
  userExtraData: PropTypes.object.isRequired,
};

ActivityTrackFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivityTrackFilterSection);
