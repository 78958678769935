import EventEmitter from 'lib/event-emitter';

const EVENT_TYPES = {
  SHOW_ASSIGNED_JOBS: 'SHOW_ASSIGNED_JOBS',
  SHOW_UNASSIGNED_JOBS: 'SHOW_UNASSIGNED_JOBS',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  ASSIGN_SUCCESS: 'ASSIGN_SUCCESS',
  SHOW_ASSIGN_BUTTON_LOADER: 'SHOW_ASSIGN_BUTTON_LOADER',
  HIDE_ASSIGN_BUTTON_LOADER: 'HIDE_ASSIGN_BUTTON_LOADER',
};


class employeeJobsListUseCase{

  EmployeeJobsAPIGateway = null;

  constructor(EmployeeJobsAPIGateway){
    
    this.EmployeeJobsAPIGateway = EmployeeJobsAPIGateway;
    this.eventEmitter = new EventEmitter();

  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }


  toString(){

    return 'employeeJobsListUseCase';
  }

  setup_data(assignJobObj, unassignObj){ 
    this.assignJobObj = assignJobObj;
    this.unassignObj= unassignObj

  }

  async getAssignedJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.assignedJobs = await this.EmployeeJobsAPIGateway.getAssignedJobsList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGNED_JOBS,
      data: this.assignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }


  async getUnassignedJobs(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.unassignedJobs = await this.EmployeeJobsAPIGateway.getUnassignedJobsList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_UNASSIGNED_JOBS,
      unassignedJobs: this.unassignedJobs,
    });
  }

  async fetchJobs(assignObj, unassignObj) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.unassignedJobs = await this.EmployeeJobsAPIGateway.getUnassignedJobsList(unassignObj);
    this.assignedJobs = await this.EmployeeJobsAPIGateway.getAssignedJobsList(assignObj);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGNED_JOBS,
      data: this.assignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_UNASSIGNED_JOBS,
      unassignedJobs: this.unassignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  async assignJobs(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGN_BUTTON_LOADER,
    });
    this.postAssignJobsList = await this.EmployeeJobsAPIGateway.assignJobs(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.ASSIGN_SUCCESS,
    });
    await this.fetchJobs(this.assignJobObj, this.unassignObj);
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_ASSIGN_BUTTON_LOADER,
    });
  }


  async deleteJob(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.updatedEmployeeJobsList = await this.EmployeeJobsAPIGateway.deleteEmployeeJob(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.DELETE_SUCCESS,
    });
    let assignFetch = {...this.assignJobObj, page: 1};
    await this.fetchJobs(assignFetch, this.unassignObj);
  }
}


export default employeeJobsListUseCase;