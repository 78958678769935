import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;
const KONNECT_URL = server.konnectHost;

export default {
  VENDORS: `${SERVER_URL}/business-admin/customers/`,
  VENDORS_INCOMPLETE_SIGNUP: `${SERVER_URL}/business-admin/customers/incomplete/`,
  VENDORS_INCOMPLETE_SIGNUP_RESET_PASSWORD: `${SERVER_URL}/business-admin/customers/reset-password/`,
  VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK: `${SERVER_URL}/business-admin/customers/activation-link/`,
  VENDOR: `${SERVER_URL}/company/`,
  COMPANY_ALL: `${SERVER_URL}/company/all/`,
  GROUP: `${SERVER_URL}/company/group-list/`,
  VENDOR_REGISTER: `${SERVER_URL}/company/register/`,
  BUSINESS_ADMIN_STATS: `${SERVER_URL}/business-admin/counts/`,
  BASIC_SETTING: `${SERVER_URL}/company/settings/base/`,
  NOTIFICATION_SETTING: `${SERVER_URL}/company/settings/notification/`,
  SYSTEM_SETTING: `${SERVER_URL}/company/settings/system/`,
  COUNTRIES: `${SERVER_URL}/address/country/`,
  STATES: `${SERVER_URL}/address/state/`,
  INDUSTRIES: `${SERVER_URL}/company/industries/`,
  ROLE: `${SERVER_URL}/company/employee/role/`,
  SUB_COMPANY: `${SERVER_URL}/company/sub-company/`,
  SUB_COMPANY_USER: `${SERVER_URL}/company/child/user/`,
  DEPARTMENT: `${SERVER_URL}/company/departments/`,
  ADJUSTMENT_TYPES: `${SERVER_URL}/company/adjustment-types/`,
  TEAM: `${SERVER_URL}/company/teams/`,
  UPDATE_ACTIVE_STATUS: `${SERVER_URL}/business-admin/customers/bulk/active/`,
  PLUGIN_LIST: `${SERVER_URL}/plugins/`,
  GET_METC_JOBS : `${SERVER_URL}/job/qb`,
  STATS: `${SERVER_URL}/business-admin/customer/`,
  REQUEST_FEATURES: `${SERVER_URL}/company/contact-us/dashboard/`,
  COMPANY_TUTORIAL:`${SERVER_URL}/company/company_tutorial/`,
  SEND_SMS:`${SERVER_URL}/company/send_sms/`,
  CHILD_COMPANY: `${SERVER_URL}/company/child/`,
  RESEND_OTP : `${SERVER_URL}/company/resend-activation/`,
  OTP_VERIFICATION: `${SERVER_URL}/company/activate/`,
  SHOW_OTP : `${SERVER_URL}/business-admin/company/get-activation-code/`,
  BILL_STATUS_DROPDOWN : `${SERVER_URL}/t2b/fsm/fsm/status-master`,
  CUSTOMER: `${SERVER_URL}/payment/customer/`,
  CANCEL_PLAN : `${SERVER_URL}/payment/customer/`,
  REJECT_CANCELLATION : `${SERVER_URL}/payment/customer/cancel/`, 
  DELETE_DEPARTMENT : `${SERVER_URL}/company/departments/delete/`,
  CUSTOM_SETTING : `${SERVER_URL}/business-admin/custom-settings/`,
  DELETE_TEAM: `${SERVER_URL}/company/teams/delete/`,
  REORDERED_IDS: `${SERVER_URL}/company/ids/`,
  /// Konect Url
  CREATE_PROFILE: `${KONNECT_URL}/qb/profile`,
  GET_PROFILE: `${KONNECT_URL}/qb/get-profile`,
  GET_SYNC: `${KONNECT_URL}/core/sync`,
  UPDATE_PROFILE: `${KONNECT_URL}/qb/update-profile`,
  UPDATE_SYNC: `${KONNECT_URL}/core/update-sync`,
  DISCONNECT_PROFILE: `${KONNECT_URL}/core/disconnect-profile`,
  ADD_EMPLOYEE: `${KONNECT_URL}/qb/add-employee`,
  GET_KONNECT_JOBS : `${KONNECT_URL}/core/get-jobs`,
  AUTHENTICATE : `${KONNECT_URL}/core/authenticate`,
  SAVE_JOB_MAPPING : `${KONNECT_URL}/core/sync-job`,
  GET_PAYROLL_ITEMS: `${KONNECT_URL}/core/payrolls`,
  SET_PAYROLL_MAPPING : `${KONNECT_URL}/core/sync-payroll`,
  SET_TASK_MAPPING : `${KONNECT_URL}/core/task-mapping`,
  GET_METC_TASKS : `${KONNECT_URL}/core/tasks/`,
  FILEPATH: `${KONNECT_URL}/qb/get-file/`,
  QBD_METC_EMPLOYEES: `${KONNECT_URL}/core/get-employees/`,
  SET_MAPPING: `${KONNECT_URL}/core/sync-employee/`,
  GET_PLUGIN_SETTING: `${KONNECT_URL}/core/sync`,
  DISCONNECT_PLUGIN_INTEGRATION: `${KONNECT_URL}/core/disconnect-profile/`,
  UPDATE_PLUGIN_INTEGRATION: `${KONNECT_URL}/konect-core/profile/stats/`,
  PLUGIN_FAILURE_LIST: `${KONNECT_URL}/konect-core/failed_list/`,
  PLUGIN_AUTH: `${KONNECT_URL}/qb/auth/check/`,
  METC_EMPLOYEES: `${KONNECT_URL}/metc/metc-employees/`, 
  UNSET_MAPPING: `${KONNECT_URL}/core/sync-employee-remove/`,
  UNSET_JOB_MAPPING: `${KONNECT_URL}/core/remove-job-mapping`,
  GET_NOTIFICATION_LIST: `${KONNECT_URL}/core/notification`,
  SET_ADP_PAYROLL_MAPPING : `${KONNECT_URL}/adp/map-payroll`
};
