import React from "react";
import cookie from "react-cookies";
import connect from "react-redux/lib/connect/connect";
import queryString from "query-string";

import * as UserDucks from "ducks/accounts/user";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";

import { NAV } from "constants/employees";
import TwoColumnLayout from "layouts/TwoColumn";
//import { toast } from "react-toastify";

import EmployeeTableComponent from "employees/components/List";
import PaginationComponent from "components/common/Pagination";
import listUseCase from "employees/usecases/listUseCase";
import EmployeeAPIGateway from "employees/gateways/employee";
import EmployeeFilterForm from "employees/components/filterForm";
import AttributeToggler from "employees/components/AttributeToggler";
import EmployeeInactiveConfirmationModal from "employees/components/EmployeeInactiveConfirmationModal";

class ListPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      employeeList: {},
      search: null,
      subCompanyList: [],
      teamList: [],
      departmentList: [],
      isLoading: false,
      isInfoOpen: true,
      infoList: [],
      showExtraInfo: false,
      selectedEmployees: [],
      isExporting: false,
      attributeToggler: false,
      showableAttributes: {},
      allJobs: [],
      isModalLoading: false,
      isButtonLoading: false,
      userPreferenceData: null,
      employeeInactiveConfirmationModal: false,
      refreshLoader: false,
    };
    this.updateState = this.updateState.bind(this);
    const employeeAPIGateway = new EmployeeAPIGateway();
    this.usecase = new listUseCase(employeeAPIGateway);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.changeCompanies = this.changeCompanies.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.setAttributeToggler = this.setAttributeToggler.bind(this);
    this.setAttributes = this.setAttributes.bind(this);
    // this.getAllJobsList = this.getAllJobsList.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.getUserPreference = this.getUserPreference.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    this.updateStatusofEmployee = this.updateStatusofEmployee.bind(this);
    this.toggleEmployeeInactiveModal = this.toggleEmployeeInactiveModal.bind(
      this
    );
    this.updateJobAssignmentofEmployee = this.updateJobAssignmentofEmployee.bind(
      this
    );
    this.checkEmpsJobAssignMentStatus = this.checkEmpsJobAssignMentStatus.bind(
      this
    );
    this.closeEmployeeInactiveModal = this.closeEmployeeInactiveModal.bind(
      this
    );
    this.accessorToHeader = {
      employee_id: "Id",
      "*first_name": "First Name",
      last_name: "Last Name",
      "*username": "UserName",
      title: "Title",
      "*user_role": "Role",
      "*phone_no": "Phone",
      tags: "Tags",
      email: "Email",
      is_active: "Is Active",
      address: "Address",
      last_activity: "Last Activity",
      department: "Department",
      team: "Team",
      "wage $/hr": "Wage $/Hr",
      kiosk_user: "Kiosk User",
      offline_mode: "Offline mode",
      dual_access: "Dual Access",
      hire_date: "Hire Date",
    };
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  setAttributeToggler(bool) {
    this.setState({ attributeToggler: bool });
    // if (!bool) {
    //   this.getUserPreference();
    // }
  }

  updateFilters(value) {
    // const {
    //   location: { search, pathname },
    //   history,
    // } = this.props;
    //const query = queryString.parse(search);
    const showFields = Object.keys(this.state.showableAttributes).filter(
      (item) => {
        if (this.state.showableAttributes[item] === true) return item;
        else if (
          this.state.showableAttributes[item] === false &&
          item === "*first_name"
        )
          return true;
        else return false;
      }
    );
    const pageSize = cookie.load("employee_page_size") || 10;
    const preferenceObj = {
      all_fields: Object.keys(this.accessorToHeader),
      show_fields: showFields,
      page_size: pageSize,
      extra_data: { ...value },
    };
    this.usecase.updateUserPreference(preferenceObj);
  }

  async setAttributes(attributes) {
    await this.setState({ showableAttributes: attributes });
    //cookie.save("showableAttributesEmployeelist", attributes, { path: "/" });
  }

  setColumns(submitData, flag) {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    const showFields = Object.keys(submitData).filter((item) => {
      if (submitData[item] === true) return item;
      else if (submitData[item] === false && item === "*first_name")
        return true;
      else return false;
    });
    const pageSize = cookie.load("employee_page_size") || 10;
    const preferenceObj = {
      all_fields: Object.keys(this.accessorToHeader),
      show_fields: showFields,
      page_size: pageSize,
      extra_data: { ...query },
    };
    this.usecase.updateUserPreference(preferenceObj).then(() => {
      // this.getUserPreference();

      if (!flag) {
        this.setState({
          showableAttributes: submitData,
          attributeToggler: false,
        });
      }

      //this.setState({ attributeToggler: false });
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    const { show_extra_info: prevInfo, ...prevRest } = prevQuery;
    const { show_extra_info: nextInfo, ...rest } = nextQuery;

    if (nextQuery.department_id!==undefined && prevQuery.department_id !== nextQuery.department_id) {
      this.usecase.getTeamList({ department_id: nextQuery.department_id });
    }
    if (
      nextQuery &&
      nextQuery.page_size &&
      nextQuery.page_size !== prevQuery.page_size
    ) {
      this.setColumns(this.state.showableAttributes, true);
      this.fetchData(nextSearch);
    } else if (prevInfo !== nextInfo && nextInfo) {
      this.updateState("showExtraInfo", JSON.parse(nextInfo));
      this.fetchData(nextSearch);
    } else if (
      prevSearch !== nextSearch &&
      (prevQuery.is_active !== nextQuery.is_active ||
        prevQuery.company_id !== nextQuery.company_id ||
        prevQuery.department_id !== nextQuery.department_id ||
        prevQuery.team_id !== nextQuery.team_id ||
        prevQuery.search != nextQuery.search ||
        prevQuery.ordering != nextQuery.ordering)
    ) {
      this.updateFilters({ ...nextQuery });
      this.fetchData(nextSearch);
    } else if (prevSearch !== nextSearch && nextInfo) {
      this.updateState("showExtraInfo", JSON.parse(nextInfo));
      this.fetchData(nextSearch);
    }
  }

  componentWillMount() {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    if ("show_extra_info" in query) {
      this.updateState("showExtraInfo", JSON.parse(query.show_extra_info));
    }
    this.listenListUsecase();
    const cookieKey = cookie.load("employee_info");

    if (cookieKey) {
      this.updateState("isInfoOpen", JSON.parse(cookieKey));
    }

    this.getUserPreference();
    this.usecase.getInfoList();
    this.usecase.getAllSubcompanies();
    this.usecase.getAllJobs({ status: 1, paginate: false, admin: true });
  }

  async getUserPreference() {
    const userPreferencesData = cookie.load("employees_user_preference");
    if (userPreferencesData === undefined) {
      await this.usecase
        .getUserPreference()
        .then(() => {})
        .catch((e) => {
          let attributes = {};
          for (const acc in this.accessorToHeader) {
            if (
              acc === "department" ||
              acc === "team" ||
              acc === "wage $/hr" ||
              acc === "kiosk_user" ||
              acc === "offline_mode"
            ) {
              attributes[acc] = false;
            } else {
              attributes[acc] = true;
            }
          }
          this.setAttributes(attributes);
        });
    } else if (!Object.keys(this.state.showableAttributes).length) {
      this.updateUserprefrencesData({ data: userPreferencesData });
    }
  }

  loadData() {
    const { subCompanyList, userPreferenceData } = this.state;
    const {
      history,
      location: { search, pathname },
    } = this.props;
    const subCompaniesId = subCompanyList.map((item) => item.id);
    const companiesId = JSON.stringify(subCompaniesId);
    const query = queryString.parse(search);
    if (
      !("is_active" in query) ||
      !("company_id" in query) ||
      !("show_extra_info" in query) ||
      !("department_id" in query) ||
      !("team_id" in query) ||
      !("search" in query) ||
      !("ordering" in query)
    ) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          is_active:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.is_active) ||
            true,
          company_id:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.company_id) ||
            companiesId,
          show_extra_info:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.show_extra_info) ||
            false,
          department_id:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.department_id) ||
            null,
          team_id:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.team_id) ||
            null,
          search:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.search) ||
            null,
          ordering:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.ordering) ||
            null,
        }),
      });
      if (!("team_id" in query)) {
        this.usecase.getTeamList({ company_id: companiesId });
      }
      if (!("department_id" in query)) {
        this.usecase.getDepartmentList({ company_id: companiesId });
      }
    } else {
      if (query.department_id) {
        this.usecase.getTeamList({ department_id: query.department_id });
      } else {
        this.usecase.getTeamList({ company_id: companiesId });
      }
      this.usecase.getDepartmentList({ company_id: companiesId });
      this.fetchData(search);
    }
  }

  fetchData(search) {
    const params = queryString.parse(search);
    const pageSize = cookie.load("employee_page_size");
    this.usecase.getEmployeeList({ ...params, page_size: pageSize });
  }

  updateUserprefrencesData(event) {
    let obj = {};
    let all_fields = event.data.all_fields;
    let show_fields = event.data.show_fields;
    this.setState({ userPreferenceData: event.data });
    all_fields.forEach((v) => {
      if (show_fields.indexOf(v) !== -1) {
        obj[v] = true;
      } else {
        obj[v] = false;
      }
    });
    this.setAttributes(obj);
    cookie.save("employee_page_size", event.data.page_size, {
      path: "/",
    });
    cookie.save("employees_user_preference", event.data, {
      path: "/",
    });
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "EMPLOYEE_LIST":
          this.updateState("employeeList", event.data);
          this.updateState("jobAssignStatus", null);
          break;
        case "SUBCOMPANY_LIST":
          this.setState({ subCompanyList: event.data }, () => this.loadData());
          break;
        case "CHANGE_COMPANIES":
          this.changeCompanies(event.companyIds);
          this.updateState("teamList", event.teamList);
          this.updateState("departmentList", event.departmentList);
          break;
        case "DEPARTMENT_LIST":
          this.updateState("departmentList", event.departmentList);
          break;
        case "TEAM_LIST":
          this.updateState("teamList", event.teamList);
          break;
        case "INFO_LIST":
          this.updateState("infoList", event.infoList);
          break;
        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;
        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;
        case "SELECTED_EMPLOYEES":
          this.updateState("selectedEmployees", event.data);
          break;
        case "SHOW_EXPORT_LOADER":
          this.updateState("isExporting", true);
          break;
        case "HIDE_EXPORT_LOADER":
          this.updateState("isExporting", false);
          this.updateState("selectedEmployees", []);
          break;
        case "ALL_JOBS":
          this.updateState("allJobs", event.data);
          break;
        case "SHOW_MODAL_LOADER":
          this.updateState("isModalLoading", true);
          break;
        case "HIDE_MODAL_LOADER":
          this.updateState("isModalLoading", false);
          break;
        case "SHOW_ASSIGN_BUTTON_LOADER":
          this.updateState("isButtonLoading", true);
          break;
        case "HIDE_ASSIGN_BUTTON_LOADER":
          this.updateState("isButtonLoading", false);
          break;
        case "UPDATE_USER_PREFERENCE":
          this.updateUserprefrencesData(event);
          break;
        case "UPDATE_STATUS":
          this.updateState("selectedEmployees", []);
          break;
        case "GET_USER_PREFERENCE":
          this.updateUserprefrencesData(event);
          // let obj = {};
          // let all_fields = event.data.all_fields;
          // let show_fields = event.data.show_fields;
          // this.setState({ userPreferenceData: event.data });
          // all_fields.forEach((v) => {
          //   if (show_fields.indexOf(v) !== -1) {
          //     obj[v] = true;
          //   } else {
          //     obj[v] = false;
          //   }
          // });
          // this.setAttributes(obj);
          // cookie.save("employee_page_size", event.data.page_size, {
          //   path: "/",
          // });
          // cookie.save("employees_usser_preference", event.data, {
          //   path: "/",
          // });
          break;
        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  // getAllJobsList(isJobListModalOpen) {
  //   if (isJobListModalOpen) {
  //     this.props.usecase.getAllJobs({
  //       status: 1,
  //       paginate: false,
  //       admin: true,
  //     });
  //   }
  // }

  changeCompanies(companyIds) {
    const {
      location: { search, pathname },
      history,
    } = this.props;
    const query = queryString.parse(search);
    const { page, ...rest } = query;
    history.push({
      pathname,
      search: queryString.stringify({
        ...rest,
        company_id: JSON.stringify(companyIds),
      }),
    });
  }

  toggleInfo() {
    const { isInfoOpen } = this.state;
    this.updateState("isInfoOpen", !isInfoOpen);
    cookie.save("employee_info", !isInfoOpen);
  }

  formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.toString().replace(/[^\d]/g, ""); //replace all characters except numbers
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  closeEmployeeInactiveModal() {
    this.setState({
      employeeInactiveConfirmationModal: false,
    });
  }

  toggleEmployeeInactiveModal(status) {
    if (status) {
      this.updateState("employeeInactiveConfirmationModal", status);
    } else {
      this.updateStatusofEmployee(false);
    }
  }

  checkEmpsJobAssignMentStatus() {
    const { selectedEmployees } = this.state;
    let status = false;
    selectedEmployees.forEach((i) => {
      if (i.jobs_present) {
        status = i.jobs_present;
      }
    });
    this.toggleEmployeeInactiveModal(status);
  }

  updateJobAssignmentofEmployee(status) {
    const { selectedEmployees } = this.state;

    const {
      history,
      location: { pathname, search },
    } = this.props;
    this.updateState("refreshLoader", true);
    // const query = queryString.parse(search);
    const empId_Obj = {
      employee_ids: selectedEmployees.map((i) => i.id),
    };
    this.usecase.updateUserJobAssignment(empId_Obj).then(() => {
      this.updateState("refreshLoader", false);
      this.updateStatusofEmployee(status);
    });
  }

  updateStatusofEmployee(status, key) {
    const { selectedEmployees } = this.state;
    const {
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    this.closeEmployeeInactiveModal();
    this.usecase
      .updateStatus(
        status,
        selectedEmployees.map((i) => i.id),
        query
      )
      .then(() => {
        this.usecase.getEmployeeList({ ...query, page: 1 });
        history.push({
          pathname,
          search: queryString.stringify({ ...query, page: 1 }),
        });
      });
  }

  render() {
    const mandatoryField = "*first_name";
    const {
      match: { params },
      isOrganizationVisible,
      location: { search },
      companyPermissions,
    } = this.props;
    const {
      subCompanyList,
      departmentList,
      employeeList,
      teamList,
      infoList,
      isInfoOpen,
      showExtraInfo,
      isLoading,
      allJobs,
      isButtonLoading,
      employeeInactiveConfirmationModal,
      // cancelLoader,
      refreshLoader,
    } = this.state;
    const subCompanyEmployeeCount = subCompanyList.length
      ? subCompanyList.map((a) => a.employee_count).reduce((b, c) => b + c)
      : 0;
    return (
      <TwoColumnLayout
        navInfo={params.id ? NAV.EmployeeEditFormPage : NAV.EmployeeListPage}
        fetchData={() => this.fetchData(search)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        isLoading={isLoading}
      >
        <div>
          <EmployeeFilterForm
            location={this.props.location}
            history={this.props.history}
            usecase={this.usecase}
            subCompanyList={subCompanyList}
            departmentList={departmentList}
            teamList={teamList}
            userExtraData={this.props.userExtraData}
            isOrganizationVisible={isOrganizationVisible}
            isSubCompany={this.props.isSubCompany}
            subCompanyEmployeeCount={subCompanyEmployeeCount}
            companyPermissions={companyPermissions}
            selectedEmployees={this.state.selectedEmployees}
            isExporting={this.state.isExporting}
            setAttributeToggler={this.setAttributeToggler}
            showableAttributes={this.state.showableAttributes}
            allJobs={allJobs}
            isButtonLoading={isButtonLoading}
            attributeToggler={this.state.attributeToggler}
            updateFilters={this.updateFilters}
            checkEmpsJobAssignMentStatus={this.checkEmpsJobAssignMentStatus}
            updateStatusofEmployee={this.updateStatusofEmployee}
          />
          {this.state.attributeToggler && (
            <AttributeToggler
              setToggle={() =>
                this.setAttributeToggler(!this.state.attributeToggler)
              }
              toggle={this.state.attributeToggler}
              data={{
                labels: Object.values(this.accessorToHeader),
                accessors: Object.keys(this.accessorToHeader),
                defaultVals: Object.values(this.state.showableAttributes),
              }}
              onChange={this.setAttributes}
              mandatoryField={mandatoryField}
              setColumns={this.setColumns}
            />
          )}
          <EmployeeTableComponent
            data={employeeList}
            location={this.props.location}
            history={this.props.history}
            subCompanyEmployeeCount={subCompanyEmployeeCount}
            isLoading={isLoading}
            showExtraInfo={showExtraInfo}
            isOrganizationVisible={isOrganizationVisible}
            usecase={this.usecase}
            selectedEmployees={this.state.selectedEmployees}
            showableAttributes={this.state.showableAttributes}
            dateFormat={this.props.dateFormatDisplay}
            timeFormat24Hrs={this.props.timeFormat24Hrs}
            formatPhoneNumber={this.formatPhoneNumber}
          />
          {subCompanyEmployeeCount && !isLoading ? (
            <PaginationComponent
              count={this.state.employeeList.count}
              location={this.props.location}
              cookieKey="employee_page_size"
              history={this.props.history}
            />
          ) : null}
          {employeeInactiveConfirmationModal && (
            <EmployeeInactiveConfirmationModal
              toggle={this.closeEmployeeInactiveModal}
              isOpen={employeeInactiveConfirmationModal}
              updateStatusofEmployee={this.updateStatusofEmployee}
              refreshLoader={refreshLoader}
              updateJobAssignmentofEmployee={this.updateJobAssignmentofEmployee}
            />
          )}
        </div>
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  userExtraData: UserDucks.userExtraData(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  companyPermissions: UserDucks.companyPermissions(state),
  isSubCompany: UserDucks.isSubCompany(state),
  dateFormatDisplay: BasicSettingDucks.dateFormatDisplay(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPage);
