import React, { PureComponent } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import styles from "./styles.module.scss";
import ButtonLoaderAtom from "atoms/ButtonLoader";

class EmployeeInactiveConfirmationModal extends PureComponent {
  render() {
    const {
      isOpen,
      toggle,
      item,
      updateStatusofEmployee,
      refreshLoader,
      cancelLoader,
      updateJobAssignmentofEmployee,
      editForm,
      type
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <ModalHeader toggle={() => toggle()}>Delete Confirmation</ModalHeader>
        <ModalBody>
          <div style={{ fontSize: "16px" }}>
          { editForm === "true"?
          'Would you like to remove the employee   from all assigned jobs ? '
          :
          'Do you want to remove the job allocation from the employees as well ?'
          }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            className={styles.cancel_btn}
            onClick={() => {
              editForm === "true"
                ? updateJobAssignmentofEmployee(true)
                : updateStatusofEmployee(false);
            }}
            disabled = {refreshLoader}
          >
            No
          </Button>
          <Button
            type="submit"
            color="secondary"
            onClick={() => updateJobAssignmentofEmployee(false)}
          >
            Yes <ButtonLoaderAtom active={refreshLoader} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EmployeeInactiveConfirmationModal;
