import React from "react";
import cookie, { load } from "react-cookies";
import { toast } from "react-toastify";
import connect from "react-redux/lib/connect/connect";
import queryString from "query-string";

import * as RouteDucks from "ducks/routes";

import { NAV } from "constants/applicants";
import TwoColumnLayout from "layouts/TwoColumn";
import listUseCase from "applicants/usecases/listUsecase";
import PaginationComponent from "components/common/Pagination";

import ApplicantsAPIGateway from "applicants/gateways/applicants";
import ApplicantFilterForm from "applicants/components/filterForm";
import ApplicantListComponent from "applicants/components/applicantsList";


import moment from "moment";

class ApplicantsListPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userPreferenceData: null,
      subCompanyList: [],
      applicantsList: [],
      applicantsListCount: 0,
      selectedApplicants: [],
      isLoading: false,
    };
    this.applicantsAPIGateway = new ApplicantsAPIGateway();
    this.usecase = new listUseCase(this.applicantsAPIGateway);
    this.updateState = this.updateState.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.loadData = this.loadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSelectApplicant = this.onSelectApplicant.bind(this);
    this.getUserPreference = this.getUserPreference.bind(this);
  }


  componentDidMount() {
    this.listenListUsecase();
    this.getUserPreference();
    this.loadData();
  }

  componentWillReceiveProps(nextProps, prevProps) {
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  getUserPreference() {
    // we will verify this
    this.usecase
      .getUserPreference()
      .then(() => {
        this.usecase.getAllSubcompanies();
      })
      .catch((e) => {
        console.log(e)
      });
  }

  loadData() {
    const { subCompanyList, userPreferenceData } = this.state;
    const {
      history,
      location: { search, pathname },
    } = this.props;
    const subCompaniesId = subCompanyList.map((item) => item.id);
    const companiesId = JSON.stringify(subCompaniesId);
    const query = queryString.parse(search);
    if (
      !("is_active" in query) ||
      !("company_id" in query) ||
      !("show_extra_info" in query) ||
      !("search" in query) ||
      !("ordering" in query)
    ) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          is_active:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.is_active) ||
            true,
          company_id:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.company_id) ||
            companiesId,
          show_extra_info:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.show_extra_info) ||
            false,
          search:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.search) ||
            null,
          ordering:
            (userPreferenceData &&
              userPreferenceData.extra_data &&
              userPreferenceData.extra_data.ordering) ||
            null,
        }),
      });
    }else{
      this.fetchData(query)
    }
  }

  fetchData(search) {
    const pageSize = cookie.load("applicant_list_page_size");
    this.setState({selectedApplicants: []});
    this.usecase.getApplicantsList({...search, page_size: pageSize || 10, applicant_role: true });
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "GET_APPLICANTS_LIST_SUCCESS":
          this.updateState("applicantsList", event.data.results);
          this.updateState("applicantsListCount", event.data.count);
          break;

        case "GET_APPLICANTS_LIST_FAILURE":
          this.updateState("applicantsList", []);
          this.updateState("applicantsListCount", 0);
          toast.error("Something went wrong!");
          break;

        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;

        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;

        case "GET_USER_PREFERENCE":
          this.setState({ userPreferenceData: event.data });
          break;

        case "SUBCOMPANY_LIST":
          this.setState({ subCompanyList: event.data }, () => this.loadData());
          break;
        
        case "STATUS_CHANGED_SUCCESS":
          toast.success("Status updated successfully");
          this.loadData();
          break;

        case "STATUS_CHANGED_FAILURE":
          toast.error("Something went wrong!!;")
          break;

        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  onSelectApplicant(data){
    const { selectedApplicants } = this.state;
    const eleIndex = selectedApplicants.findIndex((i) => i.id === data.id);
    if(eleIndex > -1){
      let newIds = [...selectedApplicants];
      newIds.splice(eleIndex, 1);
      this.setState({ selectedApplicants: [...newIds]})
    }else{
      this.setState({ selectedApplicants: [...selectedApplicants, data]})
    }
  }

  render() {
    const {
      location,
      history
    } = this.props;
    const {
      applicantsList,
      applicantsListCount,
      selectedApplicants,
      isLoading,
    } = this.state;

    const query = queryString.parse(location.search);
    return (
      <TwoColumnLayout
      navInfo={NAV.ApplicantListPage}
      fetchData={() => this.fetchData(query)}
      >
          <ApplicantFilterForm
            location={location}
            history={history}
            selectedApplicants={selectedApplicants}
            usecase={this.usecase}
            />
            
            <ApplicantListComponent
              list={applicantsList}
              onSelectApplicant={this.onSelectApplicant}
              selectedApplicants={selectedApplicants}
              isLoading={isLoading}
              location={location}
            />

            {applicantsListCount && !isLoading ? (
              <PaginationComponent
                count={applicantsListCount}
                location={location}
                cookieKey="applicant_list_page_size"
                history={history}
              />
            ) : null}

        </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantsListPage);
