import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';

import { FORM_LAYOUT } from 'constants/layout';
import closeimage from 'components/vendors/CloseCross.png';

/**
 *  * VendorDepartmentListPage -> VendorDepartmentFilterSection -> VendorTeamFormModalComponent
 *
 * Props:
 *    - Add Or Update Team
 */
const VendorTeamFormModalComponent = (props) => {
  const { 
    departmentAll,
    error,
    isOpen,
    submitting,
    handleSubmit,
    toggleTeamForm,
    initialValues,
    createOrUpdate,
  } = props;

  const submit = (data) => {
    const detail = data;

    if(!detail.is_active) detail.is_active = false;
    return createOrUpdate(detail, "team")
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleTeamForm} className="form-modal" backdrop="static">
      <ModalHeader>{initialValues && initialValues.id ? "Edit Team" : "Add Team"} 
      <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggleTeamForm()} />
      </ModalHeader>
      <Form onSubmit={handleSubmit(submit)} noValidate className='ml-4 mr-4 pl-1 pr-2 pb-2'>
        <div className="error-text ml-2">{error}</div>
        <ModalBody>
          <Field
            name="department"
            label="Department"
            component={SelectAtom}
            placeholder="Department"
            options={departmentAll}
            labelKey="name"
            valueKey="id"
            layout={FORM_LAYOUT}
            simpleValue
          />
          <Field
            id="name"
            name="name"
            label="Name"
            component={InputAtom}
            placeholder="Name"
            layout={FORM_LAYOUT}
          />
          <Field
            id="description"
            name="description"
            label="Description"
            component={InputAtom}
            placeholder="Description (Optional)"
            layout={FORM_LAYOUT}
          />
          <Field
            id="is_active"
            type="checkbox"
            name="is_active"
            label="Active?"
            component={CheckboxAtom}
            layout={FORM_LAYOUT}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.department) errors.department = 'Enter Department';
  if (!values.name) errors.name = 'Enter Name';
  return errors;
};

VendorTeamFormModalComponent.propTypes = {
  departmentAll: PropTypes.array.isRequired,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleTeamForm: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'VendorTeamFormModalComponent',
  validate,
})(VendorTeamFormModalComponent);
