import React from 'react';

import { GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import SessionMapModalComponent from 'components/activities/activityTrackList/SessionMapModal';
import ActivityTrackTableComponent from 'components/activities/activityTrackList/Table';
import AbsentEmployeeTrackTableComponent from 'components/activities/activityTrackList/AbsentEmployeeTable';

import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;


/**
 * ActivityTrackListPage -> ActivityTrackListSection
 *
 * Components:
 *    - {@link ActivityTrackTableComponent}
 *
 * State:
 *    - activityTrackList
 *    - selectedMarkerAddress
 *    - sessionInfo
 *
 * Actions:
 *    - getActivityLocationAddress
 */

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;

class ActivityTrackListSection extends React.Component {
  constructor(props) {
    super(props);
    this.getLocationAddress = this.getLocationAddress.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
    this.codeLatLng = this.codeLatLng.bind(this);
    this.resetModalData = this.resetModalData.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
    this.state = { isOpen: false,
      isModalLoading: false,
      isLocationLoading: false,
      selectedEmployee: '',
      permission: true,
    };
  }

  getLocationAddress(marker) {
    if(!marker.address && marker.position && Object.keys(marker.position).length){
      return this.props.ActivityTrackDucks.getActivityLocationAddress(marker, GeocodingOption)
        .then(() => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...marker,
            address: selectedMarkerAddress,
          });
        })
    }
    return null;
  }

  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }

  resetModalData(){
    return this.props.ActivityTrackDucks.resetSessionInfo();
  }

  handleMarkerClick(marker, showBeacon) {
    // If location address already exists then just toggle
    if (marker.address) return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
    if(showBeacon) return this.getLocationAddress(marker);
  }

  toggle(data) {
    const { isOpen } = this.state;
    const { session } = this.props;
    this.setState({ isModalLoading: true, isLocationLoading: true });
    if(isOpen) return this.setState({ isOpen: false , selectedEmployee: '' });
    this.setState({isOpen: !isOpen});
    if (data && data.id) {
      this.props.GeoFenceDucks.getActivityTrackGeofences(data);
      if(session === 'current'){
        this.props.ActivityTrackDucks.getActivityTrack(data)
          .then(() => {
            const { sessionInfo } = this.props;
            const arr = sessionInfo.locations && sessionInfo.locations.filter((item) => item.activity_code !== 'BEACON');
            const checkArrForLocation = arr && arr.filter((i) => Object.keys(i).length);
            if(sessionInfo && sessionInfo.punch_in_type && !sessionInfo.punch_in_type.kiosk &&
                checkArrForLocation && checkArrForLocation.length) {
              this.codeLatLng(arr);
            }
            else{
              this.setState({ isLocationLoading: false })
            }
            this.setState({ permission: true, isModalLoading: false });
          })
          .catch((err) => {
            this.setState({ permission: false, isModalLoading: false, isLocationLoading: false });
          });
      }
      else {
        this.setState({ selectedEmployee: data.employee && data.employee.user })
        this.props.ActivityTrackDucks.getActivityTrackHistoric(data)
          .then(() => {
            const { sessionInfo } = this.props;
            const arr = sessionInfo.locations && sessionInfo.locations.filter((item) => item.activity_code !== 'BEACON');
            const checkArrForLocation = arr && arr.filter((i) => Object.keys(i).length);
            if(sessionInfo && sessionInfo.punch_in_type && !sessionInfo.punch_in_type.kiosk &&
                checkArrForLocation && checkArrForLocation.length) {
              this.codeLatLng(arr);
            }
            else{
              this.setState({ isLocationLoading: false });
            }
            this.setState({
              isModalLoading: false,
              permission: true,
            });
          })
          .catch((err) => {
            this.setState({
              isModalLoading: false,
              isLocationLoading: false,
              permission: false
            });
          });
      }
    }
    return null;
  }

  toggleInfoWindow(marker) {
    return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
  }

  codeLatLng(array) {
    const updatedArr = array.length ? array.filter((i)=> i.position && i.position.lat !== undefined && i.position.lng !== undefined) : array;
    if(!updatedArr || !updatedArr.length) return this.setState({ isLocationLoading:  false });
    this.setState({ isLocationLoading:  true });
    Promise.all(updatedArr.map((item, index) => 
       setTimeout(()=> {
        if(Object.keys(item.location).length){
          const newItem = { ...item, position: { lat: item.location.coordinates[1],
            lng: item.location.coordinates[0] } };
          return(this.props.ActivityTrackDucks.getActivityLocationAddress(newItem , GeocodingOption)
            .then((res) => {
              const { selectedMarkerAddress } = this.props;
              this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
                ...newItem,
                address: selectedMarkerAddress,
              });
              if(index == updatedArr.length - 1){
                this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
                this.setState({ isLocationLoading:  false })            
              }
            })
            .catch(() => {
              if(index == updatedArr.length - 1){
                this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
                this.setState({ isLocationLoading:  false })            
              }
            })
          );
        }
        else{
          this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
          this.setState({ isLocationLoading:  false })         
        }
        return null;
      }, 100*index)
    ))
      .catch(() => this.setState({ isLocationLoading:  false }));
    return null;
  }

  render() {
    const { activityTrackList, isLoading, location, session,
      sessionInfo, selectedMarkerAddress, geoFenceDetail, absentEmployee ,absentEmpActivityTrackList} = this.props;
    const { isOpen, isModalLoading, isLocationLoading,
      selectedEmployee, permission } = this.state;
      
    let locationArray =[];
    let locationMap = [];
    //filter the location array from activityTrackList result
    activityTrackList.results.map((item)=>{
      if(item.locations && item.locations.length){
        item.locations.map(i=>{
          i.location && i.location.coordinates && locationMap.push(i.location)
        })
        locationArray.push(locationMap.flat());
        locationMap=[];
      }
      else{
        locationArray.push([]);
      }
    })
     
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
       {    
         !absentEmployee  ? <ActivityTrackTableComponent
          data={activityTrackList}
          session={session}
          toggle={this.toggle}
          locationArray = {locationArray}
        />:
        <AbsentEmployeeTrackTableComponent data={absentEmpActivityTrackList?.results ?? []}
          session={session}
          toggle={this.toggle}
          locationArray = {locationArray}/>
        }
        {isOpen &&
          <SessionMapModalComponent
            isOpen={isOpen}
            sessionInfo={sessionInfo}
            onMarkerClick={this.handleMarkerClick}
            onMarkerClose={this.handleMarkerClick}
            toggle={this.toggle}
            selectedMarkerAddress={selectedMarkerAddress}
            geoFenceDetail={geoFenceDetail}
            isModalLoading={isModalLoading}
            toggleInfoWindow={this.toggleInfoWindow}
            isLocationLoading={isLocationLoading}
            resetModalData={this.resetModalData}
            getLocationAddress={this.getLocationAddress}
            selectedEmployee={selectedEmployee}
            permission={permission}
            track
            userExtraData={this.props.userExtraData}
            session = {session === 'current' ? 'current' : 'historic'}
            getAddressForMarker = {this.getAddressForMarker}
            accessSidemenu = {this.props.accessSidemenu}
          />
        }
        <PaginationComponent
          count={absentEmployee ? absentEmpActivityTrackList.count : activityTrackList.count}
          location={location}
          cookieKey={session === 'current' ? absentEmployee ? "current_absent_emp_tracking_page_size" : "current_tracking_page_size" :  "historic_tracking_page_size"}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  activityTrackList: ActivityTrackDucks.activityTrackList(state),
  absentEmpActivityTrackList : ActivityTrackDucks.absentEmpActivityTrackList(state),
  geoFenceDetail: GeoFenceDucks.geoFenceDetail(state),
  selectedMarkerAddress: ActivityTrackDucks.selectedMarkerAddress(state),
  sessionInfo: ActivityTrackDucks.sessionInfo(state),
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
  accessSidemenu: UserDucks.accessSidemenu(state),
});

const mapActionsToProps = dispatch => ({
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

ActivityTrackListSection.propTypes = {
  activityTrackList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  selectedMarkerAddress: PropTypes.string,
  sessionInfo: PropTypes.object,
  geoFenceDetail: PropTypes.object,
  session: PropTypes.string.isRequired,
};

ActivityTrackListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectAppToRedux = connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivityTrackListSection);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION,
})(connectAppToRedux);
