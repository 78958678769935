import React from 'react';

import PropTypes from 'prop-types';

import Col from 'reactstrap/lib/Col';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import Label from 'reactstrap/lib/Label';

const InputAtom = (props) => {
  const {
    input, layout, label, prefix, suffix, help, size,
    meta: { touched, error }, ...rest
  } = props;
  return (
    <FormGroup
      row={Object.keys(layout).length > 0}
      color={touched && error ? 'danger' : ''}
    >
      {label && <Label {...layout.labelCol} for={rest.id}>{label}</Label>}
      {Object.keys(layout).length > 0
        ? (<Col {...layout.wrapperCol}>
          <InputGroup>
            {prefix && (<div className= "input-group-addon">{prefix}</div>)}
            {rest.static
              ? <Input {...rest} data-lpignore={true} autoComplete="off">{input.value}</Input>
              : (<Input
                {...input}
                {...rest}
                data-lpignore={true}
                autoComplete="off"
                size={layout.size}
              />)
            }
            {suffix && (<div className= "input-group-addon">{suffix}</div>)}
          </InputGroup>
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </Col>)
        : (<span>
          <InputGroup>
            {prefix && (<div className= "input-group-addon">{prefix}</div>)}
            {rest.static
              ? <Input {...rest} data-lpignore={true} autoComplete="off">{input.value}</Input>
              : (<Input
                {...input}
                {...rest}
                size={size}
                data-lpignore={true}
                autoComplete="off"
              />)
            }
            {suffix && (<div className= "input-group-addon">{suffix}</div>)}
          </InputGroup>
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </span>)
      }
    </FormGroup>
  );
};

InputAtom.defaultProps = {
  layout: {},
};

InputAtom.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  layout: PropTypes.object,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  label: PropTypes.string,
  help: PropTypes.string,
  static: PropTypes.bool,
};

export default InputAtom;
