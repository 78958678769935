import React from "react";
import PropTypes from "prop-types";

import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as PayrollSettingDucks from "ducks/payrolls/setting";
import * as RouteDucks from "ducks/routes";
import * as InfoDucks from "ducks/info/info";

import TwoColumnLayout from "layouts/TwoColumn";
import { toast } from "react-toastify";
import AddTimeOffSlider from "sections/pto/addTimeOffSlider/TimeOffSlider";
import TimeOffRequestSlider from "sections/pto/timeOffRequestSlider/TimeOffRequestSlider";

import { NAV } from "constants/pto";
import StorageGateway from "lib/storage-gateway";
import styles from "./styles.module.scss";

/**
 * PayrollSettingListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link PayrollSettingFormSection}
 *    - {@link PayrollSettingFilterSection}
 *
 * Fetch Needs:
 *    - getPayrollSetting
 */
let oAuthToken = "";
let company_id = "";
class TimeOffListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addTimeOffSlider: false,
      timeOffRequestSlider: false,
      policyId: 0,
      employeeName: "",
      refresh: false
    };
    this.closeSlider = this.closeSlider.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.eventListnerFunction=this.eventListnerFunction.bind(this)
    this.postMessageToChild=this.postMessageToChild.bind(this);
  }
  closeSlider() {
    this.setState({ addTimeOffSlider: false, timeOffRequestSlider: false });
  }
  componentWillMount() {
    oAuthToken = StorageGateway.get("oAuthToken");
    company_id = StorageGateway.get("company_id");
  }

  postMessageToChild(messageType) {
    const iFrame = document.getElementById("scheduling");
    iFrame.contentWindow.postMessage(messageType, "*");
  }
  
  fetchData() {
    this.setState({ refresh: true });
    setTimeout(() => {this.setState({ refresh: false });this.closeSlider()},100);
  }

  eventListnerFunction(event){
    const data =
          event.data &&
          typeof event.data !== "object" &&
          JSON.parse(event.data);
        const message = data?.message;
        if (message === "SUCCESS_TOAST") {
          toast.success(data?.content?.toastMessage);
          this.postMessageToChild("REFRESH_TIMEOFF_REQUEST");
          this.closeSlider();
        }
        else if (message === "FAILURE_TOAST") {
          toast.error(data?.content?.toastMessage);
        }else if (message === "CLOSE_ADD_TIME_OFF_SLIDER") {
          this.setState({ addTimeOffSlider: false });
        }else if (message === "OPEN_ADD_TIME_OFF_SLIDER") {
          this.setState({
            addTimeOffSlider: true,
            timeOffRequestSlider: false,
          });
        }else if (message === "OPEN_TIME_OFF_REQUEST_SLIDER") {
          this.setState({
            timeOffRequestSlider: true,
            policyId: data?.content?.policyId,
            employeeName: data?.content?.empName,
            addTimeOffSlider: false,
          });
        }else if (message === "CLOSE_TIME_OFF_REQUEST_SLIDER") {
          this.setState({ timeOffRequestSlider: false });
        }
      
  }
  
  componentDidMount(){
    window.addEventListener(
      "message",
      this.eventListnerFunction,
      false
    );

  }
  componentWillUnmount() {
    window.removeEventListener("message",this.eventListnerFunction);
  }
  render() {
    return (
      <div className={`${styles["wrapTimecardDetails"]}`}>
        <div
          className={
            this.state.addTimeOffSlider || this.state.timeOffRequestSlider
              ? `${styles["showEditModal"]} abc`
              : `${styles["hideModal"]} abc`
          }
        >
          <TwoColumnLayout
           navInfo={NAV.TimeOffListPage}
           fetchData={()=>this.postMessageToChild("REFRESH_TIMEOFF_REQUEST")}
          >
            <div
              style={{
                height: "calc(100vh - 102px - 65px)",
                marginRight: "-15px",
                marginTop: "-1rem",
              }}
            >
                <iframe
                  id="scheduling"
                  title="scheduling-iframe"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ width: "100%", height: "100%" }}
                  src={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/timeoff-request/?oAuthToken=${oAuthToken}&company_id=${company_id}`}
                />
            </div>
          </TwoColumnLayout>
        </div>
        <div style={{ width: "30%" }}>
          {this.state.addTimeOffSlider ? (
            <AddTimeOffSlider
              oAuthToken={oAuthToken}
              company_id={company_id}
              closeSlider={this.closeSlider}
            />
          ) : null}
          {this.state.timeOffRequestSlider ? (
            <TimeOffRequestSlider
              oAuthToken={oAuthToken}
              company_id={company_id}
              employeeName={this.state.employeeName}
              policyId={this.state.policyId}
              closeSlider={this.closeSlider}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = (dispatch) => ({
  PayrollSettingDucks: bindActionCreators(PayrollSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

TimeOffListPage.propTypes = {
  PayrollSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

TimeOffListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(TimeOffListPage);
