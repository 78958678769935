import React from 'react';
import Link from 'react-router-dom/Link';
import { Formik, Field } from 'formik';
import { Form,FormGroup, FormText, Label, Input, Button, Card, CardTitle, Row, Col } from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CountryCodeFormik from 'generics/CountryCodeFormik';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import styles from './styles.module.scss';
import name  from './name.png';
import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';
import connect from 'react-redux/lib/connect/connect';

import reduxForm from 'redux-form/lib/reduxForm';
import formValueSelector from 'redux-form/lib/formValueSelector';

class RegisterFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.state = { inputType: 'password', showStrengthCheck : false };
  }

    strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }


  async onSubmit(values, {setSubmitting, setErrors}) {
    setErrors({});
    const { inputType } = this.state;
    this.props.usecase.setup_data({ ...values, phone_ext: values.phone_ext ? values.phone_ext : '+1', inputType });
    try {
      
      await this.props.usecase.execute();
    } catch (e) {
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  render(){
    const { inputType } = this.state;
    const { password } = this.props;

    return (
      <div >
      <Card className="ui-card mt-3">
      <div className="text-center pb-5 pt-1">
        <LogoMainBlueAtom height="85" className={`${styles['logo']}`} width="130"/><br />
        <img src={name} height="26px" width="150px" alt="Logo"/>
      </div>

      <CardTitle className={`${styles['heading']} text-left ml-5 mr-5`}>Welcome to the future of employee management</CardTitle>
        <Formik
          initialValues={this.props.data}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
         }) => (
            <Form onSubmit={handleSubmit} className="ml-5 mr-5">
            <div style={{fontSize: '20px'}}><FormText color="danger">{errors && errors.non_field_errors}</FormText></div>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>First Name*</Label>
                    <Input
                      type="text"
                      name="first_name"
                      placeholder="First name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      invalid={errors && touched.first_name && errors.first_name}
                      className={errors && errors && errors.first_name ? "form-control-error" : null}
                    />
                    <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.first_name}</FormText></div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      name="last_name"
                      placeholder="Last name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      invalid={errors && touched.last_name && errors.last_name}
                      className={errors && errors.last_name ? "form-control-error" : null}
                    />
                    <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.last_name}</FormText></div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Business Name*</Label>
                    <Input
                      type="text"
                      name="company_name"
                      placeholder="eg: Silver spur ranch"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                      invalid={errors && touched.company_name && errors.company_name}
                      className={errors && errors.company_name ? "form-control-error" : null}
                    />
                    <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.company_name}</FormText></div>
                  </FormGroup>
                </Col>
                <Col>
                  <Row>
                    <Col md={5} className="pr-1">
                      <Field
                        id="phone_ext"
                        name="phone_ext"
                        label="Code"
                        component={CountryCodeFormik}
                      />
                      <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_ext}</FormText></div>
                    </Col>
                    <Col className="pl-0">
                       <Label>Phone Number*</Label>
                      <FormGroup>
                        <Input
                          type="text"
                          name="phone_no"
                          placeholder="8055550123"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone_no}
                          invalid={errors && touched.phone_no && errors.phone_no}
                          className={errors && errors.phone_no ? "form-control-error" : null}
                        />

                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_no}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormGroup>
                <Label className="font-styling">Email Address (<small className={`${styles['label-sub-part']} text-muted font-styling`}>We'll never share your email with anyone else.</small>)</Label>
                <Input
                  type="email"
                  name="email"
                  placeholder="name@example.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  invalid={errors && touched.email && errors && errors.email}
                  className={errors && errors.email ? "form-control-error" : null}
                  data-lpignore={true}
                  autoComplete="new-password"
                />
                <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.email}</FormText></div>
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                  <div className="pos-relative">
                    <Label>Password*</Label>
                    <Input
                      type={inputType}
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      invalid={errors && touched.password && errors && errors.password}
                      className={errors && errors.password ? "form-control-error" : null}
                      data-lpignore={true}
                      autoComplete="new-password"
                      onBlur={(e) => { this.strengthCheckToggle(); handleBlur(e)}}
                      onFocus = {this.strengthCheckToggle}
                    />
                    {inputType === 'password'
                      ? (<EyeOpenIconAtom
                        className="password-icon-custom"
                        height="22" width="22"
                        onClick={this.toggleInputType}
                      />)
                      : (<EyeCloseIconAtom
                        className="password-icon-custom"
                        height="22" width="22"
                        onClick={this.toggleInputType}
                    />)
                  }
                  <div style={{minHeight: '3vh'}} className="pl-2"><FormText color="danger">{errors && errors.password}</FormText></div>
                  {this.state.showStrengthCheck &&
                    <PasswordStrengthMeter
                      values={values}
                    />
                  }
              </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                  {inputType === 'password'
                    ?(
                    <div>
                    <Label>Confirm Password*</Label>
                    <Input
                      type="password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm_password}
                      invalid={errors && touched.confirm_password && errors.confirm_password}
                      className={errors && errors.confirm_password ? "form-control-error" : null}
                      data-lpignore={true}
                    />
                    <div><FormText color="danger">{errors && errors.confirm_password}</FormText></div>
                    </div>
                   ): null}
                  </FormGroup>
                </Col>                
              </Row>
              <div className= "text-left mt-4 d-inline-block">
                 Already have an account? 
                  <Link className="pl-1" to="/accounts/login">
                    Log In
                  </Link>
              </div>
              <span className="float-right mt-2">
                <Button
                  type="submit"
                  isColor='primary'
                  className={`${styles['accounts-button']}`}
                >
                  Let's Get Going <ButtonLoaderAtom active={isSubmitting} />
                </Button>
              </span>
            </Form>
          )}
        />
      </Card>
      </div>
    );
  }
}


export default RegisterFormComponent;

