import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as InfoDucks from 'ducks/info/info';

import SSTCFLayout from 'layouts/SSTCF';
import BasicSettingFormSection from 'sections/vendors/basicSetting/Form';

import { NAV } from 'constants/vendors';

/**
 * BasicSettingFormPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link BasicSettingFormSection}
 *
 * Fetch Needs:
 *    None
 */
class BasicSettingFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("basic_setting_form");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("basic_setting_form", !isInfoOpen);
  }

  fetchData(){
    this.setState({ isLoading: true });
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'vendor.basic_setting'}),
      this.props.BasicSettingDucks.getBasicSetting(),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { isLoading, isInfoOpen } = this.state;
    const tabs = [
      { href: '/vendor/basic-setting', label: 'Basic' },
      { href: '/vendor/system-setting', label: 'System' },
      { href: '/vendor/notification-setting', label: 'Notification' },
      { href: '/vendor/adjustment-types', label: 'Adjustment Types' },
      { href: "/vendor/system-forms", label: "System Forms" },
      
    ];
    const { infoList } = this.props;
    return (
      <SSTCFLayout
        navInfo={NAV.BasicSettingFormPage}
        tabItems={tabs.filter(item => item)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <BasicSettingFormSection isLoading={isLoading} />
      </SSTCFLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

BasicSettingFormPage.propTypes = {
  BasicSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(BasicSettingFormPage);
