import React from "react";

import PropTypes from "prop-types";
import Redirect from "react-router-dom/Redirect";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";

import MatchWhenAuthorized from "pages/MatchWhenAuthorized";
import NoMatch from "pages/NoMatch";

import InvoiceDetailFormPage from "payments/invoices/pages/invoiceDetail";
import AccountFormPage from "company/pages/accountForm";
import CompanyBillorPlanPage from "payments/bills/pages/plan";
import PaymentMethodPage from "payments/paymentMethods/pages/paymentMethod";
import AddCardPage from "payments/paymentMethods/pages/addCard";
import BasicFormPage from "./BasicSetting";
import VendorFormPage from "./Form";
import VendorListPage from "./List";
import VendorInvoicePage from "payments/invoices/pages/list";
import NotificationSettingListPage from "./NotificationSettingList";
import RoleListPage from "./RoleList";
import RoleFormPage from "./RoleForm";
import SubCompanyListPage from "./SubCompanyList";
import SystemSettingListPage from "./SystemSettingList";
import AdjustmentTypesListPage from "./AdjustmentTypesList";
import IncompleteSignUpListPage from "./IncompleteSignUpList";
import VendorDepartmentListPage from "./DepartmentList";
import VendorSubCompanyFormPage from "./SubCompanyForm";
import VendorSubCompanyUserFormPage from "./SubCompanyUserForm";
import PluginPage from "./Plugin";
import PluginSettingsPage from "./PluginSettings";
import PluginSettingsSetupListPage from "./PluginSettingsSetupList";
import QBDesktopPage from "./QBDesktop";
import ADPDesktopPage from "./ADPDesktop";
import SystemFormsPage from 'forms/pages/systemForms'
// import DocumentTypesListPage from "./DocumentTypesList";
// import EDocsListPage from "./EDocsList";

const VendorRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={`${match.url}/list`} to={`${match.url}/list/all`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/all`}
        name="vendor.list.all"
        component={VendorListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/invoice`}
        name="vendor.invoice"
        component={VendorInvoicePage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/invoice/:id/invoice-detail`}
        name="vendor.invoice.detail"
        component={InvoiceDetailFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/incomplete-signup`}
        name="vendor.list.incomplete_signup"
        component={IncompleteSignUpListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/profile`}
        name="vendor.profile"
        component={VendorFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/system-setting`}
        name="vendor.system_setting"
        component={SystemSettingListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/adjustment-types`}
        name="vendor.adjustment_types"
        component={AdjustmentTypesListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/notification-setting`}
        name="vendor.notification_setting"
        component={NotificationSettingListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/basic-setting`}
        name="vendor.basic_setting"
        component={BasicFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/role-setting`}
        name="vendor.role_setting"
        component={RoleListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/role-setting/add`}
        name="vendor.role_setting.add"
        component={RoleFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/role-setting/:id/edit`}
        name="vendor.role_setting.edit"
        component={RoleFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/sub-company`}
        name="vendor.company_setting"
        component={SubCompanyListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/department`}
        name="vendor.department_setting"
        component={VendorDepartmentListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/sub-company/add`}
        name="vendor.sub_company.add"
        component={VendorSubCompanyFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/sub-company/:id/edit`}
        name="vendor.sub_company.edit"
        component={VendorSubCompanyFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/sub-company/:id/user`}
        name="vendor.sub_company_user.add"
        component={VendorSubCompanyUserFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/plugin`}
        name="vendor.plugin"
        component={PluginPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/plugin/quickbooks-desktop`}
        name="vendor.plugin.quickbooks-desktop"
        component={QBDesktopPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/plugin/adp-desktop`}
        name="vendor.plugin-settings"
        component={ADPDesktopPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/plugin/settings/Quickbooks/:tab`}
        name="vendor.plugin-settings"
        component={PluginSettingsPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/plugin/install/Quickbooks`}
        name="vendor.plugin-settings-setup"
        component={PluginSettingsSetupListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/plugin/uninstall/Quickbooks`}
        name="vendor.plugin-settings-setup"
        component={PluginSettingsSetupListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/plan`}
        name="vendor.plan"
        component={CompanyBillorPlanPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/payment-method`}
        name="vendor.payment-method"
        component={PaymentMethodPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/add-card`}
        name="vendor.add-card"
        component={AddCardPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/:tab/`}
        name="vendor.account"
        component={AccountFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/plugin/install/Adp`}
        name="vendor.plugin-settings-setup"
        component={PluginSettingsSetupListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/system-forms`}
        name="forms.systemforms"
        component={SystemFormsPage}
      />
      {/* <MatchWhenAuthorized
        exact
        path={`${match.url}/e-docs`}
        name="vendor.notification_setting"
        component={EDocsListPage}
      /> */}
      <Route component={NoMatch} />
    </Switch>
  );
};

VendorRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default VendorRoutes;
