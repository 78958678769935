import React, { useEffect } from "react";
import { NAV } from "constants/scheduling";
import TwoColumnLayout from "layouts/TwoColumn";
import StorageGateway from "lib/storage-gateway";
import { withRouter } from "react-router-dom";
import { render } from "@testing-library/react";

const {REACT_APP_SCHEDULING_BASE_URL} =process.env

let oAuthToken;
let company_id;
class SchedulingDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { refresh: false };
    this.fetchData = this.fetchData.bind(this);
  }
  componentWillMount() {
    oAuthToken = StorageGateway.get("oAuthToken");
    company_id = StorageGateway.get("company_id");
  }
  fetchData() {

    this.setState({ refresh: true });
    setTimeout(() => this.setState({ refresh: false }));
 
  }
  componentDidMount() {
    window.addEventListener(
      "message",
      (event) => {
        const data =
          event.data &&
          typeof event.data !== "object" &&
          JSON.parse(event.data);
        const message = data?.message;
        if (message === "SHIFT_MANAGEMENT_PAGE") {
          this.props.history.push(`/scheduling/shift-management`);
        }
      },
      false
    );
    window.addEventListener(
      "message",
      (event) => {
        const data =
          event.data &&
          typeof event.data !== "object" &&
          JSON.parse(event.data);
        const message = data?.message;
        if (message === "SHIFT_DAHBOARD_PAGE") {
          this.props.history.push(`/scheduling/dashboard`);
        }
      },
      false
    );
  }
  render() {
    return (
      <TwoColumnLayout
        navInfo={NAV.SchedulingDashboardPage}
        fetchData={this.fetchData}
      >
        {!this.state.refresh ? (
          <div
            style={{
              height: "calc(100vh - 102px - 46px)",
            }}
          >
            <iframe
              id="scheduling"
              title="scheduling-iframe"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ width: "100%", height: "100%" }}
              src={`${REACT_APP_SCHEDULING_BASE_URL}/scheduling-dashboard/?oAuthToken=${oAuthToken}&company_id=${company_id}&refresh=${this.state.refresh}`}
            />
          </div>
        ) : (
          null
      )}
          
      </TwoColumnLayout>
    );
  }
}

export default withRouter(SchedulingDashboardPage);
