import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_EMPLOYEES = 'employees/employee/GET_EMPLOYEES';
const GET_CHANGED_BY = 'company/users/GET_CHANGED_BY';
const GET_CHANGED_BY_FULFILLED = 'company/users/GET_CHANGED_BY_FULFILLED';
const GET_EMPLOYEES_FULFILLED = 'employees/employee/GET_EMPLOYEES_FULFILLED';
const GET_EMPLOYEE_TAGS = 'employees/employee/GET_EMPLOYEE_TAGS';
const GET_EMPLOYEE_TAGS_FULFILLED = 'employees/employee/GET_EMPLOYEE_TAGS_FULFILLED';
const GET_ASSIGNED_EMPLOYEES = 'employees/employee/GET_ASSIGNED_EMPLOYEES';
const GET_ASSIGNED_EMPLOYEES_FULFILLED = 'employees/employee/GET_ASSIGNED_EMPLOYEES_FULFILLED';
const GET_ALL_EMPLOYEES = 'employees/employee/GET_ALL_EMPLOYEES';
const GET_ALL_EMPLOYEES_FULFILLED = 'employees/employee/GET_ALL_EMPLOYEES_FULFILLED';
const GET_EMPLOYEE_AUDIT_LOGS = 'employees/employee/GET_EMPLOYEE_AUDIT_LOGS';
const GET_EMPLOYEE_AUDIT_LOGS_FULFILLED = 'employees/employee/GET_EMPLOYEE_AUDIT_LOGS_FULFILLED';
const GET_EMPLOYEES_AUDIT_LOGS = 'employees/employee/GET_EMPLOYEES_AUDIT_LOGS';
const GET_EMPLOYEE_ACTIVITY_LOGS = 'employees/employee/GET_EMPLOYEE_ACTIVITY_LOGS'
const GET_EMPLOYEES_AUDIT_LOGS_FULFILLED = 'employees/employee/GET_EMPLOYEES_AUDIT_LOGS_FULFILLED';
const GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED = 'employees/employee/GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED';

const GET_STATS = 'employees/employee/GET_STATS';
const GET_STATS_FULFILLED = 'employees/employee/GET_STATS_FULFILLED'
const SELECT_EMPLOYEES = 'employees/employee/SELECT_EMPLOYEES';
const SELECT_EMPLOYEES_ALL = 'employees/employee/SELECT_EMPLOYEES_ALL';
const POST_ACTIVE_STATUS = 'employees/employee/POST_ACTIVE_STATUS';
const POST_ACTIVE_STATUS_FULFILLED = 'employees/employee/POST_ACTIVE_STATUS_FULFILLED';
const GET_EMPLOYEE = 'employees/employee/GET_EMPLOYEE';
const GET_EMPLOYEE_FULFILLED = 'employees/employee/GET_EMPLOYEE_FULFILLED';
const GET_EMPLOYEE_PENDING = 'employees/employee/GET_EMPLOYEE_PENDING';
const RESET_PASSWORD = 'employees/employee/RESET_PASSWORD';
const POST_EMPLOYEE = 'employees/employee/POST_EMPLOYEE';
const POST_EMPLOYEE_FULFILLED = 'employees/employee/POST_EMPLOYEE_FULFILLED';
const PUT_EMPLOYEE = 'employees/employee/PUT_EMPLOYEE';
const PUT_EMPLOYEE_FULFILLED = 'employees/employee/PUT_EMPLOYEE_FULFILLED';
const POST_KIOSK_AUDITS = 'employees/employee/POST_KIOSK_AUDITS';
const POST_KIOSK_AUDITS_FULFILLED = 'employees/employee/POST_KIOSK_AUDITS_FULFILLED';
const SELECT_EMPLOYEE_AUDIT = 'employees/employee/SELECT_EMPLOYEE_AUDIT';
const RESET_EMPLOYEE = 'employees/employee/RESET_EMPLOYEE';
const POST_BULK_EMPLOYEE = 'employees/employee/POST_BULK_EMPLOYEE';
const POST_BULK_EMPLOYEE_FULFILLED = 'employees/employee/POST_BULK_EMPLOYEE_FULFILLED';
const GET_BULK_EMPLOYEE = 'employees/employee/GET_BULK_EMPLOYEE';
const GET_BULK_EMPLOYEE_FULFILLED = 'employees/employee/GET_BULK_EMPLOYEE_FULFILLED';
const EXPORT_EMPLOYEES = 'employees/employee/EXPORT_EMPLOYEES';
const RESEND_LINK = 'employees/employee/RESEND_LINK';
const UPDATE_EMPLOYEE_WAGE = 'employees/employee/UPDATE_EMPLOYEE_WAGE';
const UPDATE_EMPLOYEE_WAGE_FULFILLED = 'employees/employee/UPDATE_EMPLOYEE_WAGE_FULFILLED';

export function getEmployees(params) {
  return {
    type: GET_EMPLOYEES,
    payload: axios.get(URLS.EMPLOYEE, { params }),
  };
}

export function getChangedBy(params){
  return {
    type: GET_CHANGED_BY,
    payload: axios.get(URLS.CHANGED_BY, {params}),
  }

}

export function getEmployeeTags(params) {
  return {
    type: GET_EMPLOYEE_TAGS,
    payload: axios.get(URLS.EMPLOYEE_TAGS, { params }),
  };
}

export function resendLink(params) {
  return {
    type: RESEND_LINK,
    payload: axios.get(`${URLS.RESEND_LINK}${params}` , {}),
  };
}

export function getAssignedEmployees(params) {
  return {
    type: GET_ASSIGNED_EMPLOYEES,
    payload: axios.get(URLS.EMPLOYEE, { params }),
  };
}

export function exportEmployees(params) {
  return {
    type: EXPORT_EMPLOYEES,
    payload: axios.get(URLS.EXPORT_EMPLOYEE,{ params }),
  };
}

export function getAllEmployees(params) {
  return {
    type: GET_ALL_EMPLOYEES,
    payload: axios.get(URLS.EMPLOYEE, { params }),
  };
}

export function getEmployeeAuditLogs(params) {
  return {
    type: GET_EMPLOYEE_AUDIT_LOGS,
    payload: axios.get(`${URLS.EMPLOYEE}${params.id}/${URLS.KIOSK_EMPLOYEE_AUDIT_LOGS}`, { params }),
  };
}

export function getEmployeesAuditLogs(params) {
  return {
    type: GET_EMPLOYEES_AUDIT_LOGS,
    payload: axios.get(URLS.KIOSK_EMPLOYEES_AUDIT_LOGS, { params }),
  };
}

export function getEmployeeActivityLogs(data) {
  const {empId,...params}=data
  return {
    type: GET_EMPLOYEE_ACTIVITY_LOGS,
    payload: axios.get(`${URLS.EMPLOYEE}${URLS.KIOSK_EMPLOYEES_ACTIVITY_LOGS}${empId}/`, { params }),
  };
}

export function getStats() {
  return {
    type: GET_STATS,
    payload: axios.get(URLS.DASHBOARD_DATA),
  };
}

export function getBulkEmployee(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: GET_BULK_EMPLOYEE,
    payload: axios.get(URLS.EMPLOYEE_BULK_UPLOAD, config),
  };
}

export function selectEmployees(data) {
  return {
    type: SELECT_EMPLOYEES,
    payload: data,
  };
}

export function selectEmployeesAll(data) {
  return {
    type: SELECT_EMPLOYEES_ALL,
    payload: data,
  };
}

export function postResetPasswordEmployee(data) {
  return {
    type: RESET_PASSWORD,
    payload: axios.post(URLS.RESET_PASSWORD, data),
  };
}

export function postActiveStatus(data) {
  return {
    type: POST_ACTIVE_STATUS,
    payload: axios.post(URLS.UPDATE_ACTIVE_STATUS, data)
  };
}

export function getEmployee(params) {
  return {
    type: GET_EMPLOYEE,
    payload: axios.get(`${URLS.EMPLOYEE}${params.id}/`),
  };
}

export function postEmployee(data) {
  return {
    type: POST_EMPLOYEE,
    payload: axios.post(URLS.EMPLOYEE, data),
  };
}

export function putEmployee(data) {
  return {
    type: PUT_EMPLOYEE,
    payload: axios.put(`${URLS.EMPLOYEE}${data.id}/`, data),
  };
}

export function updateEmployeeWage(id,data) {
  return {
    type: UPDATE_EMPLOYEE_WAGE,
    payload: axios.patch(`${URLS.EMPLOYEE}${id}/`, data),
  };
}

export function postKioskAudits(data) {
  return {
    type: POST_KIOSK_AUDITS,
    payload: axios.post(URLS.VALIDATE_KIOSK_AUDITS, data),
  };
}

export function selectEmployeeAudit(data) {
  return {
    type: SELECT_EMPLOYEE_AUDIT,
    payload: data,
  };
}

export function resetEmployee(data) {
  return {
    type: RESET_EMPLOYEE,
  };
}

export function postBulkEmployee(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: POST_BULK_EMPLOYEE,
    payload: axios.post(URLS.EMPLOYEE_BULK_UPLOAD, data, config),
  };
}

const defaultState = {
  list: { results: [] },
  assignedlist: { results: [] },
  detail: {},
  stats: {},
  all:[],
  resendLink : {},
  tagList : [],
  changedByList: [],
  activityLogsList :[]
};

function GET_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  const empList = action.payload.results ? action.payload.results.map(item => ({ ...item, isSelected: false }))  : action.payload.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    list: { ...action.payload, results: empList },
  });
}


function GET_CHANGED_BY_FULFILLED_REDUCER(state, action) {;
  const empList = action.payload.results ? action.payload.results.map(item => ({ ...item, isSelected: false }))  : action.payload.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    changedByList: { ...action.payload, results: empList },
  });
}


function GET_EMPLOYEE_TAGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    tagList: action.payload
  });
}

function GET_ASSIGNED_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  const empList = action.payload.results.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    assignedlist: { ...action.payload, results: empList },
  });
}

function GET_ALL_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  const empList = action.payload.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    all: empList,
  });
}

function GET_STATS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    stats: action.payload,
  });
}

function SELECT_EMPLOYEES_REDUCER(state, action) {
  const list = { ...state.list };
  for (let i = 0; i < list.results.length; i += 1) {
    if (list.results[i].id === action.payload.id) {
      list.results[i].isSelected = !list.results[i].isSelected;
    }
  }
  return Object.assign({}, state, { list });
}

function SELECT_EMPLOYEES_ALL_REDUCER(state, action) {
  const arrayList = [ ...state.all ];
  for (let i = 0; i < arrayList.length; i += 1) {
    if (arrayList[i].id === action.payload.id) {
      arrayList[i].isSelected = !arrayList[i].isSelected;
    }
  }
  return Object.assign({}, state, { all: arrayList });
}

function SELECT_EMPLOYEE_AUDIT_REDUCER(state, action) {
  const list = { ...state.list };
  for (let i = 0; i < list.results.length; i += 1) {
    if (list.results[i].id === action.payload.id) {
      list.results[i].isSelected = !list.results[i].isSelected;
    }
  }
  return Object.assign({}, state, { list });
}


function POST_ACTIVE_STATUS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

function GET_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_EMPLOYEE_PENDING_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: {},
  });
}

function GET_BULK_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}


function POST_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function UPDATE_EMPLOYEE_WAGE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_EMPLOYEE_AUDIT_LOGS_FULFILLED_REDUCER(state, action) {
  const arr  = action.payload.map((item) => ({...item, isSelected: false }));
  return Object.assign({}, state, {
    list: { results: arr },
  });
}

function GET_EMPLOYEES_AUDIT_LOGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    activityLogsList: action.payload,
  });
}

function POST_KIOSK_AUDITS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function RESET_EMPLOYEE_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

function POST_BULK_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}


const handlers = {
  [GET_EMPLOYEES_FULFILLED]: GET_EMPLOYEES_FULFILLED_REDUCER,
  [GET_CHANGED_BY_FULFILLED]: GET_CHANGED_BY_FULFILLED_REDUCER,
  [GET_ASSIGNED_EMPLOYEES_FULFILLED]: GET_ASSIGNED_EMPLOYEES_FULFILLED_REDUCER,
  [GET_ALL_EMPLOYEES_FULFILLED]: GET_ALL_EMPLOYEES_FULFILLED_REDUCER,
  [GET_EMPLOYEE_PENDING]: GET_EMPLOYEE_PENDING_REDUCER,
  [GET_STATS_FULFILLED]: GET_STATS_FULFILLED_REDUCER,
  [SELECT_EMPLOYEES]: SELECT_EMPLOYEES_REDUCER,
  [SELECT_EMPLOYEES_ALL]: SELECT_EMPLOYEES_ALL_REDUCER,
  [POST_ACTIVE_STATUS_FULFILLED]: POST_ACTIVE_STATUS_FULFILLED_REDUCER,
  [GET_EMPLOYEE_FULFILLED]: GET_EMPLOYEE_FULFILLED_REDUCER,
  [POST_EMPLOYEE_FULFILLED]: POST_EMPLOYEE_FULFILLED_REDUCER,
  [PUT_EMPLOYEE_FULFILLED]: PUT_EMPLOYEE_FULFILLED_REDUCER,
  [GET_EMPLOYEE_AUDIT_LOGS_FULFILLED]: GET_EMPLOYEE_AUDIT_LOGS_FULFILLED_REDUCER,
  [GET_EMPLOYEES_AUDIT_LOGS_FULFILLED]: GET_EMPLOYEES_AUDIT_LOGS_FULFILLED_REDUCER,
  [GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED]:GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED_REDUCER,
  [POST_KIOSK_AUDITS_FULFILLED]: POST_KIOSK_AUDITS_FULFILLED_REDUCER,
  [SELECT_EMPLOYEE_AUDIT]: SELECT_EMPLOYEE_AUDIT_REDUCER,
  [RESET_EMPLOYEE]: RESET_EMPLOYEE_REDUCER,
  [POST_BULK_EMPLOYEE_FULFILLED]: POST_BULK_EMPLOYEE_FULFILLED_REDUCER,
  [GET_BULK_EMPLOYEE_FULFILLED]: GET_BULK_EMPLOYEE_FULFILLED_REDUCER,
  [GET_EMPLOYEE_TAGS_FULFILLED]: GET_EMPLOYEE_TAGS_FULFILLED_REDUCER,
  [UPDATE_EMPLOYEE_WAGE_FULFILLED]: UPDATE_EMPLOYEE_WAGE_FULFILLED_REDUCER,
  
};


const employeeSelector = state => state.employees.employee;

export const employeeList = createSelector(
  employeeSelector,
  instance => (instance.list),
);

export const employeeassignedList = createSelector(
  employeeSelector,
  instance => (
    instance.assignedlist
  ),
);

export const employeeSelectedList = createSelector(
  employeeList,
  instance => instance.results.filter(item => item.isSelected).map(item => item.id),
);

export const employeeSelectionList = createSelector(
  employeeList,
  instance => instance.results.filter(item => item.isSelected),
);

export const tagsList = createSelector(
  employeeSelector,
  instance => instance.tagList,
);

export const employeeAll = createSelector(
  employeeSelector,
  instance => instance.all,
);

export const employeeSelectedAll = createSelector(
  employeeAll,
  instance => instance.filter(item => item.isSelected).map(item => item.id),
);

export const employeeDetail = createSelector(
  employeeSelector,
  instance => instance.detail,
);

export const stats = createSelector(
  employeeSelector,
  instance => instance.stats,
);

export const activityLogsList = createSelector(
  employeeSelector,
  instance => (instance.activityLogsList),
);

export const changedByList = createSelector(
  employeeSelector,
  instance => (instance.changedByList),
);

export default createReducer(defaultState, handlers);
