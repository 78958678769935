import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import moment from 'moment-timezone';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import SectionLoaderAtom from 'atoms/SectionLoader';
import FeedbackAuditModal from './FeedbackInAuditLogs';
import closeimage from './Close-Cross.png';

import Lightbox from 'react-image-lightbox';

import LoaderIcon from './metc.gif';

import styles from './styles.module.scss';

class InfoFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state= { isDummy: false, isOverlayOpen: false, selectedIndex: null }
  }
  
  render() {
    const { isOpen, toggle , data, activityLogs, isInfoModalOpen, fetchNextData, items, showMore, dateDisplay, dateFormatDisplay, timeFormat24Hrs ,userTimezone} = this.props;
    const {isOverlayOpen, selectedIndex} = this.state;
    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          backdrop='static'
          size="lg" className="info-form-modal" 
        >
          <ModalHeader className="mb-0 pb-0">{dateDisplay} {data.job_label && `(${data.job_label})`} 
            <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
          </ModalHeader>
          <ModalBody className="pt-2 info-modal-dt pl-0 pr-0">
            {isInfoModalOpen ? <SectionLoaderAtom active />
              :<div className="infinite-scroll-ovverride">
                <InfiniteScroll
                  dataLength={items.length}
                  next={()=>fetchNextData(data.session_id)}
                  hasMore
                  height={477}
                  loader={showMore?<div className="text-center"><img  width="auto" height="40px" src={LoaderIcon} alt="spinner"/></div>:null}
                >
                  {activityLogs  && activityLogs.results && activityLogs.results.map((item, index)=>{
                    const oldData = item.old_value;
                    const newData = item.new_value;
                    let oldStatus = '';
                    let newStatus = '';
                    if(oldData && oldData.status ==='1') oldStatus = 'Approved';
                    else if (oldData && oldData.status ==='2') oldStatus = 'Invalidated';
                    else oldStatus = 'Awaited Approval';
                    
                    if(newData && newData.status ==='1') newStatus = 'Approved';
                    else if (newData && newData.status ==='2') newStatus = 'Invalidated';
                    else newStatus = 'Awaited Approval';
                    
                    const date = timeFormat24Hrs ? moment(item.create_date).tz(userTimezone).format(`${dateFormatDisplay}, HH:mm`) : moment(item.create_date).tz(userTimezone).format(`${dateFormatDisplay} hh:mm a`)
                    const oldStartTime = oldData && moment(oldData.start_datetime).tz(userTimezone).format('hh:mm a');
                    const newStartTime = newData && moment(newData.start_datetime).tz(userTimezone).format('hh:mm a');
                    const oldEndTime = oldData && moment(oldData.end_datetime).tz(userTimezone).format('hh:mm a');
                    const newEndTime = newData && moment(newData.end_datetime).tz(userTimezone).format('hh:mm a');
                    
                    let startTimeChanged = false;
                    let endTimeChanged = false;
                    if(oldStartTime !== newStartTime){
                      startTimeChanged = true;
                    }
                    if(oldEndTime !== newEndTime){
                      endTimeChanged = true;
                    }
                    return(
                      <section className="managingDTInfodate mb-3 pl-3 pr-3">
                        {(newData.activity_type === 'PUNCH-IN' && (startTimeChanged || endTimeChanged)) && <Row>   
                          <Col className="d-inline-block">
                            <p className="mb-0"><b>Time Changed</b><span className="float-right">by {item.user}{item.job_label}</span></p>
                            {startTimeChanged && <p className="mb-0">Start Time : From {oldStartTime} To {newStartTime} <span className="float-right managingDTInfodate">{date}</span></p>}
                            {endTimeChanged && <p className="mb-0">End Time : From {oldEndTime} To {newEndTime} <span className="float-right managingDTInfodate">{date}</span></p>} 
                          </Col>
                        </Row>}
                        {(newData.activity_type === 'MEAL-PERIOD' && (startTimeChanged || endTimeChanged)) && <Row>   
                          <Col className="d-inline-block">
                            <p className="mb-0"><b>Meal Changed</b><span className="float-right">by {item.user}{item.job_label}</span></p>
                            {startTimeChanged && <p className="mb-0">Start Time : From {oldStartTime} To {newStartTime} <span className="float-right managingDTInfodate">{date}</span></p>}
                            {endTimeChanged && <p className="mb-0">End Time : From {oldEndTime} To {newEndTime} <span className="float-right managingDTInfodate">{date}</span></p>} 
                          </Col>
                        </Row>}
                        {(newData.activity_type === "Shift Added" && (startTimeChanged || endTimeChanged)) && <Row>   
                          <Col className="d-inline-block">
                            {(startTimeChanged || endTimeChanged) && <p className="mb-0"><b>Shift Added</b><span className="float-right">by {item.user}{item.job_label}</span></p>}
                            <p className="mb-0">From {newStartTime} to {newEndTime}<span className="float-right managingDTInfodate">{date}</span></p>
                          </Col>
                        </Row>}
                        {(newData.activity_type === "Meal Added" && (startTimeChanged || endTimeChanged))&&<Row>   
                          <Col className="d-inline-block">
                            {(startTimeChanged || endTimeChanged) && <p className="mb-0"><b>Meal Added</b><span className="float-right">by {item.user}{item.job_label}</span></p>}
                            <p className="mb-0">From {newStartTime} to {newEndTime}<span className="float-right managingDTInfodate">{date}</span></p>
                          </Col>
                        </Row>}
                        {(newStatus !== oldStatus) && <Row>
                          <Col className="d-inline-block">
                            <p className="mb-0"><b>Status Changed</b><span className="float-right">by {item.user}</span></p>
                            <p className="mb-0">{oldStatus} to {newStatus}<span className="float-right managingDTInfodate">{date}</span></p>
                          </Col>
                        </Row>}
                        {(newData.activity_type === "Task added" && (startTimeChanged || endTimeChanged))&&<Row>
                          <Col className="d-inline-block">
                            {(startTimeChanged || endTimeChanged) && <p className="mb-0"><b>Task Added</b><span className="float-right">by {item.user}{item.job_label}</span></p>}
                            <p className="mb-0">From {newStartTime} to {newEndTime}<span className="float-right managingDTInfodate">{date}</span></p>
                          </Col>
                        </Row>}
                        {(newData.activity_type === "TASK" && (startTimeChanged || endTimeChanged))&&<Row>
                          <Col className="d-inline-block">
                            {(startTimeChanged || endTimeChanged) && <p className="mb-0"><b>Task Edited</b><span className="float-right">by {item.user}{item.job_label}</span></p>}
                            <p className="mb-0">From {newStartTime} to {newEndTime}<span className="float-right managingDTInfodate">{date}</span></p>
                          </Col>
                        </Row>}
                        {(newData.activity_type === "Task Deleted" && (startTimeChanged || endTimeChanged))&&<Row>
                          <Col className="d-inline-block">
                            {(startTimeChanged || endTimeChanged) && <p className="mb-0"><b>Task Deleted</b><span className="float-right">by {item.user}{item.job_label}</span></p>}
                            <p className="mb-0">From {newStartTime} to {newEndTime}<span className="float-right managingDTInfodate">{date}</span></p>
                          </Col>
                        </Row>}
                        {(newData.activity_type === "Meal Deleted" && (startTimeChanged || endTimeChanged))&&<Row>
                          <Col className="d-inline-block">
                            {(startTimeChanged || endTimeChanged) && <p className="mb-0"><b>Meal Deleted</b><span className="float-right">by {item.user}{item.job_label}</span></p>}
                            <p className="mb-0">From {newStartTime} to {newEndTime}<span className="float-right managingDTInfodate">{date}</span></p>
                          </Col>
                        </Row>}
                        {(newData.message && newData.message.length >=1) && <Row>
                          <Col className="d-inline-block">
                            <p className="mb-0"><b>
                            {newData.message[0].message ? 'Comment added' :  'Daily Compliance Message'}
                             </b>
                            <span className="float-right">by {item.user}</span></p>
                            <Row>
                              <Col md={8}>
                                <p className ="mb-0">{newData.message[0].message ? newData.message[0].message : newData.message}</p>
                              </Col>
                              <Col md={4}>
                                <span className="float-right managingDTInfodate">{date}</span>
                              </Col>  
                            </Row>       
                          </Col>
                        </Row>}
                         {(newData.offline_message ) && <Row>
                          <Col className="d-inline-block">
                            <p className="mb-0"><b>
                            {newData.offline_message ? 'Offline Mode Activity' : null}
                             </b>
                            <span className="float-right">by {item.user}</span></p>
                            <Row>
                              <Col md={8}>
                                {newData.offline_message && newData.offline_message.punch_in ? <p className ="mb-0">Session:</p>  : null}
                                <p className ="mb-0">{newData.offline_message && newData.offline_message.punch_in ? newData.offline_message.punch_in : null}</p>
                                <p>
                                  {newData.offline_message && newData.offline_message.meal_timings ? <p className ="mb-0">Meal Timings:</p> : null}
                                  {newData.offline_message && newData.offline_message.meal_timings && (newData.offline_message.meal_timings).map(item => (
                                      <p className ="mb-0">{item}</p>
                                    ))
                                  }
                                </p>
                              </Col>
                              <Col md={4}>
                                <span className="float-right managingDTInfodate">{date}</span>
                              </Col>  
                            </Row>       
                          </Col>
                        </Row>}
                        {
                          (newData.miles_created_by_app || newData.miles_updated_by_user || newData.miles_updated_by_admin || newData.miles_accepted_by_user) && <Row>
                          <Col className="d-inline-block">
                            <p className="mb-0"><b>
                            Mileage
                             </b>
                            <span className="float-right">by {newData.miles_created_by_app ? 'System' : item.user}</span></p>
                            <Row>
                              <Col md={8}>
                                <p className ="mb-0">{newData.miles_created_by_app || newData.miles_updated_by_user || newData.miles_updated_by_admin || newData.miles_accepted_by_user}</p>
                              </Col>
                              <Col md={4}>
                                <span className="float-right managingDTInfodate">{date}</span>
                              </Col>  
                            </Row>       
                          </Col>
                        </Row>
                        }
                        {
                          (newData.geo_fence_breached_by_user) && <Row>
                          <Col className="d-inline-block">
                            <p className="mb-0"><b>
                            Geofence breach
                             </b>
                            <span className="float-right">by {item.user}</span></p>
                            <Row>
                              <Col md={8}>
                                <p className ="mb-0">{newData.geo_fence_breached_by_user}</p>
                              </Col>
                              <Col md={4}>
                                <span className="float-right managingDTInfodate">{date}</span>
                              </Col>  
                            </Row>       
                          </Col>
                        </Row>
                        }
                        {(newData.unscheduled && newData.unscheduled.length) && 
                          <Row>
                            <Col className="d-inline-block">
                              <p className="mb-0"><b>
                              {newData.unscheduled.length && newData.unscheduled}
                               </b>
                              <span className="float-right">by {item.user}</span></p>
                              <Row>
                                <Col md={8}>
                                  <p className ="mb-0">{newData.correction_message ? newData.correction_message : null}</p>
                                </Col>
                                <Col md={4}>
                                  <span className="float-right managingDTInfodate">{date}</span>
                                </Col>  
                            </Row>      
                            </Col>
                          </Row>
                        }
                        {(newData.correction_request) &&
                          <Row>
                            <Col className="d-inline-block">
                              <p className="mb-0"><b>
                              {newData.correction_request}
                               </b>
                              <span className="float-right">by {item.user}</span></p>
                              <Row>
                                <Col md={8}>
                                  <p className={`${styles['break-word']} mb-0`}>{newData.correction_message ? newData.correction_message : null}</p>
                                </Col>
                                <Col md={4}>
                                  <span className="float-right managingDTInfodate">{date}</span>
                                </Col>  
                            </Row>      
                            </Col>
                          </Row>
                        }
                        {(newData.image_url) &&
                          <Row>
                            <Col className="d-inline-block">
                              <p className="mb-0"><b>Facial Recognition History</b>
                              <span className="float-right">by {item.user}</span></p>
                              <Row>
                                <Col md={8} className="pt-1">
                                  <img src={newData.image_url} width="200" height="150" alt="Facial Recognition History" onClick={() => this.setState({isOverlayOpen: true, selectedIndex: index })} style={{cursor:'pointer'}}/>
                                </Col>
                                <Col md={4}>
                                  <span className="float-right managingDTInfodate">{date}</span>
                                </Col>
                              </Row>      
                            </Col>
                            {isOverlayOpen && (selectedIndex === index) && (
                              <Lightbox
                                mainSrc={newData.image_url}
                                onCloseRequest={() => this.setState({ isOverlayOpen: false })} 
                              />
                            )}
                          </Row>
                        }
                        {(newData.feedback_response) &&
                          <Row>
                           <Col className="d-inline-block">
                              <p className="mb-0"><b>Comment Added</b>
                              <span className="float-right">by {item.user}</span></p>
                              <Row>
                                <Col md={12} className="pt-0 pr-2">
                                  <FeedbackAuditModal date={date} feedbackResponse={newData.feedback_response} timeFormat24Hrs={timeFormat24Hrs} dateFormatDisplay={dateFormatDisplay}/>
                                </Col>
                                {/* <Col md={4}>
                                  <span className="float-right managingDTInfodate">{date}</span>
                                </Col> */}
                              </Row>      
                            </Col>
                          </Row>
                        }
                      </section>
                    )
                  })}
                  {!activityLogs.results.length &&
                    <div className="text-info-managingdt">There are no logs to show.</div>
                  }
                </InfiniteScroll>
              </div>
            } 
          </ModalBody>
        </Modal>
      </div>  
    );
  }
};


export default InfoFormModalComponent;


// {item.data.mealAdded && item.data.mealAdded.length >0  && item.data.mealAdded.map((mealItem)=>{ 
//   const mealStart = moment(mealItem.start_datetime).format('hh:mm a');
//   const mealEnd = moment(mealItem.end_datetime).format('hh:mm a');
//   return(<Row>   
//     <Col className="d-inline-block">
//       <p className="mb-0"><b>Meal Added</b><span className="float-right">by {item.data.actor}{item.job_label}</span></p>
//       <p>From {mealStart} To {mealEnd} <span className="float-right managingDTdate">{date}</span></p>
//     </Col>
//   </Row>)
// })}
// {item.data.taskAdded && item.data.taskAdded.length >0 && item.data.taskAdded.map((taskItem)=>{ 
//   const taskStart = moment(taskItem.start_datetime).format('hh:mm a');
//   const taskEnd = moment(taskItem.end_datetime).format('hh:mm a');
//   return(<Row>   
//     <Col className="d-inline-block">
//       <p className="mb-0"><b>Task Added</b><span className="float-right">by {item.data.actor}{item.job_label}</span></p>
//       <p>From {taskStart} To {taskEnd} <span className="float-right managingDTdate">{date}</span></p>
//     </Col>
//   </Row>)
// })}
