import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_ADJUSTMENT_TYPES = 'vendors/adjustmentTypes/GET_ADJUSTMENT_TYPES';
const GET_ADJUSTMENT_TYPES_FULFILLED = 'vendors/adjustmentTypes/GET_ADJUSTMENT_TYPES_FULFILLED';
const GET_ADJUSTMENT_TYPES_DETAILS = 'vendors/adjustmentTypes/GET_ADJUSTMENT_TYPES_DETAILS';
const GET_ADJUSTMENT_TYPES_DETAILS_FULFILLED = 'vendors/adjustmentTypes/GET_ADJUSTMENT_TYPES_DETAILS_FULFILLED';
const PUT_ADJUSTMENT_TYPES = 'vendors/adjustmentTypes/PUT_ADJUSTMENT_TYPES';
const PUT_ADJUSTMENT_TYPES_FULFILLED = 'vendors/adjustmentTypes/PUT_ADJUSTMENT_TYPES_FULFILLED';
const POST_ADJUSTMENT_TYPES = 'vendors/adjustmentTypes/POST_ADJUSTMENT_TYPES';
const POST_ADJUSTMENT_TYPES_FULFILLED = 'vendors/adjustmentTypes/POST_ADJUSTMENT_TYPES_FULFILLED';
const DELETE_ADJUSTMENT_TYPES = 'vendors/adjustmentTypes/DELETE_ADJUSTMENT_TYPES';

const GET_ADJUSTMENT_IDS = 'vendors/role/GET_ADJUSTMENT_IDS';
const GET_ADJUSTMENT_IDS_FULFILLED = 'vendors/role/GET_ADJUSTMENT_IDS_FULFILLED';

export function getAdjustmentTypes(params) {
  return {
    type: GET_ADJUSTMENT_TYPES,
    payload: axios.get(URLS.ADJUSTMENT_TYPES, { params }),
  };
}

export function getAdjustmentTypesDetail(params) {
  return {
    type: GET_ADJUSTMENT_TYPES_DETAILS,
    payload: axios.get(`${URLS.ADJUSTMENT_TYPES}${params.id}/`),
  };
}


export function putAdjustmentTypes(data) {
  return {
    type: PUT_ADJUSTMENT_TYPES,
    payload: axios.put(`${URLS.ADJUSTMENT_TYPES}${data.id}/`, data),
  };
}

export function postAdjustmentTypes(data) {
  return {
    type: POST_ADJUSTMENT_TYPES,
    payload: axios.post(URLS.ADJUSTMENT_TYPES, data),
  };
}

export function deleteAdjustmentTypes(data) {
  return {
    type: DELETE_ADJUSTMENT_TYPES,
    payload: axios.delete(`${URLS.ADJUSTMENT_TYPES}${data.id}/`, data),
  };
}

export function getAdjustmentIds() {
  return {
    type: GET_ADJUSTMENT_IDS,
    payload: axios.get(`${URLS.REORDERED_IDS}?entity=adjustment`),
  };
}

const defaultState = {
  list: {},
  detail: {},
  adjustmentIdsList:[],
};

function GET_ADJUSTMENT_TYPES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_ADJUSTMENT_TYPES_DETAILS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}


function PUT_ADJUSTMENT_TYPES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_ADJUSTMENT_TYPES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_ADJUSTMENT_IDS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    adjustmentIdsList: action.payload,
  });
}

const handlers = {
  [GET_ADJUSTMENT_TYPES_FULFILLED]: GET_ADJUSTMENT_TYPES_FULFILLED_REDUCER,
  [GET_ADJUSTMENT_TYPES_DETAILS_FULFILLED]: GET_ADJUSTMENT_TYPES_DETAILS_FULFILLED_REDUCER,
  [PUT_ADJUSTMENT_TYPES_FULFILLED]: PUT_ADJUSTMENT_TYPES_FULFILLED_REDUCER,
  [POST_ADJUSTMENT_TYPES_FULFILLED]: POST_ADJUSTMENT_TYPES_FULFILLED_REDUCER,
  [GET_ADJUSTMENT_IDS_FULFILLED] : GET_ADJUSTMENT_IDS_FULFILLED_REDUCER,
};

const adjustmentTypesSelector = state => state.vendors.adjustmentTypes;

export const adjustmentTypesDetail = createSelector(
  adjustmentTypesSelector,
  instance => instance.detail,
);

export const adjustmentTypesList = createSelector(
  adjustmentTypesSelector,
  instance => instance.list,
);

export const adjustmentIdsList = createSelector(
  adjustmentTypesSelector,
  instance => instance.adjustmentIdsList,
);


export default createReducer(defaultState, handlers);
