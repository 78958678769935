import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import { secondsToHours } from 'helpers/utils';
import { toast } from 'react-toastify';

import Table from 'reactstrap/lib/Table';
import moment from 'moment';

import DailyTimecardSubTableComponent from 'components/timecards/dailyTimecardList/SubTable';

import styles from './styles.module.scss';
/**
 * DailyTimecardListPage -> DailyTimecardListSection -> DailyTimecardTableComponent
 *
 * Props:
 *    - daily timecard list
 *
 */

class DailyTimecardTableComponent extends React.PureComponent{
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      shift: {},
      mealShift : {},
      isDeleteModalOpen: false,
    }
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.updateBoxes = this.updateBoxes.bind(this);
    this.saveValidity = this.saveValidity.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.addShift = this.addShift.bind(this);
    this.addMeal = this.addMeal.bind(this);
    this.redirectToCustomSplitList = this.redirectToCustomSplitList.bind(this);
    this.counter = 0;
  }

  componentDidMount(){
    const elementToScroll = document.getElementById(+sessionStorage.getItem("remember_daily_timecard_row"));
    if(elementToScroll){
      elementToScroll.scrollIntoView(true);
    }
  }

  updateBoxes(key, value, id, item) {
    const { updateBoxes } = this.props;
    updateBoxes(key, value, id, item);
  }

  dropdownToggle() {
    const { dropdownOpen } = this.state;
    this.setState(prevState => ({dropdownOpen: !dropdownOpen}))
  }


  saveValidity(valueValidity, item, id, stateText){
    if(valueValidity && valueValidity.label === 'Delete') {
      const { deletedt } = this.props;
      deletedt(item);
    }
    else{
     const { saveData } = this.props;
     saveData(valueValidity, item, id);
   }
  }

  toggleInfo(id,data){
    const { getActivityLogs } = this.props;
    getActivityLogs(id,data);
  }

  // functional call to ducks to create a empty shift
  addShift(data){
    const { addShift } = this.props;
    const currentDate = moment().format('YYYY-MM-DD');
    this.setState({ shift : data});
    // if(data.date > currentDate ){
    //   toast.error('You cannot add activities for future date and time.');
    // }
    if(data.is_approved){
      toast.error('Actvities can not be added as daily timecard is already approved');
    }
    else addShift(data);
  }

  // functional call to ducks to create a empty meal period session
  addMeal(data){
    const { addMeal } = this.props;
    this.setState({ mealShift : data});
    addMeal(data);
  }

  redirectToCustomSplitList(childItem, employeeId) {
    const { router: { history }} = this.context;
    const { location : { query } } = this.props;

    history.push({
      pathname: `/activity/timecard/custom/list/${childItem.id}/split`,
      search: queryString.stringify({
        parent: JSON.stringify(query),
        employee_id: employeeId,
        start_date: query.start_date,
        end_date: query.end_date,
        is_approved: childItem.is_approved,
      }),
    })
  }

  render(){
    const {
      data,
      payPeriodTotal,
      basicSettingDetail,
      updateTable,
      isValidityBoxLoading,
      isShiftLoading,
      isInfoLoading,
      trackingToggle,
      approveDailyTimecard,
      toggleModal,
      getActivityLogs,
      goTo,
      activityLogs,
      isInfoModalOpen,
      openMissingMealState,
      openForceOutState,
      openPunchOutState,
      openCommentState,
      openManualCorrectionState,
      dateFormat,
      timeFormat,
      timeFormat24Hrs,
      selectedId,
      location : { query },
      isInfoOpen,
      addNewShift,
      PunchIn,
      PunchOut,
      mealIn,
      mealOut,
      updateNewShift,
      addMeal,
      errorId,
      updateTime,
      selectedShift,
      type,
      loadedShift,
      fetchNextData,
      items,
      showMore,
      isCommentLoading,
      selectedShiftData,
      showableAttributes,
      userExtraData,
      updateMileage,
      openMileageDeviationState,
      isError,
      openTimefenceBreachState,
      userTimecardFilters,
      togglePtoModal,
      deletePtoTimecard,
      savePtoTimecardData,
      isApproving,
    } = this.props;

    const {dropdownOpen, shift, mealShift} = this.state;
    const trColors = (param) => {
      let string = "";
      if (param.date_display === 'Weekly Total') string = '#CCD2D5';
      else if(param.color === '') string = '#fff';
      else string = param.color;
      return string;
    };
    return (
      <div className="pb-5 ml-3 mt-1 mr-4">
        <span>
          <input
            id="show_employee_listing"
            type="checkbox"
            name="show_employee_listing"
            onChange={(e) => this.props.showEmployeeListing(e.target.checked)}
            className=" mr-1 align-middle"
            checked={this.props.showEmployeeCookie}
          />
          <span className="align-middle font-colorInput">Show Employee List</span>
        </span>
        <small className="float-right mt-1">
            * Adjustment activities do not count towards RT, OT, DOT totals.
        </small>
        <Table hover responsive size="sm" className={this.props.showAllocateTasksPopup ? "table-inline-view mt-1 table table-sm table-hover table_responsive" : "table-inline-view mt-1 table table-sm table-hover"}>
          <thead className="static-header-timecard Header-col">
            <tr>
              <th className = "date_col pl-3">Date</th>
              <th className = "punch_in_col" hidden={!showableAttributes.punch_in}>Punch In</th>
              <th className = "punch_in_col" hidden={!showableAttributes.meal_start}>Meal Start</th>
              <th className = "punch_in_col" hidden={!showableAttributes.meal_end}>Meal End</th>
              <th className = "punch_in_col text-left" hidden={!showableAttributes.punch_out}>Punch Out</th>
              <th className = "punch_in_col text-right" hidden={!showableAttributes.total_shift_time}>Total (Shift) </th>
              <th className = "punch_in_col text-right" hidden={!showableAttributes.total_day_time}>Total (Day) </th>
              <th className = "punch_in_col text-right" hidden={!showableAttributes.meal_period}>Meal Period</th>
              <th className = "punch_in_col text-right" hidden={!showableAttributes.rt}>RT</th>
              <th className = "punch_in_col text-right" hidden={!showableAttributes.ot}>OT </th>
              <th className = "punch_in_col text-right" hidden={!showableAttributes.dot}>DOT</th>
              {userExtraData.company_permissions.mileage ?
                <th hidden={!showableAttributes.mileage} className = "mileage_col text-right">
                  Mileage (Mi)
                  <div className={`${styles['betaflag-custom']}`}>
                    BETA
                  </div>
                </th> : null
              }
              <th className = "punch_in_col text-center" hidden={!showableAttributes.edit}>Edit</th>
              <th className = "punch_in_col text-center" hidden={!showableAttributes.approve}>Approve</th>
            </tr>
          </thead>
          <tbody className= {this.props.showAllocateTasksPopup ? 'static-header-table-tbody responsive_width' : 'static-header-table-tbody'}>
            {data && data.map((item, index) => item && item.child.map((childItem, childItemIndex) => {
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              const d = new Date(childItem.date);
              d.setHours(0, 0, 0, 0);
              this.counter +=1;
              if (childItem.date_display === "Weekly Total") {
                return(
                  <tr
                    key={childItem.id}
                    style={{backgroundColor: trColors(childItem)}}
                  >
                    <td title={childItem.date_display} className="vertical-center weekly_col pl-2">
                      <b>{childItem.date_display}</b>
                    </td>
                    <td hidden={!showableAttributes.punch_in} className="punch_in_col"/>
                    <td hidden={!showableAttributes.meal_start} className="punch_in_col"/>
                    <td hidden={!showableAttributes.meal_end} className="punch_in_col"/>
                    <td hidden={!showableAttributes.punch_out} className="punch_in_col text-left"/>
                    <td hidden={!showableAttributes.total_shift_time} className="punch_in_col text-right"/>
                    {childItem.daily_timecard ? <td hidden={!showableAttributes.total_day_time} className="text-right vertical-center punch_in_col"
                      title={
                        userTimecardFilters && userTimecardFilters.data
                          ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                              ? secondsToHours(childItem.total_time_seconds) : childItem.total_time )
                          : basicSettingDetail.timecard_decimal_view
                          ? secondsToHours(childItem.total_time_seconds)
                          : childItem.total_time
                      }
                    >
                      { userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                              ? secondsToHours(childItem.total_time_seconds) : childItem.total_time )
                        : basicSettingDetail.timecard_decimal_view
                        ? secondsToHours(childItem.total_time_seconds)
                        : childItem.total_time
                      }

                    </td> : <td hidden={!showableAttributes.total_day_time} className="punch_in_col text-right"/>}
                    <td hidden={!showableAttributes.meal_period} className="text-right vertical-center punch_in_col"
                     >
                      <span style={{fontSize: '11px'}}>
                      {childItem.meal_paid_time !==0 ?
                        <div style={childItem.meal_unpaid_time === 0 ?  {fontSize: '14px'} : null}>
                         {childItem.meal_unpaid_time !== 0 ? 'P: ' : null}
                            {userTimecardFilters && userTimecardFilters.data
                              ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                                  ? secondsToHours(childItem.meal_paid_time) : childItem.meal_paid_time_display)
                              : basicSettingDetail.timecard_decimal_view
                              ? secondsToHours(childItem.meal_paid_time)
                              : childItem.meal_paid_time_display
                          }
                        </div>
                        :
                        null
                      }
                      {childItem.meal_unpaid_time ?
                        <div style={childItem.meal_paid_time !== 0 ? {color: '#EC4409'} : {fontSize: '14px', color : '#EC4409'}}>
                          {childItem.meal_paid_time !== 0 ? 'U: ' : null}{(childItem.meal_paid_time === 0 && childItem.meal_unpaid_time!==0) ? '-' : null}
                            {userTimecardFilters && userTimecardFilters.data
                              ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                                  ? secondsToHours(childItem.meal_unpaid_time) : childItem.meal_unpaid_time_display)
                              : basicSettingDetail.timecard_decimal_view
                              ? secondsToHours(childItem.meal_unpaid_time)
                              : childItem.meal_unpaid_time_display
                          }
                        </div>
                        :
                        null
                      }
                      </span>
                    </td>
                    {childItem.daily_timecard ? <td hidden={!showableAttributes.rt} className="text-right vertical-center punch_in_col"
                      title={
                        userTimecardFilters && userTimecardFilters.data
                          ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                              ? secondsToHours(childItem.regular_time) : childItem.regular_time_display)
                          : basicSettingDetail.timecard_decimal_view
                          ? secondsToHours(childItem.regular_time)
                          : childItem.regular_time_display
                      }
                    >
                      { userTimecardFilters && userTimecardFilters.data
                          ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                              ? secondsToHours(childItem.regular_time) : childItem.regular_time_display)
                          : basicSettingDetail.timecard_decimal_view
                          ? secondsToHours(childItem.regular_time)
                          : childItem.regular_time_display
                      }
                    </td>: <td hidden={!showableAttributes.rt} />}
                    {childItem.daily_timecard ? <td hidden={!showableAttributes.ot} className="text-right vertical-center punch_in_col"
                      title={
                        userTimecardFilters && userTimecardFilters.data
                          ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                              ? secondsToHours(childItem.over_time) : childItem.over_time_display)
                          : basicSettingDetail.timecard_decimal_view
                          ? secondsToHours(childItem.over_time)
                          : childItem.over_time_display
                      }
                    >
                      { userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(childItem.over_time) : childItem.over_time_display)
                        : basicSettingDetail.timecard_decimal_view
                        ? secondsToHours(childItem.over_time)
                        : childItem.over_time_display
                      }
                    </td> : <td hidden={!showableAttributes.ot}/>}
                    {childItem.daily_timecard ? <td hidden={!showableAttributes.dot} className="text-right vertical-center punch_in_col"
                      title={
                        userTimecardFilters && userTimecardFilters.data
                         ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(childItem.double_over_time) : childItem.double_over_time_display)
                         : basicSettingDetail.timecard_decimal_view
                         ? secondsToHours(childItem.double_over_time)
                         : childItem.double_over_time_display
                      }
                    >
                      { userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                             ? secondsToHours(childItem.double_over_time) : childItem.double_over_time_display)
                        : basicSettingDetail.timecard_decimal_view
                        ? secondsToHours(childItem.double_over_time)
                        : childItem.double_over_time_display
                      }
                    </td> : <td hidden={!showableAttributes.dot} className="punch_in_col text-right"/>}
                     {/* Mileage column  starts*/}
                      {userExtraData.company_permissions.mileage ? (childItem.daily_timecard ? <td hidden={!showableAttributes.mileage} className="text-right vertical-center mileage_col"
                        title={
                          childItem.mileage ? childItem.mileage.toFixed(1) : 0
                        }
                      >{childItem.mileage ? childItem.mileage.toFixed(1) : 0 }
                      </td> : <td className="punch_in_col text-right" hidden={!showableAttributes.mileage} />) : null}
                      {/* Mileage column ends*/}
                    <td hidden={!showableAttributes.edit} className="punch_in_col text-center" />
                    <td hidden={!showableAttributes.approve} className="punch_in_col text-center"/>
                  </tr>
                );
              } else {
                return(<tr>
                  <td colSpan="14" className="p-0">
                    <DailyTimecardSubTableComponent
                      employeeId={item.employee && item.employee.id}
                      childItem={childItem}
                      goTo={goTo}
                      dropdownToggle={this.dropdownToggle}
                      dropdownOpen={dropdownOpen}
                      openManualCorrectionState={openManualCorrectionState}
                      openMileageDeviationState={openMileageDeviationState}
                      openMissingMealState={openMissingMealState}
                      openForceOutState={openForceOutState}
                      openPunchOutState={openPunchOutState}
                      openCommentState={openCommentState}
                      openTimefenceBreachState={openTimefenceBreachState}
                      selectedId={selectedId}
                      selectedShiftData={selectedShiftData}
                      basicSettingDetail ={basicSettingDetail}
                      userTimecardFilters={userTimecardFilters}
                      toggleModal={toggleModal}
                      updateBoxes= { this.updateBoxes}
                      updateTable= {updateTable}
                      updateTime= {updateTime}
                      isValidityBoxLoading = {isValidityBoxLoading}
                      isShiftLoading={isShiftLoading}
                      saveValidity={this.saveValidity}
                      getActivityLogs={getActivityLogs}
                      activityLogs={activityLogs}
                      isInfoModalOpen={isInfoModalOpen}
                      toggleInfo={this.toggleInfo}
                      isInfoOpen={isInfoOpen}
                      isInfoLoading={isInfoLoading}
                      addMeal={addMeal}
                      shift={shift}
                      addNewShift={addNewShift}
                      updateNewShift={updateNewShift}
                      PunchIn={PunchIn}
                      PunchOut={PunchOut}
                      mealShift={mealShift}
                      dateFormat = {dateFormat}
                      timeFormat24Hrs = {timeFormat24Hrs}
                      timeFormat = {timeFormat}
                      mealIn={mealIn}
                      mealOut={mealOut}
                      errorId={errorId}
                      type={type}
                      selectedShift={selectedShift}
                      loadedShift={loadedShift}
                      fetchNextData={fetchNextData}
                      items={items}
                      showMore={showMore}
                      isCommentLoading={isCommentLoading}
                      redirectToCustomSplitList={this.redirectToCustomSplitList}
                      trackingToggle={trackingToggle}
                      addShift={this.addShift}
                      approveDailyTimecard={approveDailyTimecard}
                      payPeriodTotal={payPeriodTotal}
                      dtNumber={this.counter}
                      disapproveDailyTimecard={this.props.disapproveDailyTimecard}
                      openPayMealModal={this.props.openPayMealModal}
                      changeMealStatus = {this.props.changeMealStatus}
                      departmentAll = {this.props.departmentAll}
                      query={query}
                      defaultPayrollSetting={this.props.defaultPayrollSetting}
                      isDeleteModalOpen = {this.state.isDeleteModalOpen}
                      showableAttributes={showableAttributes}
                      userExtraData={userExtraData}
                      updateMileage={updateMileage}
                      showTasksModal = {this.props.showTasksModal}
                      editDisabled = {this.props.editDisabled}
                      isLoading = {this.props.isLoading}
                      isError = {isError}
                      isFeedbackModalOpen={this.props.isFeedbackModalOpen}
                      feedbackModal={this.props.feedbackModal}
                      dateFormatDisplay={this.props.dateFormatDisplay}
                      feedbackResponseList={this.props.feedbackResponseList}
                      isFeedbackCmntLoader = {this.props.isFeedbackCmntLoader}
                      saveData={this.props.saveData}
                      showMessage={this.props.showMessage}
                      showFeedback={this.props.showFeedback}
                      userTimezone={this.props.userTimezone}
                      togglePtoModal={togglePtoModal}
                      deletePtoTimecard={deletePtoTimecard}
                      savePtoTimecardData={savePtoTimecardData}
                      isApproving={isApproving}
                     />
                  </td>
                </tr>);
              }
            }))}
            {payPeriodTotal && data.length >0 &&
              <tr className={`${styles['dailytimecardlist--payperiodtotal']} font-weight-bold`}>
                <td title={payPeriodTotal.date_display} className="vertical-center weekly_col pl-3">{payPeriodTotal.date_display}</td>
                <td className = "punch_in_col" hidden={!showableAttributes.punch_in} />
                <td className = "punch_in_col" hidden={!showableAttributes.meal_start} />
                <td className = "punch_in_col" hidden={!showableAttributes.meal_end}/>
                <td className = "punch_in_col" hidden={!showableAttributes.punch_out}/>
                <td className = "punch_in_col text-right" hidden={!showableAttributes.total_shift_time}/>
                <td className = "punch_in_col text-right vertical-center" hidden={!showableAttributes.total_day_time}
                  title={
                    userTimecardFilters && userTimecardFilters.data
                      ? ( (userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                          ? secondsToHours(payPeriodTotal.total_time_seconds) : payPeriodTotal.total_time)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(payPeriodTotal.total_time_seconds)
                      : payPeriodTotal.total_time
                  }
                >

                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                       ? secondsToHours(payPeriodTotal.total_time_seconds) : payPeriodTotal.total_time)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(payPeriodTotal.total_time_seconds)
                    : payPeriodTotal.total_time
                  }

                </td>
                <td hidden={!showableAttributes.meal_period} className="text-right vertical-center punch_in_col"
                style={{fontSize: '11px'}}
                >

                  { userTimecardFilters && userTimecardFilters.data
                    ?
                    ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ?
                    <span>
                    {payPeriodTotal.meal_paid_time !==0 ?
                      <div style={payPeriodTotal.meal_unpaid_time ===0 ? {fontSize: '14px'}: null}>{payPeriodTotal.meal_unpaid_time !==0 ? 'P: ' : null }{secondsToHours(payPeriodTotal.meal_paid_time)}</div>
                      :
                      null
                    }
                    {payPeriodTotal.meal_unpaid_time !==0 ?
                      <div style={payPeriodTotal.meal_paid_time ===0 ? {fontSize: '14px', color: '#EC4409'}: {color: '#EC4409'}}>{payPeriodTotal.meal_paid_time !==0 ? 'U: ' : null }{(payPeriodTotal.meal_unpaid_time!==0 && payPeriodTotal.meal_paid_time===0) ? '-' : null}{secondsToHours(payPeriodTotal.meal_unpaid_time)}</div>
                      :
                      null
                    }
                    </span>
                    : <span>
                    {payPeriodTotal.meal_paid_time !==0 ?
                      <div style={payPeriodTotal.meal_unpaid_time ===0 ? {fontSize: '14px'}: null}>{payPeriodTotal.meal_unpaid_time !==0 ? 'P: ' : null }{payPeriodTotal.meal_paid_time_display}</div>
                      :
                      null
                    }
                    {payPeriodTotal.meal_unpaid_time !==0 ?
                      <div style={payPeriodTotal.meal_paid_time ===0 ? {fontSize: '14px', color: '#EC4409'}: {color: '#EC4409'}}>{payPeriodTotal.meal_paid_time !==0 ? 'U: ' : null }{(payPeriodTotal.meal_unpaid_time!==0 && payPeriodTotal.meal_paid_time===0) ? '-' : null}{payPeriodTotal.meal_unpaid_time_display}</div>
                      :
                      null
                    }
                    </span>)
                    : basicSettingDetail.timecard_decimal_view
                    ? <span>
                      {payPeriodTotal.meal_paid_time !==0 ?
                        <div style={payPeriodTotal.meal_unpaid_time ===0 ? {fontSize: '14px'}: null}>{payPeriodTotal.meal_unpaid_time !==0 ? 'P: ' : null }{secondsToHours(payPeriodTotal.meal_paid_time)}</div>
                        :
                        null
                      }
                      {payPeriodTotal.meal_unpaid_time !==0 ?
                        <div style={payPeriodTotal.meal_paid_time ===0 ? {fontSize: '14px', color: '#EC4409'}: {color: '#EC4409'}}>{payPeriodTotal.meal_paid_time !==0 ? 'U: ' : null }{(payPeriodTotal.meal_unpaid_time!==0 && payPeriodTotal.meal_paid_time===0) ? '-' : null}{secondsToHours(payPeriodTotal.meal_unpaid_time)}</div>
                        :
                        null
                      }
                    </span>
                    : <span>
                      {payPeriodTotal.meal_paid_time !==0 ?
                        <div style={payPeriodTotal.meal_unpaid_time ===0 ? {fontSize: '14px'}: null}>{payPeriodTotal.meal_unpaid_time !==0 ? 'P: ' : null }{payPeriodTotal.meal_paid_time_display}</div>
                        :
                        null
                      }
                      {payPeriodTotal.meal_unpaid_time !==0 ?
                        <div style={payPeriodTotal.meal_paid_time ===0 ? {fontSize: '14px', color: '#EC4409'}: {color: '#EC4409'}}>{payPeriodTotal.meal_paid_time !==0 ? 'U: ' : null }{(payPeriodTotal.meal_unpaid_time!==0 && payPeriodTotal.meal_paid_time===0) ? '-' : null}{payPeriodTotal.meal_unpaid_time_display}</div>
                        :
                        null
                      }
                    </span>
                  }

                </td>
                <td hidden={!showableAttributes.rt} className="text-right vertical-center punch_in_col"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(payPeriodTotal.regular_time) : payPeriodTotal.regular_time_display)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(payPeriodTotal.regular_time)
                      : payPeriodTotal.regular_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(payPeriodTotal.regular_time) : payPeriodTotal.regular_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(payPeriodTotal.regular_time)
                    : payPeriodTotal.regular_time_display
                  }
                </td>
                <td hidden={!showableAttributes.ot} className="text-right vertical-center punch_in_col"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(payPeriodTotal.over_time) : payPeriodTotal.over_time_display)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(payPeriodTotal.over_time)
                      : payPeriodTotal.over_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(payPeriodTotal.over_time) : payPeriodTotal.over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(payPeriodTotal.over_time)
                    : payPeriodTotal.over_time_display
                  }
                </td>
                <td hidden={!showableAttributes.dot} className="text-right vertical-center punch_in_col"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                    ? ( (userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(payPeriodTotal.double_over_time) : payPeriodTotal.double_over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(payPeriodTotal.double_over_time)
                    : payPeriodTotal.double_over_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ( (userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(payPeriodTotal.double_over_time) : payPeriodTotal.double_over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(payPeriodTotal.double_over_time)
                    : payPeriodTotal.double_over_time_display
                  }
                </td>
                {/* Mileage column starts*/}
                {userExtraData.company_permissions.mileage ?
                  <td hidden={!showableAttributes.mileage} className="text-right vertical-center mileage_col"
                    title={
                      payPeriodTotal.mileage ? payPeriodTotal.mileage.toFixed(1) : 0
                    }
                  > 
                    { payPeriodTotal.mileage ? payPeriodTotal.mileage.toFixed(1) : 0
                    }
                  </td> : null
                }
                {/* mileage column ends*/}
                <td hidden={!showableAttributes.edit} className = "punch_in_col text-center"/>
                <td hidden={!showableAttributes.approve} className = "punch_in_col text-center"/>
              </tr>
            }
            {!data.length &&
              <tr><td className="text-center" colSpan="14">No Records Found</td></tr>
            }
          </tbody>
        </Table>
      </div>
    );
  }
};

DailyTimecardTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  payPeriodTotal: PropTypes.object.isRequired,
  basicSettingDetail: PropTypes.object.isRequired,
  trackingToggle: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  isValidityBoxLoading: PropTypes.bool,
  isShiftLoading: PropTypes.bool,
  isInfoLoading: PropTypes.bool,
  approveDailyTimecard: PropTypes.func.isRequired,
  toggleModal: PropTypes.func,
  timecardDetail: PropTypes.object,
  getActivityLogs: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  activityToggle: PropTypes.func.isRequired,
  isActivityOpen: PropTypes.bool,
  goTo: PropTypes.func,
  saveData: PropTypes.func.isRequired,
  activityLogs: PropTypes.object,
  isInfoModalOpen: PropTypes.bool,
  openMissingMealState: PropTypes.bool,
  openForceOutState: PropTypes.bool,
  openPunchOutState: PropTypes.bool,
  openCommentState: PropTypes.bool,
  openManualCorrectionState: PropTypes.bool,
  dateFormat: PropTypes.object,
  timeFormat: PropTypes.object,
  timeFormat24Hrs: PropTypes.object,
};

DailyTimecardTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default DailyTimecardTableComponent;
