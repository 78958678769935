import React from "react";

import cookie from "react-cookies";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as RouteDucks from "ducks/routes";
import * as SystemSettingDucks from "ducks/vendors/systemSetting";
import * as InfoDucks from "ducks/info/info";

import SSTCFLayout from "layouts/SSTCF";
import SystemSettingListSection from "sections/vendors/systemSettingList/List";

import { NAV } from "constants/vendors";

/**
 * SystemSettingListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link SystemSettingListSection}
 *
 * Fetch Needs:
 *    - getSystemSettings
 */
class SystemSettingListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.state = { isLoading: false, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("vendor_sub_company_user_add");
    if (cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { query: prevQuery },
    } = this.props;
    const {
      location: { query: nextQuery },
    } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo() {
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_sub_company_user_add", !isInfoOpen);
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    Promise.all([
      this.props.InfoDucks.getInformations({ route: "vendor.system_setting" }),
      this.props.SystemSettingDucks.getSystemSettings(params),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  loadData() {
    const {
      location: { query },
    } = this.props;
    const pageSize = cookie.load("system_setting_page_size") || 10;
    this.fetchData({...query, pageSize, page: query?.page ?? 1});
  }

  render() {
    const { isLoading, isInfoOpen } = this.state;
    const tabs = [
      { href: "/vendor/basic-setting", label: "Basic" },
      { href: "/vendor/system-setting", label: "System" },
      { href: "/vendor/notification-setting", label: "Notification" },
      { href: "/vendor/adjustment-types", label: "Adjustment Types" },
      { href: "/vendor/system-forms", label: "System Forms" },
    ];
    const { infoList } = this.props;
    return (
      <SSTCFLayout
        navInfo={NAV.SystemSettingListPage}
        tabItems={tabs.filter((item) => item)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <SystemSettingListSection isLoading={isLoading} />
      </SSTCFLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = (dispatch) => ({
  SystemSettingDucks: bindActionCreators(SystemSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

SystemSettingListPage.propTypes = {
  location: PropTypes.object.isRequired,
  SystemSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(SystemSettingListPage);
