
import React from "react";
import PropTypes from "prop-types";

import {Switch,Redirect,Route, withRouter} from "react-router-dom";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import StorageGateway from "lib/storage-gateway";
import * as PayrollSettingDucks from "ducks/payrolls/setting";
import * as RouteDucks from "ducks/routes";
import * as InfoDucks from "ducks/info/info";
import TwoColumnLayout from "layouts/TwoColumn";
import { toast } from "react-toastify";

import { NAV } from "constants/pto";

/**
 * PayrollSettingListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 * Fetch Needs:
 *    - getPayrollSetting
 */
let oAuthToken = "";
let company_id = "";
class EditPtoPolicyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeCheck:false,
      balanceCheck:false,
      accuralCheck:false,
      policyCheck:false
    };
    this.goTo = this.goTo.bind(this);
    this.editPolicy=this.editPolicy.bind(this)
    this.eventListnerFunction=this.eventListnerFunction.bind(this)
    this.postMessageToChild=this.postMessageToChild.bind(this)
  }
  
  postMessageToChild(messageType) {
    const iFrame = document.getElementById("scheduling");
    iFrame.contentWindow.postMessage(messageType, "*");
  }
  goTo(url) {
    const {
      router: { history },
    } = this.context;
    history.push(url);
  }
  editPolicy(data,type) {
    if(data?.message==="POLICY_TAB")
    {
      this.props.history.push(`/pto-policy/${data?.content?.policyId}/policyDetails/`)
     // this.componentWillMount()
    } else if(data?.message==="BALANCE_TAB")
    {
      this.props.history.push({pathname:`/pto-policy/${data?.content?.policyId}/balance/`})
     // this.componentWillMount()
      return
    } 
    
    
  }
  eventListnerFunction(event){
    const data =
          event.data &&
          typeof event.data !== "object" &&
          JSON.parse(event.data);
        const message = data?.message;
        if (message === "PREVIOUS_PAGE") {
          this.props.history.push(`/pto-policy/list`)
        }if (message === "SUCCESS_TOAST") {
          toast.success(data?.content?.toastMessage);
        }else if (message === "FAILURE_TOAST") {
          toast.error(data?.content?.toastMessage);
        }else if (message === "SAVE") {
          this.props.history.push(`/pto-policy/list`)
        }else if (message === "POLICY_TAB") {
          this.props.history.push(`/pto-policy/${data?.content?.policyId}/policyDetails/`)
        }else if (message === "EMPLOYEE_TAB") {
          this.props.history.push({pathname:`/pto-policy/${data?.content?.policyId}/employee/`})
          //this.componentWillMount()
        }else if (message === "ACCURAL_TAB") {
          this.props.history.push({pathname:`/pto-policy/${data?.content?.policyId}/accural/`})
          //this.componentWillMount()
        }else  if (message === "BALANCE_TAB") {
          this.props.history.push({pathname:`/pto-policy/${data?.content?.policyId}/balance/`})
    
        }else  if (message === "PREVIOUS_PAGE") {
          this.props.history.push(`/pto-policy/list`)
        }
      
  }
  componentDidMount(){
    window.addEventListener(
      "message",
      this.eventListnerFunction,
      false
    );

  }
  componentWillUnmount() {
    window.removeEventListener("message",this.eventListnerFunction);
  }
  componentWillMount() {
    oAuthToken = StorageGateway.get("oAuthToken");
    company_id = StorageGateway.get("company_id");
  
}
  

  render() {
    const { path } =this.props.match;
    const { pathname, search } = this.props.location;
    return (
      <TwoColumnLayout
        navInfo={this.props.match.params.id!=='add'?NAV.EditPTOPoliciesPage:NAV.AddPTOPoliciesPage}
        goTo={this.goTo}
        fetchData={()=>this.postMessageToChild("REFRESH_ADD_EDIT_POLICY")}
      >
         {!this.state.refresh ?
        <div
          style={{
            height: "calc(100vh - 102px - 46px)",  
            marginRight: "-15px",
            marginTop: "-1rem",
          }}
        >
         <Switch>
            <Route exact path={path}>
               <Redirect
                 to={{
                 pathname: `${pathname}/policy-details`,
                 search: search && search,
                }}
              />
            </Route>
            <Route exact path={`${path}/:tabId`} >
                <WithRouteIframe />
           </Route>
       </Switch>
          
        </div>:<></>}
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = (dispatch) => ({
  PayrollSettingDucks: bindActionCreators(PayrollSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

EditPtoPolicyPage.propTypes = {
  PayrollSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

EditPtoPolicyPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(EditPtoPolicyPage);

 class IframeComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        tabId: 'policyDetails'
      }
    }
    componentDidUpdate(prevProps){
      const { params: {tabId} } = this.props.match;
      if(this.props.match.params.id!==prevProps.match.params.id)
      {
        this.setState({tabId })
      }

    }
    componentDidMount() {
      const { params: {tabId} } = this.props.match;
      this.setState({ tabId })
    }
  render() {
    return (
    <iframe
    id="scheduling"
    title="scheduling-iframe"
    width="100%"
    height="100%"
    frameBorder="0"
    style={{ width: "100%", height: "100%" }}
    src={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/pto-policy/${this.props.match.params.id}/?oAuthToken=${oAuthToken}&company_id=${company_id}&tabId=${this.state.tabId}`}
  />
  )
}
  }
  const WithRouteIframe = withRouter(IframeComponent)