import EventEmitter from 'lib/event-emitter';

const EVENT_TYPES = {
  EMPLOYEE_LIST: 'EMPLOYEE_LIST',
  SUBCOMPANY_LIST: 'SUBCOMPANY_LIST',
  CHANGE_COMPANIES: 'CHANGE_COMPANIES',
  TEAM_LIST: 'TEAM_LIST',
  DEPARTMENT_LIST: 'DEPARTMENT_LIST',
  INFO_LIST: 'INFO_LIST',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  SHOW_EXPORT_LOADER: 'SHOW_EXPORT_LOADER',
  HIDE_EXPORT_LOADER: 'HIDE_EXPORT_LOADER',
  SELECTED_EMPLOYEES: 'SELECTED_EMPLOYEES',
  ALL_JOBS:'ALL_JOBS',
  SHOW_MODAL_LOADER: 'SHOW_MODAL_LOADER',
  HIDE_MODAL_LOADER: 'HIDE_MODAL_LOADER',
  SHOW_ASSIGN_BUTTON_LOADER: 'SHOW_ASSIGN_BUTTON_LOADER',
  HIDE_ASSIGN_BUTTON_LOADER: 'HIDE_ASSIGN_BUTTON_LOADER',
  UPDATE_USER_PREFERENCE: 'UPDATE_USER_PREFERENCE',
  GET_USER_PREFERENCE: 'GET_USER_PREFERENCE',
  UPDATE_STATUS: 'UPDATE_STATUS',
};


class listUseCase{

  employeeAPIGateway = null;

  constructor(employeeAPIGateway){
    
    this.employeeAPIGateway = employeeAPIGateway;
    this.eventEmitter = new EventEmitter();

  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }


  toString(){

    return 'listUseCase';
  }

  setup_data(data){ 

  }

  async getEmployeeList(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.employeeList = await this.employeeAPIGateway.employeeList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.EMPLOYEE_LIST,
      data: this.employeeList,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  async getAllSubcompanies(){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.subCompanies = await this.employeeAPIGateway.getAllSubCompanies({ paginate: false });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SUBCOMPANY_LIST,
      data: this.subCompanies,
    });
  }

  async toggleVendor(data, companyId, departmentId) {
    let queryCompanyId = companyId;
    let index;
    if (!data) {
      queryCompanyId = this.subCompanies.map(item => item.id)
    } else if (data === true) {
      queryCompanyId = [];
    } else if (data.checked) {
      index = queryCompanyId.indexOf(data.id);
      queryCompanyId.splice(index, 1);
    } else {
      queryCompanyId.push(data.id);
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });

    Promise.all([
      this.employeeAPIGateway.getDepartmentList({
        company_id: JSON.stringify(queryCompanyId),
        paginate: false,
      }),
      this.employeeAPIGateway.getTeamList({
        company_id: JSON.stringify(queryCompanyId),
        department_id: departmentId,
        paginate: false,
      }),
    ])
    .then((list) => {
      this.departmentList = list[0];
      this.departmentList.splice(0, 0, { id: "", name: "All Departments" });
      this.teamList = list[1];
      this.teamList.splice(0, 0, { id: "", name: "All Teams" })
    })
    .then(() => {
      this.eventEmitter.emit({
        type: EVENT_TYPES.CHANGE_COMPANIES,
        teamList: this.teamList,
        departmentList: this.departmentList,
        companyIds: queryCompanyId,
      });
    })
  }

  async getDepartmentList(params){
    this.departmentList = await this.employeeAPIGateway.getDepartmentList({ ...params, paginate: false});
    this.departmentList.splice(0, 0, { id: "", name: "All Departments" });
    this.eventEmitter.emit({
      type: EVENT_TYPES.DEPARTMENT_LIST,
      departmentList: this.departmentList,
    });
  }

  async getTeamList(params){
    this.teamList = await this.employeeAPIGateway.getTeamList({ ...params, paginate: false});
    this.teamList.splice(0, 0, { id: "", name: "All Teams" })
    this.eventEmitter.emit({
      type: EVENT_TYPES.TEAM_LIST,
      teamList: this.teamList,
    });
  }

  async getInfoList(){
    this.infoList = await this.employeeAPIGateway.getInfoList({ route: 'employee.list'});
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }

  async updateStatus(status, employeeIds, params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    await this.employeeAPIGateway.updateStatus({
      is_active: status, employee_ids: employeeIds
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.UPDATE_STATUS,
    });
  }

  async toggleSelection(employee, prevState){
    employee.toggleSelection(!employee.isSelected);
    let updatedList = this.employeeList.results.filter((item) => item.isSelected && item.id == employee.id);
    let previousSelectedEmp = prevState && prevState.length && prevState.filter((item) => item.isSelected && item.id !== employee.id);
    previousSelectedEmp && previousSelectedEmp.length && updatedList.push(...previousSelectedEmp);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SELECTED_EMPLOYEES,
      data: updatedList,
    });
  }

  async export(query, employeeIds, showableAttributes){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_EXPORT_LOADER,
    });
    let showableKeys = ""
    let idx = 0;
    for (let [key, val] of Object.entries(showableAttributes)) {
      if (idx === 0) {
        if (val)  {
          showableKeys = showableKeys.concat(key)
        }
        idx = idx + 1;
        continue;
      }
      else if (key === 'address' && val) {
        showableKeys = showableKeys.concat(",address_line_one,address_line_two,country,state,city,zip_code");
        idx= idx + 1;
      }
      else if (val) {
        showableKeys = showableKeys.concat(",").concat(key);
        idx = idx + 1;
      }
    }
    showableKeys = showableKeys.concat(",").concat('password');
    const data = await this.employeeAPIGateway.export({...query, employee_ids: JSON.stringify(employeeIds), show: showableKeys })
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_EXPORT_LOADER,
    });
        // Only for IE 11
    if (window.navigator.msSaveOrOpenBlob) {
      const a = document.createElement("download");
      document.body.appendChild(a);
      a.style.display = "none";
      a.onclick = ((evx) => 
      {
        const  myBlob =  new Blob([data] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        window.navigator.msSaveOrOpenBlob(myBlob, 'employees.csv');
      });
      a.click();
    }
    // chrome ,safari and mozilla browsers 
    else {
      const  myBlob =  new Blob([data] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      const  url = window.URL.createObjectURL(myBlob);
      const element = document.createElement("a");
      document.body.appendChild(element);
      element.href = url;
      element.download = 'employees.csv';
      element.click();
    }
  }

  async resetPassword(data){

    await this.employeeAPIGateway.resetPassword(data);

  }
  async getAllJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_MODAL_LOADER,
    });
    this.allJobs = await this.employeeAPIGateway.getAllJobsList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.ALL_JOBS,
      data: this.allJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_MODAL_LOADER,
    });
  }

  async assignJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGN_BUTTON_LOADER,
    });
    this.postAssignJobsList = await this.employeeAPIGateway.assignJobs(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.ASSIGN_SUCCESS,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_ASSIGN_BUTTON_LOADER,
    });
  }

  async updateUserPreference(params) {
    this.userPreference = await this.employeeAPIGateway.updateUserPreference(params)
    this.eventEmitter.emit({
      type: EVENT_TYPES.UPDATE_USER_PREFERENCE,
      data: this.userPreference
    });
    /*this.getUserPreference();*/
  }

  async getUserPreference(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.userPreference = await this.employeeAPIGateway.getUserPreference(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_USER_PREFERENCE,
      data: this.userPreference
    });
    return this.userPreference
  }

  async updateUserJobAssignment(params){
    await this.employeeAPIGateway.updateUserJobAssignment(params)
    // this.eventEmitter.emit({
    //   type: EVENT_TYPES.UPDATE_USER_PREFERENCE,
    // });
  }

}


export default listUseCase;
