import React, {PureComponent} from 'react';
import { Button, Modal, ModalBody , ModalHeader , ModalFooter} from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import styles from './styles.module.scss';

class EmployeeDeleteModal extends PureComponent {

  render() {
    const {isOpen, toggle, deleteEmployeeAssignment, isSubmitting,employeeDeleteModal} = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={()=>toggle(null)}>Remove employee</ModalHeader>
        <ModalBody>
          <div className={styles.modalBody_font}>Are you sure you want to remove the employee from this job?</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteEmployeeAssignment(isOpen)}
          >
          Yes <ButtonLoaderAtom active={isSubmitting} />
          </Button>
          <Button color="secondary" onClick={()=>toggle(null)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );}
  }
  
export default EmployeeDeleteModal;