import React from 'react';

import PropTypes from 'prop-types';

import { toTitleCase } from 'helpers/utils';
import Collapse from 'reactstrap/lib/Collapse';
import Table from 'reactstrap/lib/Table';

import bindActionCreators from 'redux/lib/bindActionCreators';
import connect from 'react-redux/lib/connect/connect';

import ActiveIconAtom from 'atoms/ActiveIcon';
import CollapseIconAtom from 'atoms/CollapseIcon';
import EditIconAtom from 'atoms/EditIcon';
import DeleteIconAtom from 'atoms/DeleteIcon'
import DepartmentTeamTableComponent from './Team';

import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';

import DragIcon from 'components/vendors/Drag.png';

import SectionLoaderAtom from 'atoms/SectionLoader';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import styles from './styles.module.scss';
import { sort } from 'ramda';
/**
 * VendorDepartmentListPage -> VendorDepartmentListSection -> VendorDepartmentListTableComponent
 *
 * Props:
 *    - departmentList
 */
class VendorDepartmentListTableComponent extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      sortedArray: this.props.data?.results && this.props.data?.results,
      teamArray: [],
      selectedDeptId: null,
      isLoading: false
    }
    this.onDragEnd = this.onDragEnd.bind(this);
    this.toggleTeamList= this.toggleTeamList.bind(this);
  }

  componentWillReceiveProps(nextProps)
  {
      if(nextProps.data?.results !== this.state.sortedArray)
      {
        this.setState({sortedArray:nextProps.data?.results})
      }
      this.props.toggleIsLoading()
  }

  toggleTeamList(data) {
    this.setState({isLoading:true});
    this.props.DepartmentDucks.toggleDepartmentTeams(data);
    this.props.TeamDucks.getTeams({department_id: data.id})
    .then((res)=>{
      this.setState({teamArray: res.value.results});
      this.setState({selectedDeptId: data.id, isLoading: false})
    })
  }

  //This function calls after droping the item at destination index.
  onDragEnd(result){
    const { destination, source } = result;
    const {sortedArray, teamArray} = this.state;
    const {departmentIdsList, teamIdsList} = this.props;

    // return true if item was dropped outside
    if (!destination) return true;
    
    // return true if the item was dropped in the same place
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return true;
    }

    //for department table
    if (result.type === "droppableItem") {
      const results = Object.assign([], sortedArray);
      const item = sortedArray[source.index]; //save the draggable item in item constant
      results.splice(source.index, 1); //remove the source item from the existing index.
      results.splice(destination.index, 0, item); //add the source item at destination index.
      this.setState({
        sortedArray: results
      });

    const selectedItemPk = sortedArray[source.index] && sortedArray[source.index].id;
    const selectedItemIndex = departmentIdsList.indexOf(selectedItemPk);
    let updatedArray = departmentIdsList;
    const difference = destination.index - source.index;
    updatedArray.splice(selectedItemIndex, 1);
    updatedArray.splice(selectedItemIndex + difference, 0, selectedItemPk ) 

    updatedArray && updatedArray.length && this.props.saveDepartmentTeamOrderPreference(updatedArray, 'user_preference_departments')
    .then(() => this.props.getDepartmentIds());
    }
    //for team table
    else if (result.type.includes("droppableSubItem")) {
      const teamResults = Object.assign([],teamArray );
      const item = teamArray[source.index]; //save the draggable item in item constant
      teamResults.splice(source.index, 1); //remove the source item from the existing index.
      teamResults.splice(destination.index, 0, item); //add the source item at destination index.
      this.setState({
        teamArray: teamResults
      });

      const selectedItemPk = teamArray[source.index] && teamArray[source.index].id;
      const selectedItemIndex = teamIdsList.indexOf(selectedItemPk);
      let updatedArray = teamIdsList;
      const difference = destination.index - source.index;
      updatedArray.splice(selectedItemIndex, 1);
      updatedArray.splice(selectedItemIndex + difference, 0, selectedItemPk ) 

      updatedArray && updatedArray.length && this.props.saveDepartmentTeamOrderPreference(updatedArray, 'user_preference_teams')
      .then(() => this.props.getTeamIds());
    }
  }

  render(){
    const { sortedArray } = this.state;
    const { data, toggleDeptForm, toggleTeamForm, deleteDepartment,deleteTeam} =  this.props;

    return (
      <div className="ml-3 mr-4">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Table hover responsive size="sm" >
            <thead className="Header-col">
              <tr>
                <th width="5%">&nbsp;</th>
                <th width="15%" className="text-center">Action</th>
                <th width="15%">Name</th>
                <th width="20%">Payroll Name</th>
                <th width="18">Payroll Setting</th>
                <th width="18%">Description</th>
                <th width="9%" className="text-left pr-3">Is Active</th>
              </tr>
            </thead>
            <Droppable droppableId="droppable" type="droppableItem">
              {(provided, snapshot) => (
                <tbody ref = {provided.innerRef} {...provided.droppableProps} >
                  {sortedArray && sortedArray.map((item, idx) => {
                    return (
                      <Draggable
                        draggableId={item.id.toString()}
                        index={idx}
                        key={item.id}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref = {provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item.id}
                          >
                            <td colSpan="7" className="p-0">
                              <Table
                                hover responsive size="sm"
                                className={`${styles['table-parent']} m-0`}
                              >
                                <tbody className="timeCard-table font-colorInput">
                                  <tr>
                                    <td width="5%" className="text-center"><img className="drag-icon" src={DragIcon} width="20" height="20" alt="dragIcon"/></td>
                                    <td width="15%" className="text-center">
                                      <span title="View Teams">
                                        <CollapseIconAtom
                                          active={item.showTeamList}
                                          className="cursor-pointer"
                                          onClick={() => this.toggleTeamList(item)}
                                        />
                                      </span>
                                      <span title="Edit Department" className="ml-2">
                                        <EditIconAtom
                                          className="cursor-pointer"
                                          onClick={() => toggleDeptForm(item)}
                                        />
                                      </span>
                                      <span title="Delete Department"  role='button'  className="ml-2">
                                        <DeleteIconAtom
                                          className="cursor-pointer"
                                          onClick={() => deleteDepartment(item)}
                                        />
                                      </span>
                                    </td>
                                    <td width="15%" title={item.name}>{item.name}</td>
                                    <td width="20%" title={item.department_payroll_name && item.department_payroll_name.name}>
                                      {item.department_payroll_name && item.department_payroll_name.name ? toTitleCase(item.department_payroll_name.name) : ''}
                                    </td>
                                    <td width="18%" title={item.payroll_setting_name}>{item.payroll_setting_name}</td>
                                    <td width="18%" title={item.description}>{item.description}</td>
                                    {item.is_active
                                      ?  <td width="9%" className="text-left pr-3">Yes</td>
                                      :  <td width="9%" className="text-left pr-3">No</td>
                                    }
                                  </tr>
                                </tbody>
                              </Table>
                              {item.id == this.state.selectedDeptId || this.state.isLoading ?
                                <Collapse className={styles['collapse-border']} isOpen={item.showTeamList}>
                                  <DepartmentTeamTableComponent
                                    toggleTeamForm={toggleTeamForm}
                                    deleteTeam={deleteTeam}
                                    type={item.id}
                                    teamArray={this.state.teamArray}
                                    isLoading={this.state.isLoading}
                                  />
                                </Collapse>
                                : null
                              }           
                            </td>
                          </tr>
                        )}
                      </Draggable>)
                    })}
                    {provided.placeholder}
                    {data && !data.count &&
                      <tr><td className="text-center" colSpan="7">No Records Found</td></tr>
                    }
              </tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </div>
    );
   } 
};

const mapStateToProps = state => ({
});

const mapActionsToProps = dispatch => ({
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
});

VendorDepartmentListTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  toggleDeptForm: PropTypes.func.isRequired,
  toggleTeamForm: PropTypes.func.isRequired,
  toggleTeamList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
) (VendorDepartmentListTableComponent);
